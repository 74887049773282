import { ROLE_CHOICES } from "../constants/choices";
import { EXPORT_HEADER } from "../constants/export-header";
import { utils, writeFile } from "xlsx";

// translate values into option names
const userFieldTranslator = {
  role: ROLE_CHOICES,
};

// credit: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
// the code is modified to allow conversion of array objects to csv
export function exportToCsv(filename, rows) {
  if (rows.length === 0) return;
  let fields;
  if (filename.toLowerCase().includes("user")) {
    fields = EXPORT_HEADER.user;
  } else if (filename.toLowerCase().includes("farm")) {
    fields = EXPORT_HEADER.farm;
  } else {
    fields = EXPORT_HEADER.other;
  }

  let processRow = function (row) {
    let finalVal = "";
    let result = "";
    for (let j = 0; j < fields.length; j++) {
      // get the lang if the field is name(includes _)
      let lang = "";
      let name = "";
      if (fields[j].includes("_")) {
        name = fields[j].split("_")[0];
        lang = fields[j].split("_")[1];
      }

      // field translator
      if (
        filename.toLowerCase().includes("user") &&
        !!userFieldTranslator[fields[j]]
      ) {
        result = userFieldTranslator[fields[j]].find(
          (x) => x.id === row[fields[j]]
        );
        result =
          !!result && !!result.name ? result.name : row[fields[j]].toString();
      } else if (lang !== "" && name === "name") {
        result = row[name][lang];
      } else if (lang !== "" && name !== "name" && Array.isArray(row[name])) {
        if (j > 0) finalVal += ",";
        row[name].map((e, index) => {
          result = e["name"][lang];
          if (index > 0) finalVal += " / ";
          finalVal += result;
        });
        continue;
      } else if (lang !== "" && name !== "name") {
        result = row[name][lang];
      } else {
        let innerValue = !row[fields[j]] ? "" : row[fields[j]].toString();
        if (row[fields[j]] instanceof Date) {
          innerValue = row[fields[j]].toLocaleString();
        }
        result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      }
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  csvFile += fields.toString() + "\n";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }
  let blob = new Blob(["\uFEFF", csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const exportToExcel = (headers, data) => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
  writeFile(workbook, `${new Date().toLocaleDateString()}.xlsx`);
};
