import React from "react";
import { TIME_FRAME_CHOICES } from "../../constants/choices";

export default function SelectTimeFrame({
  timeFrame,
  handleChangeTimeFrame,
  t,
}) {
  var keyId = 0;
  return (
    <div
      style={{
        display: "inline-block",
        paddingRight: "10px",
        background: "#B0D776",
        border: "0.2px solid #ccc",
        borderRadius: "4px",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        margin: "0 10px",
      }}
    >
      <select
        id="timeFrame"
        value={timeFrame}
        onChange={handleChangeTimeFrame}
        style={{
          padding: "10px 40px 10px 20px",
          fontSize: "16px",
          border: "none",
          backgroundColor: "transparent",
          color: "rgba(0, 0, 0, 0.68)",
          outline: "none",
          width: "100%",
        }}
      >
        {TIME_FRAME_CHOICES.map((choice) => {
          keyId++;
          return (
            <option key={keyId} value={t(choice.id)}>
              {choice.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
