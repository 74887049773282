import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { resolvePath } from "../../utils/utils";
import { useRef } from "react";
import SearchField from "./search-field";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    fields,
  } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        bgcolor: "#B0D776",
      }}
    >
      <TableRow>
        <TableCell padding="checkbox" sx={{ width: "fit-content" }}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            sx={{
              "&.Mui-checked, &.MuiCheckbox-indeterminate": {
                color: "rgba(0,0,0,0.7)",
              },
            }}
          />
        </TableCell>
        {fields.map((field) => (
          <TableCell
            key={field.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === field.id ? order : false}
            width={field.width ?? "initial"}
            sx={{
              fontWeight: "bold",
            }}
          >
            <TableSortLabel
              active={orderBy === field.id}
              direction={orderBy === field.id ? order : "asc"}
              onClick={createSortHandler(field.id)}
            >
              {t(field.header)}
              {orderBy === field.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      width: PropTypes.string,
    })
  ).isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    toolbar = <></>,
    filters,
    setFilters,
    noSearch = false,
    searchDebouncePeriod,
    createFunc,
    exportFunc,
    deleteFunc,
  } = props;
  const { t } = useTranslation();

  return (
    <Toolbar
      sx={{
        padding: 2,
        "&.MuiToolbar-root": {
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
        height: "80px",
      }}
    >
      {numSelected > 0 ? (
        <>
          <Typography
            sx={{ flex: "1 1 100%", fontWeight: "bold" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          {deleteFunc && (
            <Tooltip title="Delete">
              <IconButton size="large" onClick={deleteFunc}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack direction="row" spacing={2} justifyContent="flex-start">
            {createFunc && (
              <Button color="primary" onClick={createFunc}>
                {t("Add Record")}
              </Button>
            )}
            {exportFunc && (
              <Button color="secondary" onClick={exportFunc}>
                {t("Export")}
              </Button>
            )}
            {toolbar}
          </Stack>
          <Box>
            {!noSearch && (
              <SearchField
                value={filters.search}
                setFilters={setFilters}
                label={t("Search")}
                debouncePeriod={searchDebouncePeriod}
              />
            )}
          </Box>
        </Stack>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  toolbar: PropTypes.node,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  noSearch: PropTypes.bool,
  createFunc: PropTypes.func,
  exportFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
};

const EnhancedTable = forwardRef((props, _ref) => {
  const {
    data,
    filters: predefinedFilter = {},
    fields,
    handleRowClick,
    useLocalFilterSort = false,
    defaultOrder = "asc",
    defaultOrderBy,
    setRefreshData,
    tableFooterSlot,
    addItemBtnSlot,
    customComparator,
    dense,
    ...rest
  } = props;
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [filters, setFilters] = useState(predefinedFilter);
  const [paginatedData, setPaginatedData] = useState([]);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy ?? fields[0].id ?? "");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isInitialMount = useRef(true);
  const {
    i18n: { language },
  } = useTranslation();

  useImperativeHandle(_ref, () => ({
    getPaginatedData: () => paginatedData,
    getSelectedData: () => selected,
    getSetSelected: () => setSelected,
    getParams: () => ({ order, orderBy, page, rowsPerPage, filters }),
  }));
  console.log("Table Data: ", data);
  console.log("Table AllData: ", allData);
  console.log("Table field: ", fields);
  // Extract the values needed from data according to fields config
  useEffect(() => {
    let dataArr = data;
    if (!Array.isArray(dataArr)) {
      setAllDataLength(dataArr.total);
      dataArr = dataArr.data;
    } else {
      setAllDataLength(dataArr.length);
    }

    const rows = [];
    dataArr.forEach((d) => {
      const row = { id: d.id, values: [] };
      fields.forEach((field, index) => {
        console.log("single Field: ", field);
        let value;
        if (field.value && typeof field.value === "string") {
          value = resolvePath(d, field.value);
          console.log("Value string 1: ", value);
        } else {
          value = resolvePath(d, field.id);
          console.log("Value string 2: ", value);
        }
        row.values.push(value);

        // Store the derived value if the value field is a function so that it can
        // be used for searching and sorting as well
        if (field.value && typeof field.value === "function") {
          if (!row.displayValue) row.displayValue = {};
          row.displayValue[index] = field.value(value, d);
          console.log("Row display value: ", row.displayValue[index]);
        }
        console.log("Field name: ", field.header);
        console.log("Table row: ", row);
      });
      rows.push(row);
    });
    console.log("Table final rows: ", rows);
    setAllData(rows);
  }, [data, fields]);

  useEffect(() => {
    if (useLocalFilterSort) localFilterSort();
    else setPaginatedData(allData);
  }, [allData]);

  // Reset current page to first page when filters changed
  useEffect(() => {
    setPage(0);
  }, [filters]);

  // Update the data when order or page value changed
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (useLocalFilterSort) localFilterSort();
      else if (setRefreshData) setRefreshData((prevState) => prevState + 1);
    }
  }, [order, orderBy, page, rowsPerPage, filters]);

  const descendingComparator = (a, b, orderBy) => {
    if (customComparator) return customComparator(a, b, orderBy);
    const index = fields.map((field) => field.id).indexOf(orderBy);
    a = a.values[index];
    b = b.values[index];
    // Parse to number if the value is numeric
    a = !isNaN(parseFloat(a)) && isFinite(a) ? parseFloat(a) : a;
    b = !isNaN(parseFloat(b)) && isFinite(b) ? parseFloat(b) : b;
    if (typeof a === "number" && typeof b === "number") {
      if (b < a) return -1;
      if (b > a) return 1;
      return 0;
    } else {
      return b.localeCompare(
        a,
        language === "cht" || language === "chs" ? "zh" : "en"
      );
    }
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const localFilterSort = () => {
    setPaginatedData(
      allData
        .map((data) => {
          if (data.displayValue)
            Object.keys(data.displayValue).forEach((key) => {
              data.values[key] = data.displayValue[key];
            });
          return data;
        })
        .filter((data) => {
          if (filters.search) {
            return data.values
              .map((value) => String(value).toLowerCase())
              .some((value) => value?.includes(filters.search.toLowerCase()));
          }
          return true;
        })
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // TODO: Support select all action for REST API pagination
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allData.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Fix the minimum height of the table according to rowsPerPage
  const emptyRows = Math.max(0, rowsPerPage - paginatedData.length);

  console.log("PageData: ", paginatedData);
  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          borderRadius: 3,
          boxShadow: "6px 10px 20px rgba(0, 0, 0, 0.05)",
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          filters={filters}
          setFilters={setFilters}
          {...rest}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={allData.length}
              fields={fields}
            />
            <TableBody>
              {paginatedData.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    onClick={(event) =>
                      handleRowClick
                        ? handleRowClick(event, row.id)
                        : () => void 0
                    }
                    sx={{ cursor: handleRowClick ? "pointer" : "default" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClick(event, row.id);
                        }}
                      />
                    </TableCell>
                    {row.values &&
                      Array.isArray(row.values) &&
                      row.values.map((value, index) => {
                        if (row.displayValue && row.displayValue[index]) {
                          value = row.displayValue[index];
                        }
                        return (
                          <TableCell key={index} align="left">
                            {value}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {tableFooterSlot}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={allDataLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {addItemBtnSlot}
      </Paper>
    </Box>
  );
});

EnhancedTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
      total: PropTypes.number,
    }),
  ]).isRequired,
  filters: PropTypes.object,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      width: PropTypes.string,
    })
  ).isRequired,
  handleRowClick: PropTypes.func,
  useLocalFilterSort: PropTypes.bool,
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  setRefreshData: PropTypes.func,
  noSearch: PropTypes.bool,
  searchDebouncePeriod: PropTypes.number,
  createFunc: PropTypes.func,
  exportFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  tableFooterSlot: PropTypes.node,
  addItemBtnSlot: PropTypes.node,
  customComparator: PropTypes.func,
  dense: PropTypes.bool,
};

export default EnhancedTable;
