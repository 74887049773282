import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";
import { create, getOne, update } from "../../api/common";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../redux/slices/snackbar-slice";
import { useState } from "react";
import { FORM_MODE } from "../../constants/choices";
import { validateData } from "../../utils/utils";
import { Fragment } from "react";
import { getComponent } from "./components";

export default function GenericForm(props) {
  const {
    mode,
    config: { source, initialState, fields },
    formId,
    formData: existingFormData,
    formOptions,
    onSubmit,
    setRefreshData,
    handleClose,
  } = props;
  const [formData, setFormData] = useState(() => initFormData());
  const [options, setOptions] = useState(formOptions ?? {});
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function initFormData() {
    // The form data that passes through props outweights the formId
    if (mode === FORM_MODE.EDIT && existingFormData) return existingFormData;

    // Fetch data if formId exists
    // Return initial state first if it does exist to minimize layout jump
    // compared to returning empty object
    if (mode === FORM_MODE.EDIT && formId)
      getOne(source, formId).then(({ data }) => {
        setFormData(data);
      });
    if (initialState) return initialState;
    return {};
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!(await validateData({ mode, fields, formData, setError }))) return;
    if (onSubmit) {
      onSubmit(formData);
    } else if (mode === FORM_MODE.CREATE) {
      create(source, formData)
        .then(() => {
          dispatch(showSuccessSnackbar("Successfully created record!"));
          if (setRefreshData) setRefreshData((prev) => prev + 1);
        })
        .catch((err) => {
          console.log(err);
          dispatch(showErrorSnackbar(err));
        })
        .finally(() => handleClose());
    } else if (mode === FORM_MODE.EDIT) {
      update(source, formData)
        .then(() => {
          dispatch(showSuccessSnackbar("Successfully updated record!"));
          if (setRefreshData) setRefreshData((prev) => prev + 1);
        })
        .catch((err) => {
          console.log(err);
          dispatch(showErrorSnackbar(err));
        })
        .finally(() => handleClose());
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {fields
        .filter((field) => field[mode]?.hidden !== true)
        .map((field, index) => (
          <Fragment key={index}>
            {getComponent({
              field,
              formData,
              setFormData,
              options,
              setOptions,
              error,
              handleChange,
              t,
            })}
          </Fragment>
        ))}
      <Button onClick={handleSubmit}>
        {mode === FORM_MODE.CREATE ? t("Create") : t("Update")}
      </Button>
    </Box>
  );
}

GenericForm.propTypes = {
  mode: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  formId: PropTypes.string,
  formData: PropTypes.object,
  formOptions: PropTypes.object,
  onSubmit: PropTypes.func,
  setRefreshData: PropTypes.func,
  handleClose: PropTypes.func,
};
