import React from "react";
import ReactEcharts from "echarts-for-react";
import { SummaryCard } from "../dashboard/dashboard";
import { computeMonthlySalesData } from "../visualisation-function/compute-data";
import { noDataGraph } from "../visualisation-function/compute-data";

export default function MonthlySalesChart({
	summarySales,
	chartRefs,
	language,
	t,
}) {
	return (
		<div>
			{summarySales.length > 0 && (
				<SummaryCard
					title={t("Monthly Sales")}
					component={
						<div ref={chartRefs.MonthlySales}>
							<ReactEcharts option={computeMonthlySalesData(summarySales, language)} />
						</div>
					}
				/>
			)}
			{(summarySales === undefined || summarySales.length === 0) && (
				<SummaryCard
					title={t("Monthly Sales")}
					component={
						<div ref={chartRefs.MonthlySales}>
							<ReactEcharts option={noDataGraph(t)} />
						</div>
					}
				/>
			)}
		</div>
	);
}
