import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  farmId: "",
};

export const farmSlice = createSlice({
  name: "selectedFarm",
  initialState,
  reducers: {
    setSelectedFarmId: (state, action) => {
      if (typeof action.payload !== "string") return state;
      return { farmId: action.payload ?? state };
    },
    clearSelectedFarmId: () => {
      return initialState;
    },
  },
});

export const { setSelectedFarmId, clearSelectedFarmId } = farmSlice.actions;

export default farmSlice.reducer;
