import React from "react";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import GrassIcon from "@mui/icons-material/Grass";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import BugReportIcon from "@mui/icons-material/BugReport";
import PersonIcon from "@mui/icons-material/Person";
import SunIcon from "@mui/icons-material/WbSunnyOutlined";
import SpaIcon from "@mui/icons-material/Spa";
import BarChartIcon from "@mui/icons-material/BarChartRounded";
import CheckIcon from "@mui/icons-material/CheckBoxOutlined";
import CartIcon from "@mui/icons-material/ShoppingCartOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SanitizerIcon from "@mui/icons-material/Sanitizer";

export const mainMenuListItem = [
  {
    id: "0",
    title: "Dashboard",
    pathname: "/dashboard",
    icon: <DashboardIcon fontSize="large" />,
    role: ["admin", "owner", "farmer"],
    enabled: "true",
    color: "#800F09",
  },
  {
    id: "1",
    title: "Users",
    pathname: "/users",
    icon: <PersonIcon fontSize="large" />,
    role: ["admin", "owner"],
    enabled: "true",
    color: "#800F09",
  },
  {
    id: "2",
    title: "Activities",
    pathname: "/activities",
    icon: <SunIcon fontSize="large" />,
    role: ["owner", "farmer"],
    enabled: "true",
    color: "#800F09",
  },
  {
    id: "3",
    title: "Planned tasks",
    pathname: "/tasks",
    icon: <CheckIcon fontSize="large" />,
    role: ["owner", "farmer"],
    enabled: "true",
    color: "#800F09",
  },
  {
    id: "4",
    title: "Statistics",
    pathname: "/statistics",
    icon: <BarChartIcon fontSize="large" />,
    role: ["farmer", "owner"],
    enabled: "true",
    color: "#800F09",
  },
  {
    id: "5",
    title: "Farms",
    pathname: "/farms",
    icon: <AgricultureIcon fontSize="large" />,
    role: ["admin"],
    enabled: "true",
    color: "#800F09",
  },
  {
    id: "6",
    title: "Plants",
    pathname: "/plants",
    icon: <GrassIcon fontSize="large" />,
    role: ["admin"],
    enabled: "true",
    hideOnMobile: true,
    color: "#800F09",
  },
  {
    id: "7",
    title: "Fertilizers",
    pathname: "/fertilizers",
    icon: <Inventory2Icon fontSize="large" />,
    role: ["admin"],
    enabled: "true",
    hideOnMobile: true,
    color: "#800F09",
  },
  {
    id: "8",
    title: "Pesticides",
    pathname: "/pesticides",
    icon: <BugReportIcon fontSize="large" />,
    role: ["admin"],
    enabled: "true",
    hideOnMobile: true,
    color: "#800F09",
  },
  {
    id: "9",
    title: "Foliars",
    pathname: "/foliars",
    icon: <SpaIcon fontSize="large" />,
    role: ["admin"],
    enabled: "true",
    hideOnMobile: true,
    color: "#800F09",
  },
  {
    id: "10",
    title: "Fungicides",
    pathname: "/fungicides",
    icon: <SanitizerIcon fontSize="large" />,
    role: ["admin"],
    enabled: "true",
    hideOnMobile: true,
    color: "#800F09",
  },
  {
    id: "11",
    title: "Subscription",
    pathname: "/subscription",
    icon: <CartIcon fontSize="large" />,
    role: ["owner", "farmer"],
    enabled: "true",
    color: "#800F09",
  },
];
