import React from "react";
import ReactEcharts from "echarts-for-react";
import { SummaryCard } from "../dashboard/dashboard";
import { computeInventoryData } from "../visualisation-function/compute-data";
import { noDataGraph } from "../visualisation-function/compute-data";

export default function InventoryChart({ chartRefs, inventoryData, field, t }) {
  return (
    <div>
      {inventoryData !== undefined && (
        <div>
          <SummaryCard
            title={t(field.label) + " (Solid)"}
            component={
              <div ref={chartRefs.Inventory}>
                <ReactEcharts
                  option={computeInventoryData(
                    inventoryData,
                    field,
                    t,
                    "solid"
                  )}
                />
              </div>
            }
          />
          <SummaryCard
            title={t(field.label) + " (Liquid)"}
            component={
              <div ref={chartRefs.Inventory}>
                <ReactEcharts
                  option={computeInventoryData(
                    inventoryData,
                    field,
                    t,
                    "liquid"
                  )}
                />
              </div>
            }
          />
        </div>
      )}
      {(inventoryData === undefined || inventoryData.length === 0) && (
        <SummaryCard
          title={t(field.label)}
          component={
            <div ref={chartRefs.Inventory}>
              <ReactEcharts option={noDataGraph(t)} />
            </div>
          }
        />
      )}
    </div>
  );
}
