import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { create, queryList, update, query } from "../../api/common";
import { activityTypes } from "../../constants/activity-types";
import { setTitle } from "../../redux/slices/title-slice";
import { DialogLeavingPage } from "../../hook/confirmationModal";
import { useNavigatingAway } from "../../hook/useNavigatingAway";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../redux/slices/snackbar-slice";
import { convertToStandardUnit } from "../../constants/unit-conversion";
import { Button, Stack } from "@mui/material";
import ActivityTable from "./activity-table";
import { CustomTabsActivity } from "../common/custom-tabs-activities";
import { ADD_INVENTORY_CHOICES } from "../../constants/choices";

export const Activities = () => {
  const [selected, setSelected] = useState(-1);
  const [previewSelected, setPreviewSelected] = useState(-1);
  const [data, setData] = useState(activityTypes.map(() => []));
  const [isSwitchTable, setIsSwitchTable] = useState(false);
  const selectedFarmId = useSelector((state) => state.selectedFarm.farmId);
  const [originalOptions, setOriginalOptions] = useState();
  const [farmData, setFarmData] = useState();
  const [filteredTabs, setFilteredTabs] = useState([]);
  const [options, setOptions] = useState({
    plants: [],
    fertilizers: [],
    pesticides: [],
    foliars: [],
    fungicides: [],
    categories: ADD_INVENTORY_CHOICES,
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    farmData &&
      setFilteredTabs(
        activityTypes.filter((activityType) =>
          farmData.activities.includes(activityType.label)
        )
      );
  }, [farmData]);

  useEffect(() => {
    dispatch(setTitle("Activities"));
    // fetch options
    if (selectedFarmId) {
      queryList(`farms/name/${selectedFarmId}`, `sort=name`).then((res) => {
        setOriginalOptions(res.data);
        setFarmData(res.data);
      });
    }
  }, [selectedFarmId, dispatch]);

  // recalculate the options when language has changed
  useEffect(() => {
    if (!originalOptions) return;
    const {
      plants = [],
      fertilizers = [],
      pesticides = [],
      foliars = [],
      fungicides = [],
      categories = ADD_INVENTORY_CHOICES,
    } = originalOptions;
    setOptions({
      plants: plants
        .filter((p) =>
          p.tags.some((t) => originalOptions.visibleTags?.includes(t))
        )
        .sort((a, b) => {
          if (a.name[language] < b.name[language]) return -1;
          return 1;
        })
        .map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
        })),
      fertilizers: fertilizers
        .filter((p) =>
          p.tags.some((t) => originalOptions.visibleTags?.includes(t))
        )
        .sort((a, b) => {
          if (a.name[language] < b.name[language]) return -1;
          return 1;
        })
        .map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
          unit: p.unit ?? "",
        })),
      pesticides: pesticides
        .filter((p) =>
          p.tags.some((t) => originalOptions.visibleTags?.includes(t))
        )
        .sort((a, b) => {
          if (a.name[language] < b.name[language]) return -1;
          return 1;
        })
        .map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
          unit: p.unit ?? "",
        })),
      foliars: foliars
        .filter((p) =>
          p.tags.some((t) => originalOptions.visibleTags?.includes(t))
        )
        .sort((a, b) => {
          if (a.name[language] < b.name[language]) return -1;
          return 1;
        })
        .map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
          unit: p.unit ?? "",
        })),
      fungicides: fungicides
        .filter((p) =>
          p.tags.some((t) => originalOptions.visibleTags?.includes(t))
        )
        .sort((a, b) => {
          if (a.name[language] < b.name[language]) return -1;
          return 1;
        })
        .map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
          unit: p.unit ?? "",
        })),
      categories: categories
        .sort((a, b) => {
          if (a.id[language] < b.id[language]) return -1;
          return 1;
        })
        .map((p) => ({
          id: JSON.stringify(p.id),
          label: p.id[language],
        })),
    });
  }, [originalOptions, language]);

  const canShowDialogLeavingPage = () => {
    for (let i in data) {
      if (data[i].length > 0) return true;
    }
    return false;
  };

  const [
    showDialogLeavingPage,
    setShowDialogLeavingPage,
    confirmNavigation,
    cancelNavigation,
  ] = useNavigatingAway(canShowDialogLeavingPage(), isSwitchTable);

  // Switch table will not pop up warnings now as the data is cached
  const checkTable = (v) => {
    setPreviewSelected(v);
    setSelected(v);
  };

  const cancelSwitchTable = () => {
    setIsSwitchTable(false);
    setShowDialogLeavingPage(false);
  };

  const confirmSwitchTable = () => {
    setIsSwitchTable(false);
    setSelected(previewSelected);
    setShowDialogLeavingPage(false);
  };

  const handleSubmit = async () => {
    // Flatten the data value which is an array of arrays
    const flatten = data.flat(1);
    if (flatten.length === 0) {
      dispatch(showErrorSnackbar(t("Please insert activities first!")));
      return;
    }
    console.log("Data: ", data);
    console.log("Flatten: ", flatten);
    // process dataList to correct the data type
    const tmp = JSON.parse(JSON.stringify(flatten));

    let farmBatchData = [];

    const batchUpdateFarm = (tmp) => {
      return tmp.reduce((result, item) => {
        const { eng } = item.item;
        const existingItem = result.find((el) => el.name === eng);
        const { quantity } = convertToStandardUnit(
          Number(item.quantity),
          item.unit
        );
        if (existingItem) {
          existingItem.totalQuantity += parseFloat(quantity);
        } else {
          result.push({
            name: eng,
            totalQuantity: parseFloat(quantity),
          });
        }

        return result;
      }, []);
    };

    if (
      flatten[0].action === "Fertilizer" ||
      flatten[0].action === "Foliar" ||
      flatten[0].action === "Pesticide" ||
      flatten[0].action === "Fungicide" ||
      flatten[0].action === "Add Inventory"
    ) {
      console.log("Tmp: ", tmp);
      farmBatchData = batchUpdateFarm(tmp);
      const res = await query(`farms/name/${selectedFarmId}`);
      console.log("ResNew: ", res.data);
      console.log("FarmBatch: ", farmBatchData);
      update("farms", {
        ...res.data,
        quantityUpdate: farmBatchData,
        action: flatten[0].action,
        category: flatten[0].category,
      });
    }

    const promises = tmp.map((t) => {
      const { quantity, unit } = convertToStandardUnit(
        Number(t.quantity),
        t.unit,
        t.action,
        t.convertQuantity
      );
      const obj = {
        ...t,
        field: parseInt(t.field),
        row: parseInt(t.row),
        originalQuantity: t.quantity,
        originalUnit: t.unit,
        quantity,
        unit,
      };
      console.log("Obj: ", obj);
      // return true;
      return create("activities", obj);
    });
    Promise.all(promises)
      .then(() => {
        dispatch(showSuccessSnackbar(t("Created")));
        setData(activityTypes.map(() => []));
      })
      .catch((e) => showErrorSnackbar(e));
  };

  console.log("Option 1: ", options);
  return (
    <Stack direction="column" alignItems="flex-end">
      {filteredTabs && (
        <CustomTabsActivity
          value={selected}
          setValue={checkTable}
          farmData={farmData}
          setFarmData={setFarmData}
          tabs={filteredTabs.map((activityType) => activityType.label)}
          panels={filteredTabs.map((activityType) => (
            <ActivityTable
              key={activityType.id}
              config={activityType}
              data={data[activityType.id]}
              setData={(v) => {
                const newData = JSON.parse(JSON.stringify(data));
                newData[activityType.id] = v;
                setData(newData);
              }}
              options={options}
            />
          ))}
        />
      )}
      {selected >= 0 && (
        <Button
          onClick={handleSubmit}
          color="secondary"
          sx={{
            mr: 5,
            mb: 3,
          }}
        >
          {t("Submit Activities")}
        </Button>
      )}
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        confirmSwitchTable={confirmSwitchTable}
        cancelSwitchTable={cancelSwitchTable}
        isSwitchTable={isSwitchTable}
      />
    </Stack>
  );
};
