import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ResetPasswordField from "../reset-password/reset-password-field";
import { setTitle } from "../../redux/slices/title-slice";
import GenericForm from "../common/generic-form";
import { UsersConfig } from "../../constants/config";
import { FORM_MODE } from "../../constants/choices";
import { useTranslation } from "react-i18next";

export const UserForm = ({ profileId }) => {
  useTranslation();
  const [config] = useState(UsersConfig);
  const email = useSelector((state) => state.auth.email);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!profileId) dispatch(setTitle("Profile"));
  }, [profileId, dispatch]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        p: {
          xl: 10,
          lg: 6,
          xs: 3,
        },
      }}
    >
      <GenericForm config={config} formId={profileId} mode={FORM_MODE.EDIT} />
      <ResetPasswordField email={email} />
    </Box>
  );
};
