import React from "react";
import FooterForm from "./footer-form";
import { useRef } from "react";
import { useState } from "react";
import { FORM_MODE } from "../../constants/choices";
import { getTableFieldsArr } from "../../utils/utils";
import EnhancedTable from "../common/enhanced-table";
import FormDialog from "../common/form-dialog";
import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export default function ActivityTable(props) {
  const { data, setData, options, config } = props;
  const tableRef = useRef();
  const footerRef = useRef();
  const [dialogStatus, setDialogStatus] = useState({
    open: false,
    mode: FORM_MODE.CREATE,
    formId: "",
    formData: {},
    formOptions: {},
    onSubmit: null,
  });
  const { t } = useTranslation();

  return (
    <Box sx={{ pl: 6, pr: 6 }}>
      <EnhancedTable
        ref={tableRef}
        data={data}
        fields={getTableFieldsArr(config)}
        deleteFunc={() => {
          setData(
            data.filter(
              (x) => !tableRef.current.getSelectedData().includes(x.id)
            )
          );
          tableRef.current.getSetSelected()([]);
        }}
        handleRowClick={(e, rowId) =>
          setDialogStatus({
            open: true,
            mode: FORM_MODE.EDIT,
            formData: data.find((d) => d.id === rowId),
            formOptions: options,
            onSubmit: (formData) => {
              setData(
                data.map((d) => {
                  if (d.id === formData.id) return formData;
                  return d;
                })
              );
              setDialogStatus((prevState) => ({ ...prevState, open: false }));
            },
          })
        }
        tableFooterSlot={<FooterForm ref={footerRef} {...props} />}
        addItemBtnSlot={
          <Stack direction="row" justifyContent="flex-end" sx={{ padding: 2 }}>
            <Button onClick={() => footerRef.current.getAddHandler()()}>
              {t("Add Item")}
            </Button>
          </Stack>
        }
        useLocalFilterSort
        searchDebouncePeriod={0}
        dense
      />
      <FormDialog
        config={config}
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
      />
    </Box>
  );
}
