import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { persistor } from "../../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { UserForm } from "../users/user-form";
import { SignIn } from "../signin/signin";
import { MainMenu } from "../main-menu/main-menu";
import { ConfirmationModal } from "../common/confirmation-modal";
import { SnackbarModal } from "../common/snackbar-modal";
import { UserList } from "../users/user-list";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { ResetPassword } from "../reset-password/reset-password";
import { Statistics } from "../statistics/statistics";
import { Activities } from "../activities/activities";
import { FarmList } from "../farms/farm-list";
import { FungicideList } from "../fungicides/fungicide-list";
import { FertilizerList } from "../fertilizers/fertilizer-list";
import { useSelector } from "react-redux";
import { PesticideList } from "../pesticides/pesticide-list";
import { FoliarList } from "../foliars/foliar-list";
import { PlantList } from "../plants/plant-list";
import { FarmForm } from "../farm-setting/farm-form";
import { HomeController } from "../home-controller/home-controller";
import Locales from "./locales";
import { Construction } from "../construction/construction";
import { Dashboard } from "../dashboard/dashboard";
import { RefreshBanner } from "../pwa/refresh-banner";
import { OfflineMsg } from "../pwa/offline-msg";
import TaskScreen from "../tasks/task-screen";
import { ActivityUsageDetails } from "../dashboard/activity-usage-details";
import InventoryDetials from "../dashboard/inventory-details";

export const Routers = () => {
  const id = useSelector((state) => state.auth.id);
  const isAuthenticated = !!id;
  const role = useSelector((state) => state.auth.role);

  const RequireAuth = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/signin" />;
  };

  const RequireAdminPermission = ({ children }) => {
    const isAllowed = role === "admin" ? true : false;
    return isAllowed ? children : <Navigate to="/dashboard" />;
  };

  const FarmerNotAllowed = ({ children }) => {
    const isAllowed = role === "farmer" ? false : true;
    return isAllowed ? children : <Navigate to="/dashboard" />;
  };

  const RequireNoAuth = ({ children, ignoreAuthIfPresent }) => {
    // `IgnoreAuthIfPresent` - some pages like reset password should be shown when the user is both unauthenticated or not
    return ignoreAuthIfPresent || !isAuthenticated ? (
      children
    ) : (
      <Navigate to="/" />
    );
  };

  return (
    <Router>
      <PersistGate persistor={persistor}>
        <Locales>
          <RefreshBanner />
          <LoadingOverlay />
          <ConfirmationModal />
          {!isAuthenticated && <OfflineMsg />}
          <SnackbarModal />
          <MainMenu
            showMenu={isAuthenticated}
            component={
              <Routes>
                <Route path="/" element={<HomeController />} />
                <Route
                  path="/signin"
                  element={
                    <RequireNoAuth>
                      <SignIn />
                    </RequireNoAuth>
                  }
                />
                <Route
                  // resetpassword instead of other pathname
                  // resetpassword will be matched in main menu component and main menu will be hidden on the page
                  path="/auth/resetpassword"
                  element={
                    <RequireNoAuth ignoreAuthIfPresent={true}>
                      <ResetPassword />
                    </RequireNoAuth>
                  }
                />
                <Route
                  path="/activities"
                  element={
                    <RequireAuth>
                      <Activities />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard title="Dashboard" />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <RequireAuth>
                      <UserForm profileId={id} />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <RequireAuth>
                      <FarmerNotAllowed>
                        <UserList />
                      </FarmerNotAllowed>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/statistics"
                  element={
                    <RequireAuth>
                      <Statistics />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/farms"
                  element={
                    <RequireAuth>
                      <RequireAdminPermission>
                        <FarmList />
                      </RequireAdminPermission>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/farms/create"
                  element={
                    <RequireAuth>
                      <FarmForm isCreate />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/farms/:id"
                  element={
                    <RequireAuth>
                      <FarmerNotAllowed>
                        <FarmForm />
                      </FarmerNotAllowed>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/fertilizers"
                  element={
                    <RequireAuth>
                      <RequireAdminPermission>
                        <FertilizerList />
                      </RequireAdminPermission>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/pesticides"
                  element={
                    <RequireAuth>
                      <RequireAdminPermission>
                        <PesticideList />
                      </RequireAdminPermission>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/foliars"
                  element={
                    <RequireAuth>
                      <RequireAdminPermission>
                        <FoliarList />
                      </RequireAdminPermission>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/fungicides"
                  element={
                    <RequireAuth>
                      <RequireAdminPermission>
                        <FungicideList />
                      </RequireAdminPermission>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/plants"
                  element={
                    <RequireAuth>
                      <RequireAdminPermission>
                        <PlantList />
                      </RequireAdminPermission>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/tasks"
                  element={
                    <RequireAuth>
                      <TaskScreen />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/subscription"
                  element={
                    <RequireAuth>
                      <Construction title="Subscription" />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/activityusage"
                  element={
                    <RequireAuth>
                      <ActivityUsageDetails />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/inventorydetails/:label"
                  element={
                    <RequireAuth>
                      <InventoryDetials />
                    </RequireAuth>
                  }
                />
              </Routes>
            }
          />
        </Locales>
      </PersistGate>
    </Router>
  );
};
