import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/slices/title-slice";
import TaskItem from "./task-item";
import TaskFooter from "./task-footer";
import { queryList } from "../../api/common";
import { useTranslation } from "react-i18next";

const TaskScreen = () => {
  // set title on the nav bar
  const dispatch = useDispatch();
  const farmSelectedId = useSelector((state) => state.selectedFarm.farmId);
  const role = useSelector((state) => state.auth.role);
  const [reload, setReload] = useState(0);
  const [tasks, setTasks] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setTitle("Planned tasks"));
    // fetch tasks on load
    if (!farmSelectedId) return;
    queryList("tasks", `filter[farmId]=${farmSelectedId}&sort=-date`).then(
      ({ data }) => {
        setTasks(
          data.sort((a, b) => {
            if (a.completed && !b.completed) return 1;
            if (!a.completed && b.completed) return -1;
            return 0;
          })
        );
      }
    );
  }, [reload, farmSelectedId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pt: 5,
        height: "80vh",
        width: "75%",
        mx: "auto",
      }}
    >
      <Box
        sx={{
          p: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          overflowX: "hidden",
          mb: 2,
          mt: 2,
        }}
      >
        {tasks.length === 0 ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>{t("No data to be shown")}</Typography>
          </Box>
        ) : (
          tasks.map((task) => (
            <TaskItem key={task.id} task={task} setReload={setReload} />
          ))
        )}
      </Box>
      {role === "owner" && <TaskFooter setReload={setReload} />}
    </Box>
  );
};

export default TaskScreen;
