export const monthList = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const summarysList = [
  { name: "Harvest", unit: "kg", totalOf: "quantity" },
  { name: "Pesticide", unit: "ℓ", totalOf: "quantity" },
  { name: "Sowing", unit: "pieces", totalOf: "quantity" },
  { name: "Fertilizer", unit: "kg", totalOf: "quantity" },
  { name: "Foliar", unit: "ℓ", totalOf: "quantity" },
  { name: "Fungicide", unit: "ℓ", totalOf: "quantity" },
];

export const activities = [
  { name: "Aerating", unit: "", totalOf: "document" },
  { name: "Sowing", unit: "pieces", totalOf: "quantity" },
  { name: "Fertilizer", unit: "kg", totalOf: "quantity" },
  { name: "Pesticide", unit: "ℓ", totalOf: "quantity" },
  { name: "Foliar", unit: "ℓ", totalOf: "quantity" },
  { name: "Fungicide", unit: "ℓ", totalOf: "quantity" },
  { name: "Harvest", unit: "kg", totalOf: "quantity" },
  { name: "Sales", unit: "kg", totalOf: "quantity" },
  { name: "Transplant", unit: "pieces", totalOf: "quantity" },
  { name: "Others", unit: "", totalOf: "document" },
];

export const expensesList = [
  { name: "Pesticide", unit: "RM", totalOf: "quantity" },
  { name: "Fertilizer", unit: "RM", totalOf: "quantity" },
  { name: "Foliar", unit: "RM", totalOf: "quantity" },
  { name: "Fungicide", unit: "RM", totalOf: "quantity" },
];

export const financialActivities = [
  { name: "Sales", unit: "RM", totalOf: "quantity" },
  { name: "Pesticide", unit: "RM", totalOf: "quantity" },
  { name: "Fertilizer", unit: "RM", totalOf: "quantity" },
  { name: "Foliar", unit: "RM", totalOf: "quantity" },
  { name: "Fungicide", unit: "RM", totalOf: "quantity" },
];

export const chartsDashboard = [
  { title: "TotalSales" },
  { title: "TotalExpenses" },
  { title: "ProfitOrLoss" },
  { title: "Summary" },
  { title: "MonthlySales" },
];

export const inventoryItems = [
  {
    id: 0,
    label: "Plant",
    options: "plants",
  },
  {
    id: 1,
    label: "Fertilizer",
    options: "fertilizers",
    cardColor: "#F47E3E",
    softColor: "#FAE8DE",
    iconColor: "#F69865",
    icon: "flower-pollen-outline",
    titleColor: "white",
  },
  {
    id: 2,
    label: "Pesticide",
    options: "pesticides",
    cardColor: "#3870A8",
    softColor: "#DCEBFA",
    iconColor: "#6699CC",
    icon: "bug",
    titleColor: "white",
  },
  {
    id: 3,
    label: "Foliar",
    options: "foliars",
    cardColor: "#48A9A6",
    softColor: "#D5EDEC",
    iconColor: "#7EC8C5",
    icon: "leaf",
    titleColor: "white",
  },
  {
    id: 4,
    label: "Fungicide",
    options: "fungicides",
    cardColor: "#BC76BC",
    softColor: "#FFE8FF",
    iconColor: "#D09FD0",
    icon: "mushroom",
    titleColor: "#fdfffc",
  },
];

var chartsActivity = [{ title: "Summary" }];

activities.forEach((activity) => {
  chartsActivity.push({ title: activity.name });
});

export var chartsActivity;
