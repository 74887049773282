import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export const DialogLeavingPage = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  cancelSwitchTable,
  confirmSwitchTable,
  isSwitchTable,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={isSwitchTable ? cancelSwitchTable : cancelNavigation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Confirm Navigation")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Confirm Navigation Description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={isSwitchTable ? confirmSwitchTable : confirmNavigation}
            autoFocus
          >
            {t("Confirm")}
          </Button>
          <Button
            color="secondary"
            onClick={isSwitchTable ? cancelSwitchTable : cancelNavigation}
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
