export const ROLE_CHOICES = [
  {
    id: "admin",
    name: "Admin",
  },
  {
    id: "owner",
    name: "Farm Owner",
  },
  {
    id: "farmer",
    name: "Farmer",
  },
];

export const ACTION_CHOICES = [
  {
    id: "Sowing",
    name: "Sowing",
  },
  {
    id: "Transplant",
    name: "Transplant",
  },
  {
    id: "Fertilizer",
    name: "Fertilizer",
  },
  {
    id: "Pesticide",
    name: "Pesticide",
  },
  {
    id: "Foliar",
    name: "Foliar",
  },
  {
    id: "Fungicide",
    name: "Fungicide",
  },
  {
    id: "Add Inventory",
    name: "Add Inventory",
  },
  {
    id: "Harvest",
    name: "Harvest",
  },
  {
    id: "Sales",
    name: "Sales",
  },
  {
    id: "Others",
    name: "Others",
  },
];

export const ADD_INVENTORY_CHOICES = [
  {
    id: { eng: "Fertilizer", chs: "施肥", cht: "施肥" },
  },
  {
    id: { eng: "Pesticide", chs: "农药", cht: "農藥" },
  },
  {
    id: { eng: "Foliar", chs: "叶面肥", cht: "葉面肥" },
  },
  {
    id: { eng: "Fungicide", chs: "杀菌剂", cht: "殺菌劑" },
  },
];

export const UNIT_TYPE_CHOICES = [
  {
    id: "mass",
    name: "mass",
  },
  {
    id: "volume",
    name: "volume",
  },
];

export const MASS_CHOICES = [
  {
    id: "kg",
    name: "kg",
  },
  {
    id: "g",
    name: "g",
  },
  {
    id: "mg",
    name: "mg",
  },
];

export const PACK_CHOICES = [
  {
    id: "pack",
    name: "pack",
  },
];

export const LANG_CHOICES = [
  {
    id: "eng",
    name: "English",
  },
  {
    id: "chs",
    name: "简体中文",
  },
  {
    id: "cht",
    name: "繁体中文",
  },
];

export const ACCOUNT_STATUS_CHOICES = [
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "active",
    name: "Active",
  },
  {
    id: "unpaid",
    name: "Unpaid",
  },
  {
    id: "cancelled",
    name: "Cancelled",
  },
  {
    id: "expired",
    name: "Expired",
  },
];

export const FORM_MODE = {
  CREATE: "create",
  EDIT: "edit",
};

export const TIME_FRAME_CHOICES = [
  {
    id: "past12Months",
    name: "Past 12 Months",
  },
  {
    id: "thisMonth",
    name: "This Month",
  },
  {
    id: "lastMonth",
    name: "Last Month",
  },
  {
    id: "past3Months",
    name: "Past 3 Months",
  },
  {
    id: "past6Months",
    name: "Past 6 Months",
  },
  {
    id: "thisYear",
    name: "This Year",
  },
  {
    id: "lastYear",
    name: "Last Year",
  },
  {
    id: "past3Years",
    name: "Past 3 Years",
  },
  {
    id: "past5Years",
    name: "Past 5 Years",
  },
  {
    id: "past10Years",
    name: "Past 10 Years",
  },
];
