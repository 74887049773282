import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import CustomTextField from "../common/custom-text-field";
import jwt from "jwt-decode";
import { Navigate, useSearchParams } from "react-router-dom";
import { store } from "../../redux/store";
import { showModal, hideModal } from "../../redux/slices/modal-slice";
import { logoutUser, resetPassword } from "../../api/auth";
import { useTranslation } from "react-i18next";
import { LangSettings } from "../lang-settings/lang-settings";

export const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation(["translations", "errorTranslations"]);

  // get token from url path
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [navigateToSignIn, setNavigateToSignIn] = useState(false);
  // get email on load
  useEffect(() => {
    try {
      if (!token) {
        throw Error();
      }
      const decoded = jwt(token);
      setEmail(decoded.email);
    } catch (e) {
      //setNavigateToSignIn(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (store.getState().auth.email === email) {
      // after the user makes a http request to change own password, the user will be logged out
      // need to inform user before showing the page
      store.dispatch(
        showModal({
          type: "confirmationModal",
          title: t("Warning"),
          content: t(
            "You are changing your own password. Logout is required after the change."
          ),
          confirm: t("Proceed"),
          cancel: t("Abort"),
          onCancel: () => {
            setNavigateToSignIn(true);
            store.dispatch(hideModal());
            // since it is possible for the reset password to open in popup window or browser tab
            // would need to check if it is popup window before closing
            // window name is provided when opening up the popup window
            if (
              window.opener &&
              window.opener !== window &&
              window.name === "Reset password"
            ) {
              window.self.close();
            }
          },
          onConfirm: () => {
            store.dispatch(hideModal());
          },
        })
      );
    }
  }, [email]);

  const onResetPassword = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage(
        t("Password is not the same with the password confirmation.", {
          ns: "errorTranslations",
        })
      );
      return;
    }

    resetPassword(token, password)
      .then(() => {
        store.dispatch(
          showModal({
            type: "confirmationModal",
            title: t("Reset Password"),
            content: t("Successfully reset your password!"),
            cancel: t("Confirm"),
            onCancel: () => {
              setNavigateToSignIn(true);
              store.dispatch(hideModal());
              if (store.getState().auth.email === email) {
                // log out user if changing own password
                // to avoid authentication error
                logoutUser();
              } else if (
                window.opener &&
                window.opener !== window &&
                window.name === "Reset password"
              ) {
                window.self.close();
              }
            },
          })
        );
      })
      .catch((e) => {
        console.log(e);
        if (e === "Unauthorized") {
          // if the token expires
          setErrorMessage(
            t(
              "The token is expired. Please request to reset your password again.",
              {
                ns: "errorTranslations",
              }
            )
          );
        } else {
          // if password is not strong enough error message will be passed from backend
          setErrorMessage(e);
        }
      });
  };

  if (navigateToSignIn) {
    return <Navigate to={{ pathname: "/signin" }} push />;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      <LangSettings
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
          backgroundColor: "white",
        }}
      />
      <Stack spacing={2} alignItems="center" width="40%">
        <img
          src="/logo.png"
          alt="logo"
          style={{
            width: "90%",
            maxWidth: 200,
            objectFit: "contain",
          }}
        />
        <Typography textAlign="center" variant="h5">
          {t("Reset Password")}
        </Typography>
        <Typography variant="subtitle1">{email}</Typography>
        <CustomTextField
          margin="normal"
          label={t("New Password")}
          value={password}
          setValue={setPassword}
          type="password"
        />
        <CustomTextField
          margin="normal"
          label={t("Confirm New Password")}
          value={confirmPassword}
          setValue={setConfirmPassword}
          type="password"
        />
        <Typography alignSelf="start" color="red">
          {t(errorMessage, { ns: "errorTranslations" })}
        </Typography>
        <Button onClick={onResetPassword}>{t("Reset")}</Button>
      </Stack>
    </Box>
  );
};
