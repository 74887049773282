import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { queryList } from "../../api/common";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { setTitle } from "../../redux/slices/title-slice";
import { useTranslation } from "react-i18next";
import { TIME_FRAME_CHOICES } from "../../constants/choices";
import {
  summarysList,
  activities,
  chartsActivity,
} from "../../constants/visualisation";
import { createUrlQuery } from "../visualisation-function/compute-data";
import {
  getDateRange,
  getDateRangeType,
} from "../visualisation-function/compute-date-range";
import ExportChartModal from "./export-chart-modal";
import ActivityChart from "../visualization-chart/activity-chart";
import UsageSummaryChart from "../visualization-chart/usage-summary-chart";
import SelectTimeFrame from "./select-time-frame";

const useStyles = makeStyles((theme) => ({
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
    margin: 16,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

export const ActivityUsageDetails = () => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  const chartRefs = {
    TotalSales: useRef(null),
    TotalExpenses: useRef(null),
    ProfitOrLoss: useRef(null),
    Summary: useRef(null),
    MonthlySales: useRef(null),
    Aerating: useRef(null),
    Sowing: useRef(null),
    Fertilizer: useRef(null),
    Pesticide: useRef(null),
    Foliar: useRef(null),
    Fungicide: useRef(null),
    Harvest: useRef(null),
    Sales: useRef(null),
    Transplant: useRef(null),
    Others: useRef(null),
  };

  const [loading, setLoading] = useState(true);
  const [allActivities, setAllActivities] = useState([]);
  const [allSummarys, setAllSummarys] = useState([]);
  const [timeFrame, setTimeFrame] = useState("");

  const handleChangeTimeFrame = (e) => {
    setTimeFrame(e.target.value);
  };
  const role = useSelector((state) => state.auth.role);
  const selectedFarmId =
    useSelector((state) => state.selectedFarm.farmId) ?? null;

  const isAllFarmData = role === "admin" ? true : false; //display all data or specify farm data

  const getLengthOfMonths = (timeFrame) => {
    switch (timeFrame) {
      case "thisMonth":
        return 0;
      case "lastMonth":
        return -1;
      case "past3Months":
        return 2;
      case "past6Months":
        return 5;
      case "past12Months":
        return 11;
      case "thisYear":
        const currentMonth = new Date().getMonth();
        return currentMonth;
      case "lastYear":
        return 13;
      case "past3Years":
        return 15;
      case "past5Years":
        return 17;
      case "past10Years":
        return 22;
      default:
        return 11;
    }
  };

  const activitiesMonths = getLengthOfMonths(timeFrame);
  const summaryMonths = getLengthOfMonths(timeFrame);

  useEffect(() => {
    dispatch(setTitle("Activity Details"));

    /********************Summary********************/
    let summaryDateRage = getDateRangeType(timeFrame);
    console.log("summaryDateRage: ", summaryDateRage);
    summarysList.forEach((summary) => {
      let summaryQueryString = createUrlQuery(
        `startDate=${summaryDateRage[0]}&endDate=${summaryDateRage[1]}&&totalOf=quantity&groupBy=month&action=${summary.name}&allFarms=${isAllFarmData}`,
        `&farmId=${selectedFarmId}`,
        selectedFarmId
      );

      queryList(`visualisation`, summaryQueryString).then((res) => {
        setAllSummarys((prevArray) => [
          ...prevArray,
          {
            name: summary.name,
            unit: summary.unit,
            data: res.data.sort((a, b) => a.year - b.year || a.month - b.month),
          },
        ]);
      });
    });

    /********************Activities********************/
    let activitiesDateRange = getDateRangeType(timeFrame);
    console.log("activitiesDateRange: ", activitiesDateRange);
    activities.forEach((activity) => {
      let queryString = createUrlQuery(
        `startDate=${activitiesDateRange[0]}&endDate=${activitiesDateRange[1]}&&totalOf=${activity.totalOf}&groupBy=month,item&action=${activity.name}&allFarms=${isAllFarmData}`,
        `&farmId=${selectedFarmId}`,
        selectedFarmId
      );
      queryList(`visualisation`, queryString).then((res) => {
        setAllActivities((prevArray) => [
          ...prevArray,
          {
            name: activity.name,
            data: res.data.sort((a, b) => a.year - b.year || a.month - b.month),
          },
        ]);
      });
    });

    setLoading(false);
    return () => {
      setAllSummarys([]);
    };
  }, [timeFrame]);

  return (
    <div>
      {!loading && (
        <>
          <Box sx={{ flexGrow: 1 }} className={classes.summaryCards}>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={3} md={6}>
                <SelectTimeFrame
                  timeFrame={timeFrame}
                  handleChangeTimeFrame={handleChangeTimeFrame}
                  t={t}
                />
              </Grid>
              <Grid item xs={12} xl={3} md={6}>
                <ExportChartModal
                  chartRefs={chartRefs}
                  charts={chartsActivity}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={12}>
                <UsageSummaryChart
                  chartRefs={chartRefs}
                  allSummarys={allSummarys}
                  summaryMonths={summaryMonths}
                  t={t}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {activities.map((activity, index) => {
                const findActivity = allActivities.find(
                  (x) => x.name === activity.name
                );
                return (
                  <Grid item xs={12} lg={4} key={index}>
                    <ActivityChart
                      activity={activity}
                      findActivity={findActivity}
                      chartRefs={chartRefs}
                      activitiesMonths={activitiesMonths}
                      language={language}
                      t={t}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};
