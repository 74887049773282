import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Box, Menu, MenuItem } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TaskIcon from "@mui/icons-material/Task";
import { query } from "../../api/common";

const NotificationMenuItem = ({ notification, userId, isFarmer }) => {
  const monthOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const icons = [
    {
      category: "task",
      icon: <AssignmentIcon />,
      color: "purple",
    },
    {
      category: "completed",
      icon: <TaskIcon />,
      color: "green",
    },
  ];

  const convertDate = (dateString) => {
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so add 1
    const date = dateObject.getDate();
    return date + "/" + month + "/" + year;
  };

  const convertDateCreated = (dateString) => {
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = monthOfYear[dateObject.getMonth()]; // Month is zero-based, so add 1
    const date = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    // Add leading zeros to minutes if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${date} ${month} ${year} at ${hours}:${formattedMinutes}`;
  };

  const getAvatarProp = () => {
    return icons.find((item) => item.category === notification.category);
  };
  const processDate = convertDate(notification.date);
  const processDateCreated = convertDateCreated(notification.createdAt);
  const isUserAssignee = notification.assigneeId == userId;
  const isUserAssigner = notification.userId == userId;
  const isTask = notification.category == "task";
  const isAssigneeFarmer = async () => {
    const { data } = await query(`users/${notification.assigneeId}`);
    return data.role == "farmer";
  };
  const isMarkedFarmer = async () => {
    const { data } = await query(`users/${notification.markedId}`);
    return data.role == "farmer";
  };

  return (
    <MenuItem
      disableRipple
      // onClick={(e) => {}}
      // value={"test"}
      sx={{
        width: 500,
        paddingRight: 0,
        backgroundColor: notification.readUsers.includes(userId)
          ? "transparent"
          : "#d4fcd6",
      }}
    >
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item alignSelf={"center"}>
          <Avatar sx={{ backgroundColor: getAvatarProp().color }}>
            {getAvatarProp().icon}
          </Avatar>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography
            noWrap
            sx={{
              fontSize: "13px",
              whiteSpace: "pre-line",
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {isTask ? notification.userName.eng : notification.markedName.eng}
            </span>
            {isTask ? (
              isFarmer || isUserAssignee ? (
                <>
                  <span>{" has assigned you a task to be completed by "}</span>
                  <span style={{ fontWeight: "bold" }}>{processDate}</span>
                </>
              ) : (
                <>
                  <span>{" has assigned "}</span>
                  <span style={{ fontWeight: "bold" }}>
                    {notification.assigneeName.eng}
                  </span>
                  <span>{" a task to be completed by "}</span>
                  <span style={{ fontWeight: "bold" }}>{processDate}</span>
                </>
              )
            ) : isFarmer || isUserAssignee ? (
              <>
                <span>{" has marked "}</span>
                <span style={{ fontWeight: "bold" }}>{"COMPLETE"}</span>
                <span>{" on a task assigned by "}</span>
                <span style={{ fontWeight: "bold" }}>
                  {notification.userName.eng}
                </span>
                <span>{" to you on "}</span>
                <span style={{ fontWeight: "bold" }}>{processDate}</span>
              </>
            ) : isUserAssigner ? (
              <>
                <span>{" has marked "}</span>
                <span style={{ fontWeight: "bold" }}>{"COMPLETE"}</span>
                <span>{" on a task assigned by you on "}</span>
                <span style={{ fontWeight: "bold" }}>{processDate}</span>
              </>
            ) : isAssigneeFarmer ? (
              <>
                <span>{" has marked "}</span>
                <span style={{ fontWeight: "bold" }}>{"COMPLETE"}</span>
                <span>{" on a task assigned by "}</span>
                <span style={{ fontWeight: "bold" }}>
                  {notification?.userName?.eng}
                </span>
                <span>{" to "}</span>
                <span style={{ fontWeight: "bold" }}>
                  {notification?.assigneeName?.eng}
                </span>
                <span>{" on "}</span>
                <span style={{ fontWeight: "bold" }}>{processDate}</span>
              </>
            ) : isMarkedFarmer ? (
              <>
                <span>{" has marked "}</span>
                <span style={{ fontWeight: "bold" }}>{"COMPLETE"}</span>
                <span>{" on a task assigned by "}</span>
                <span style={{ fontWeight: "bold" }}>
                  {notification?.assigneeName?.eng}
                </span>
                <span>{" on "}</span>
                <span style={{ fontWeight: "bold" }}>{processDate}</span>
              </>
            ) : (
              <>
                <span>{" has marked "}</span>
                <span style={{ fontWeight: "bold" }}>{"COMPLETE"}</span>
                <span>{" on a task assigned to "}</span>
                <span style={{ fontWeight: "bold" }}>
                  {notification?.assigneeName?.eng}
                </span>
                <span>{" on "}</span>
                <span style={{ fontWeight: "bold" }}>{processDate}</span>
              </>
            )}
            <Typography
              noWrap
              variant="subtitle1"
              sx={{
                fontSize: "13px",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                fontWeight: "bold",
                color: "green",
              }}
            >
              {"Task: "}
              <span style={{ color: "green" }}>{notification.content}</span>
            </Typography>
            <Typography
              noWrap
              sx={{
                fontSize: "12px",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                color: "darkgray",
              }}
            >
              {processDateCreated}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </MenuItem>
  );
};

export default NotificationMenuItem;
