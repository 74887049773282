import React from "react";
import { Divider, Typography, Box } from "@mui/material";

export const Footer = ({ showMenu }) => {
  if (!showMenu) {
    return <></>;
  }
  return (
    <Box marginTop="auto">
      <Divider variant="middle"></Divider>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 2,
        }}
      >
        <Typography>iLab &#169; Copyright 2022 All Rights Reserved.</Typography>
      </Box>
    </Box>
  );
};
