import React from "react";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import { enUS, zhCN } from "date-fns/locale";

export function CustomDateInput({ label, date, setDate, sx, textFieldProps }) {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = language === "cht" || language === "chs" ? zhCN : enUS;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      <DesktopDatePicker
        label={label}
        inputFormat="dd/MM/yyyy"
        value={date}
        onChange={setDate}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{ ...params?.InputProps, sx }}
            required
            {...textFieldProps}
          />
        )}
      />
    </LocalizationProvider>
  );
}
