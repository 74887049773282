import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { queryList } from "../../api/common";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import { FORM_MODE } from "../../constants/choices";
import { getComponent } from "../common/components";
import { validateData } from "../../utils/utils";
import { uuidv4 } from "../../utils/field-utils";
import { convertToStandardUnit } from "../../constants/unit-conversion";

const FarmFooterForm = forwardRef((props, _ref) => {
  const {
    data,
    config: { source, fields, initialState },
    options,
    setData,
  } = props;
  const mode = FORM_MODE.EDIT;
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState({});
  const [formOptions, setFormOptions] = useState(options ?? {});
  const {
    t,
    i18n: { language },
  } = useTranslation();

  // whenever the name has changed, set the tags
  useEffect(() => {
    if (!formData.name.eng && !formData.name.chs && !formData.name.cht) return;
    queryList(
      source,
      `filter[name.${language}]=${formData.name[language]}`
    ).then(({ data }) => {
      if (data.length === 0) return;
      setFormData({ ...formData, tags: data[0].tags });
    });
  }, [formData.name]);

  useImperativeHandle(_ref, () => ({
    getAddHandler: () => handleAdd,
  }));

  const handleAdd = async () => {
    if (!(await validateData({ mode, fields, formData, setError }))) return;
    const tmp = JSON.parse(JSON.stringify(data));

    const { quantity, unit } = convertToStandardUnit(
      Number(formData.quantity),
      formData.unit
    );
    tmp.push({ ...formData, id: uuidv4(), quantity, unit });
    console.log("temp: ", tmp);
    setData(tmp);
    setFormData(initialState);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

  return (
    <TableHead
      sx={{
        bgcolor: "#B0D776",
        width: "100%",
      }}
    >
      <TableRow>
        <TableCell padding="checkbox" />
        {fields
          .filter((field) => field.footer == true)
          .filter((field) => field[mode]?.hidden !== true)
          .map((field) => (
            <TableCell key={field.id} align="left" padding="normal">
              {getComponent({
                field,
                formData,
                setFormData,
                options: formOptions,
                setOptions: setFormOptions,
                error,
                handleChange,
                t,
                commonProps: {
                  variant: "filled",
                },
                sx: {
                  backgroundColor: "white",
                  borderRadius: 5,
                  boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.25)",
                  "&.Mui-focused": {
                    backgroundColor: "white",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.7)",
                  },
                  "&:active": {
                    backgroundColor: "white",
                  },
                },
              })}
            </TableCell>
          ))}
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
});

export default FarmFooterForm;
