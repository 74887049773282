import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import farmer from "../../images/farmer.gif";
import { Box } from "@mui/system";
import "./animation.css";
import { useTranslation } from "react-i18next";

export const LoadingOverlay = () => {
  const { isLoading } = useSelector((state) => state.loading);
  const { t } = useTranslation();

  if (!isLoading) return <></>;

  return (
    <Box
      sx={{
        zIndex: 1400,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(18, 18, 18, 0.2)",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          width: "90%",
          maxWidth: 400,
          borderRadius: 20,
          backgroundColor: "white",
          minHeight: "80px",
        }}
      >
        <Typography
          className="animate-bounce"
          variant="h6"
          sx={{ position: "absolute", bottom: "15%" }}
        >
          {t("Loading")}
        </Typography>
        <img
          src={farmer}
          alt="farmer"
          width="100%"
          style={{
            borderRadius: 20,
            boxShadow:
              "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
          }}
        />
      </Box>
    </Box>
  );
};
