import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { query } from "../../api/common";
import { showSuccessSnackbar } from "../../redux/slices/snackbar-slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function ResetPasswordDialog({ open, setOpen }) {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setEmail("");
  };

  const handleOnClick = () => {
    handleClose();
    query(`/auth/requestresetpasswordemail?email=${email}`).then(() => {
      dispatch(showSuccessSnackbar(t("An email has been sent")));
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("forgot_password")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Please enter your email address. A password reset link will be emailed to you."
          )}
        </DialogContentText>
        <TextField
          label={t("email")}
          type="email"
          fullWidth
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Cancel")}</Button>
        <Button variant="contained" onClick={handleOnClick}>
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
