import { Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const RefreshBanner = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setOpen(false);
    window.location.reload(true);
  };

  useEffect(() => {
    window.addEventListener("showRefreshSnackbar", (e) => {
      setOpen(true);
      setWaitingWorker(e.detail.waiting);
    });
  }, []);

  return (
    <Snackbar
      open={open}
      message={t("New version")}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={
        <Button size="small" variant="text" onClick={reloadPage}>
          {t("Refresh")}
        </Button>
      }
    />
  );
};
