import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/slices/title-slice";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InventoryChart from "../visualization-chart/inventory-chart";
import { getOne } from "../../api/common";
import { inventoryItems } from "../../constants/visualisation";
import { Grid, Box } from "@mui/material";

const InventoryDetails = () => {
  const initialValueInventory = {
    fertilizers: [],
    pesticides: [],
    foliars: [],
    fungicides: [],
  };

  const selectedFarmId =
    useSelector((state) => state.selectedFarm.farmId) ?? null;

  const dispatch = useDispatch();

  const { label } = useParams();

  const chartRefs = {
    Inventory: useRef(null),
  };

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const filteredInventoryItem = inventoryItems.find(
    (item) => item.label == label
  );

  console.log("FilteredItem Option: " + filteredInventoryItem.options);

  const [inventory, setInventory] = useState(initialValueInventory);

  useEffect(() => {
    dispatch(setTitle(`${label} Details`));

    /********************Inventory********************/
    var filteredInventory = initialValueInventory;

    async function fetchInventoryData() {
      await getOne("farms", selectedFarmId).then((res) => {
        filteredInventory = {
          ...filteredInventory,
          [filteredInventoryItem.options]:
            res.data[filteredInventoryItem.options],
        };

        setInventory(filteredInventory);
      });
    }

    fetchInventoryData();
  }, []);

  console.log("Inventory: " + JSON.stringify(inventory));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          {inventory[filteredInventoryItem.options].length !== 0 && (
            <InventoryChart
              chartRefs={chartRefs}
              inventoryData={inventory}
              field={filteredInventoryItem}
              t={t}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InventoryDetails;
