import React, { useState } from "react";
import { LANG_CHOICES } from "../../constants/choices";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

export const LangSettings = ({ sx }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          fontWeight: "bold",
          width: "fit-content",
          ...sx,
        }}
      >
        {language}
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {LANG_CHOICES.map((choice) => (
          <MenuItem
            disableRipple
            onClick={(e) => {
              const lang = e.target.getAttribute("value");
              changeLanguage(lang);
              localStorage.setItem("lang", lang);
              setAnchorEl(null);
            }}
            key={choice.id}
            value={t(choice.id)}
          >
            {choice.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
