import React from "react";
import { Calendar } from "react-calendar";
import "./custom-calendar.css";
import { activityTypes } from "../../../constants/activity-types";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export const CustomCalendar = ({ startDate, endDate, setDate, activities }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = language === "cht" || language === "chs" ? "zh" : "en";

  // highlight selected tile range
  function tileClassName({ date, view }) {
    // Add class to tiles in month view only
    if (view === "month") {
      // convert date without time
      const tmpDate = new Date(date);
      tmpDate.setHours(0, 0, 0, 0);
      const startDateOnly = new Date(startDate);
      startDateOnly.setHours(0, 0, 0, 0);
      const endDateOnly = new Date(endDate);
      endDateOnly.setHours(0, 0, 0, 0);
      if (tmpDate >= startDateOnly && tmpDate <= endDateOnly) {
        return "react-calendar__tile--hasActive";
      }
    }
  }

  function tileContent({ date, view }) {
    // For each date, for each activity type, check if activities exist.
    if (view === "month") {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {activityTypes.map((activityType) => {
            // find activity
            const tmp = activities.find(
              (a) =>
                a.action === activityType.label &&
                new Date(a.date).toLocaleDateString() ===
                  date.toLocaleDateString()
            );
            if (!!tmp) {
              return (
                <span
                  style={{
                    color: activityType.bgcolor,
                    margin: "0 3px",
                    fontSize: 15,
                  }}
                >
                  &#9679;
                </span>
              );
            } else return <></>;
          })}
        </Box>
      );
    }
  }

  return (
    <Calendar
      value={[startDate, endDate]}
      onChange={setDate}
      tileClassName={tileClassName}
      tileContent={tileContent}
      locale={locale}
    />
  );
};
