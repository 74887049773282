import React, { useState } from "react";
import { Grid, InputAdornment, IconButton, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DynamicVisibilityComponent } from "../common/dynamic-visibility-component";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
  showInfoSnackbar,
} from "../../redux/slices/snackbar-slice";
import CustomTextField from "../common/custom-text-field";
import { getResetPasswordLink } from "../../api/auth";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";

const ResetPasswordField = ({ email, isCreate }) => {
  const { t } = useTranslation();

  const [resetPasswordLink, setResetPasswordLink] = useState("");

  const onResetPassword = () => {
    getResetPasswordLink(email)
      .then(({ data }) => {
        setResetPasswordLink(data.url);
        store.dispatch(
          showSuccessSnackbar(
            `The reset password link is generated. Expiring in ${data.expiration}.`
          )
        );
      })
      .catch((e) => {
        store.dispatch(showErrorSnackbar(e));
      });
  };

  return (
    <>
      <DynamicVisibilityComponent
        component={
          <Grid item xs={12}>
            <CustomTextField
              label={t("Reset password link")}
              value={resetPasswordLink}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="refresh reset password link"
                      onClick={onResetPassword}
                      edge="start"
                      disabled={!!!resetPasswordLink}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="copy reset password link"
                      onClick={() => {
                        navigator.clipboard.writeText(resetPasswordLink);
                        store.dispatch(
                          showInfoSnackbar(
                            "Link copied to clipboard. Share with cautious!"
                          )
                        );
                      }}
                      edge="end"
                      disabled={!!!resetPasswordLink}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                      aria-label="reset password in new tab"
                      onClick={() => {
                        // open the url in a pop up window
                        // window could be identified with the name `Reset password`
                        window.open(
                          resetPasswordLink,
                          "Reset password",
                          "width=800,height=900,scrollbars=yes"
                        );
                      }}
                      edge="end"
                      style={{ marginLeft: "5px" }}
                      disabled={!!!resetPasswordLink}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        }
        isVisible={!!resetPasswordLink}
      />
      <DynamicVisibilityComponent
        component={
          <Grid item xs={12}>
            <Button
              color="secondary"
              onClick={() => {
                onResetPassword();
              }}
            >
              {t("Reset Password")}
            </Button>
          </Grid>
        }
        isVisible={!isCreate && !!!resetPasswordLink}
        // the refresh icon button will replace the functionality of the reset password button after link is generated
        // therefore this button will be hidden when the link is generated
      />
    </>
  );
};

export default ResetPasswordField;
