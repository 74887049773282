import { Chip, Stack } from "@mui/material";

export const ChipList = ({ data, ...props }) => {
  return (
    <Stack direction="row" flexWrap="wrap">
      {data.map((d, index) => (
        <Chip sx={{ margin: "0 6px 6px 0" }} label={d} {...props} key={index} />
      ))}
    </Stack>
  );
};
