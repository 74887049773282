import React from "react";
import ReactEcharts from "echarts-for-react";
import { SummaryCard } from "../dashboard/dashboard";
import { computeActivityData } from "../visualisation-function/compute-data";
import { noDataGraph } from "../visualisation-function/compute-data";

export default function ActivityChart({
	activity,
	findActivity,
	chartRefs,
	activitiesMonths,
	language,
	t,
}) {
	return (
		<SummaryCard
			title={t(activity.name)}
			component={
				<div ref={chartRefs[activity.name]}>
					<ReactEcharts
						option={
							findActivity?.data.length > 0
								? computeActivityData(
										findActivity,
										activity.unit,
										activity.totalOf === "document",
										activitiesMonths,
										language,
										t
								  )
								: noDataGraph(t)
						}
					/>
				</div>
			}
		/>
	);
}
