import React from "react";
import ReactEcharts from "echarts-for-react";
import { SummaryCard } from "../dashboard/dashboard";
import { computeSummaryData } from "../visualisation-function/compute-data";
import { noDataGraph } from "../visualisation-function/compute-data";

export default function UsageSummaryChart({
	allSummarys,
	chartRefs,
	summaryMonths,
	t,
}) {
	return (
		<div>
			{allSummarys.length > 0 && (
				<SummaryCard
					title={t("Summary of Usage")}
					component={
						<div ref={chartRefs.Summary}>
							<ReactEcharts
								option={computeSummaryData(allSummarys, summaryMonths, t)}
							/>
						</div>
					}
				/>
			)}
			{(allSummarys === undefined || allSummarys.length === 0) && (
				<SummaryCard
					title={t("Summary of Usage")}
					component={
						<div ref={chartRefs.Summary}>
							<ReactEcharts option={noDataGraph(t)} />
						</div>
					}
				/>
			)}
		</div>
	);
}
