import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import CloseIcon from "@mui/icons-material/Close";
import { trimCompare } from "../../utils/utils";

const filter = createFilterOptions();

export const FarmAutocompleteField = ({
  label,
  options,
  excludeOptions = [],
  id,
  value,
  setValue,
  error,
  helperText,
  sx,
  textFieldProps,
  data,
}) => {
  const [localValue, setLocalValue] = useState(
    !!value
      ? {
          id: JSON.stringify(value),
          label: JSON.stringify(value),
        }
      : null
  );
  const {
    i18n: { language },
  } = useTranslation();
  const [open, setOpen] = useState(false);

  // when the local value changes, populate the actual form value
  useEffect(() => {
    if (!localValue) {
      setValue({ eng: "", chs: "", cht: "" });
      return;
    }
    if (
      !!localValue &&
      typeof localValue !== "string" &&
      !!localValue.id &&
      localValue.id !== "addition"
    )
      setValue(JSON.parse(localValue.id));
  }, [localValue]);

  // when value changes (like due to manually triggered clear), set local value
  useEffect(() => {
    if (!!localValue && JSON.stringify(value) === localValue.id) return;
    setLocalValue({
      id: JSON.stringify(value),
      label: JSON.stringify(value),
    });
  }, [value]);

  return (
    <>
      <Autocomplete
        value={localValue}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            setOpen(true);
          } else if (newValue && newValue.id === "addition") {
            setOpen(true);
          } else {
            setLocalValue(newValue);
          }
        }}
        id={id}
        filterOptions={(options, params) => {
          const filtered = filter(
            options.filter((x) => !excludeOptions.includes(x.id)),
            params
          );
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = filtered.some(
            (option) => inputValue === option.id
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              id: "addition",
              label: t(`Add Item`),
            });
          }
          return filtered;
        }}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (!!option.id && option.id !== "addition") {
            const tmp = JSON.parse(option.id);
            return tmp[language];
          }
          return option.label;
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            label={label}
            InputProps={{ ...params?.InputProps, sx }}
            {...textFieldProps}
          />
        )}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
      />
      <AddOptionDialog
        open={open}
        setOpen={setOpen}
        setValue={setLocalValue}
        data={data}
      />
    </>
  );
};

const initialState = {
  name: {
    eng: "",
    chs: "",
    cht: "",
  },
  tags: [],
};

const AddOptionDialog = ({ open, setOpen, setValue, data }) => {
  const [formState, setFormState] = useState(initialState);
  const [error, setError] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setError({});
  }, [formState]);

  const handleSubmit = () => {
    // Check if the name is clashed with existing data
    const errObj = {};
    for (const d of data) {
      if (!errObj["name.eng"] && trimCompare(formState.name.eng, d.name.eng)) {
        errObj["name.eng"] = t("There is an item with the same name");
      }
      if (!errObj["name.chs"] && trimCompare(formState.name.chs, d.name.chs)) {
        errObj["name.chs"] = t("There is an item with the same name");
      }
      if (!errObj["name.cht"] && trimCompare(formState.name.cht, d.name.cht)) {
        errObj["name.cht"] = t("There is an item with the same name");
      }
    }
    if (Object.keys(errObj).length > 0) {
      setError({ ...errObj });
      return;
    }
    // one of the names must not be empty
    if (!formState.name.eng && !formState.name.chs && !formState.name.cht) {
      setError({
        "name.eng": t("One of the name fields must be filled."),
        "name.chs": t("One of the name fields must be filled."),
        "name.cht": t("One of the name fields must be filled."),
      });
      return;
    }
    setValue({
      id: JSON.stringify(formState.name),
      label: JSON.stringify(formState.name),
    });
    setFormState(initialState);
    setOpen(false);
  };

  const handleClose = () => {
    setFormState(initialState);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      scroll="body"
      onClose={handleClose}
    >
      <Card
        elevation={0}
        sx={{
          padding: 3,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <IconButton
          sx={{ alignSelf: "flex-start" }}
          aria-label="cancel-icon"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <TextField
            id="name.eng"
            label={t("Name (ENG)")}
            onChange={(e) => {
              setFormState({
                ...formState,
                name: { ...formState.name, eng: e.target.value },
              });
            }}
            value={formState["name"]["eng"]}
            required
            error={!!t(error["name.eng"])}
            helperText={t(error["name.eng"])}
            InputProps={{
              sx: {
                backgroundColor: "white",
                borderRadius: 5,
                "&.Mui-focused": {
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.7)",
                },
              },
            }}
          />
          <TextField
            id="name.chs"
            label={t("Name (CHS)")}
            onChange={(e) => {
              setFormState({
                ...formState,
                name: { ...formState.name, chs: e.target.value },
              });
            }}
            value={formState["name"]["chs"]}
            required
            error={!!t(error["name.chs"])}
            helperText={t(error["name.chs"])}
            InputProps={{
              sx: {
                backgroundColor: "white",
                borderRadius: 5,
                "&.Mui-focused": {
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.7)",
                },
              },
            }}
          />
          <TextField
            id="name.cht"
            label={t("Name (CHT)")}
            onChange={(e) => {
              setFormState({
                ...formState,
                name: { ...formState.name, cht: e.target.value },
              });
            }}
            value={formState["name"]["cht"]}
            required
            error={!!t(error["name.cht"])}
            helperText={t(error["name.cht"])}
            InputProps={{
              sx: {
                backgroundColor: "white",
                borderRadius: 5,
                "&.Mui-focused": {
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.7)",
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("Add")}
          </Button>
        </DialogActions>
      </Card>
    </Dialog>
  );
};
