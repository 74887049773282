import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { queryList } from "../../api/common";
import { useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import { FORM_MODE } from "../../constants/choices";
import { uuidv4, validateRange } from "../../utils/field-utils";
import { getComponent } from "../common/components";
import { validateData } from "../../utils/utils";

const FooterForm = forwardRef((props, _ref) => {
  const { data, setData, config, options } = props;
  const { fields } = config;
  const mode = FORM_MODE.CREATE;
  const selectedFarmId = useSelector((state) => state.selectedFarm.farmId);
  const userId = useSelector((state) => state.auth.id);
  const initialState = {
    date: new Date(),
    action: config.label,
    item: {
      eng: "",
      chs: "",
      cht: "",
    },
    category: {
      eng: "",
      chs: "",
      cht: "",
    },
    unit: config.units[0] ? config.units[0] : "",
    quantity: 0,
    field: "0", // field will need to be processed later
    row: "0", // row will need to be processed later
    price: "0",
    farmId: selectedFarmId,
    farmName: {},
    userId: userId,
    userName: {},
    remarks: "",
    isActual: true,
    convertQuantity: 0,
    originalQuantity: 0,
    originalUnit: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [formOptions, setFormOptions] = useState(options ?? {});
  const [error, setError] = useState({});
  const { t } = useTranslation();

  useImperativeHandle(_ref, () => ({
    getAddHandler: () => handleAdd,
  }));

  const handleAdd = async () => {
    console.log("Fields: ", fields);
    console.log("FormData: ", formData);
    console.log("Data: ", data);
    if (!(await validateData({ mode, fields, formData, setError }))) return;
    const tmp = JSON.parse(JSON.stringify(data));
    // handle batch data entry for row if applicable
    if (!!fields.find((f) => f.type === "row")) {
      const rowSet = validateRange(formData.row);
      if (!!rowSet) {
        for (let rowNum of rowSet)
          tmp.push({ ...formData, row: rowNum.toString(), id: uuidv4() });
      }
    } else tmp.push({ ...formData, id: uuidv4() });
    console.log("temp after: ", tmp);
    setData(tmp);
    setFormData(initialState);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id || e.target.name]: e.target.value,
    });
  };
  console.log("Options footer: ", formOptions);
  return (
    <TableHead
      sx={{
        bgcolor: "#B0D776",
        width: "100%",
      }}
    >
      <TableRow>
        <TableCell padding="checkbox" />
        {fields
          .filter((field) => field[mode]?.hidden !== true)
          .map((field) => (
            <TableCell key={field.id} align="left" padding="normal">
              {getComponent({
                field,
                formData,
                setFormData,
                options: formOptions,
                setOptions: setFormOptions,
                error,
                handleChange,
                t,
                commonProps: {
                  variant: "filled",
                },
                sx: {
                  backgroundColor: "white",
                  borderRadius: 5,
                  boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.25)",
                  "&.Mui-focused": {
                    backgroundColor: "white",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.7)",
                  },
                  "&:active": {
                    backgroundColor: "white",
                  },
                },
              })}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
});

export default FooterForm;
