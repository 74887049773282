import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { showLoading } from "../../redux/slices/loading-slice";
import { InputAdornment, IconButton, Box, Link, Button } from "@mui/material";
import CustomTextField from "../common/custom-text-field";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginUser } from "../../api/auth";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import broccoli from "../../images/broccoli.jpg";
import grape from "../../images/grape.jpg";
import tomato from "../../images/tomato.jpg";
import { useTranslation } from "react-i18next";
import { LangSettings } from "../lang-settings/lang-settings";
import ResetPasswordDialog from "../reset-password/reset-paswword-dialog";

export function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const images = [broccoli, grape, tomato];
  const [open, setOpen] = useState(false);

  const onLogin = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    loginUser(email, password);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          paddingLeft: {
            xs: 2,
            md: 10,
          },
          paddingRight: {
            xs: 2,
            md: 10,
          },
          overflow: "auto",
          position: "relative",
        }}
      >
        <LangSettings
          sx={{
            position: "absolute",
            top: 5,
            right: 5,
            backgroundColor: "white",
          }}
        />
        <img
          src="/logo.png"
          alt="logo"
          style={{
            width: "90%",
            maxWidth: 200,
            objectFit: "contain",
            margin: 10,
            marginTop: { xs: 0, md: 100, lg: 0 },
            marginBottom: 40,
          }}
        />
        <CustomTextField
          type="email"
          label={t("email")}
          value={email}
          setValue={setEmail}
          InputProps={{
            required: true,
          }}
          sx={{
            marginBottom: 3,
          }}
        />
        <CustomTextField
          type={showPassword ? "text" : "password"}
          label={t("password")}
          value={password}
          setValue={setPassword}
          InputProps={{
            required: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            marginBottom: 3,
          }}
        />
        <Link
          underline="hover"
          color="#92CF34"
          component="button"
          variant="body1"
          sx={{ alignSelf: "flex-start", mb: 3 }}
          onClick={() => setOpen(true)}
        >
          {t("forgot_password")}
        </Link>
        <Button
          onClick={(e) => {
            onLogin(e);
          }}
          sx={{
            mb: 3,
          }}
        >
          {t("login")}
        </Button>
      </Box>
      <Box
        sx={{
          display: {
            xs: "none",
            md: "initial",
          },
          flex: {
            xs: 1,
            md: 2,
          },
          overflow: "auto",
        }}
      >
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          transitionTime={1000}
          emulateTouch={true}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt="carousel"
                style={{
                  height: "100vh",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </Carousel>
      </Box>
      <ResetPasswordDialog open={open} setOpen={setOpen} />
    </Box>
  );
}
