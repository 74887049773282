import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Routers } from "./components/routers/routers";
import * as serviceWorkerRegistration from "./service-worker-registration";
import reportWebVitals from "./report-web-vitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./i18n/config";

const THEME = createTheme({
  typography: {
    fontFamily: "Montserrat, Roboto, Helvetica, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#B0D776",
    },
    secondary: {
      main: "#CF401F",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        contained: {
          width: 200,
          height: "max-content",
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <Provider store={store}>
        <Routers />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) =>
    window.dispatchEvent(
      new CustomEvent("showRefreshSnackbar", { detail: registration })
    ),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
