import { axiosInstance } from "./axios-instance";

export const query = async (url, source, isGetOne) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(url, {
        withCredentials: true,
      })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        allData: response.data,
        data: response.data.data,
        total: response.data.total,
        message: response.data.message,
      }))
      .then(({ status, headers, allData, data, total, message }) => {
        if (!!total || total === 0) total = parseInt(total, 10);
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, allData, data, total, message });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

export const queryList = async (source, params) => {
  // construct URL to query
  const url = `/${source}?${params}`;
  return query(url, source);
};

// export const getOne = async (source, id) => {
//   // construct URL to query
//   const url = `/${source}/${id}`;
//   return query(url);
// };

export const create = async (source, data) => {
  // remove _id field
  delete data["_id"];
  let url = `/${source}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data, { withCredentials: true })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, data });
      })
      .catch((err) => {
        if (
          !!err.response &&
          !!err.response.data &&
          err.response.data.message
        ) {
          return reject(err.response.data.message);
        }
        return reject(err.message);
      });
  });
};

export const update = async (source, data) => {
  const url = `/${source}/${!!data._id ? data._id : data.id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(url, data, { withCredentials: true })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, data });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

export const deleteOne = async (source, id) => {
  let url = `/${source}/${id}`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(url, {
        withCredentials: true,
      })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: {},
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, data });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

export const getOne = async (source, id) => {
  const url = `${source}/${id}`;
  return query(url, source, true);
};

export const createMock = async (source, data) => {
  data.id = "abc";
  return { data };
};

export const updateMock = async (source, data) => {
  return { data };
};
