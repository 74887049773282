import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { FORM_MODE } from "../../constants/choices";
import { getTableFieldsArr } from "../../utils/utils";
import EnhancedTable from "../common/enhanced-table";
import FormDialog from "../common/form-dialog";
import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import FarmFooterForm from "./footer-form";
import { useTranslation } from "react-i18next";

export default function FarmSettingTable(props) {
  const { data, setData, options, config } = props;
  const tableRef = useRef();
  const footerRef = useRef();
  const [editConfig] = useState({
    ...config,
    fields: config.fields.filter((c) => {
      return !(c.id === "quantity" || c.id === "unit");
    }),
  });
  const [dialogStatus, setDialogStatus] = useState({
    open: false,
    mode: FORM_MODE.CREATE,
    formData: {},
    formOptions: {},
  });
  const { t } = useTranslation();
  console.log("EConfig: ", editConfig);
  return (
    <Box sx={{ pl: 6, pr: 6 }}>
      <EnhancedTable
        ref={tableRef}
        data={data}
        fields={getTableFieldsArr(config)}
        deleteFunc={() => {
          setData(
            data.filter(
              (x) => !tableRef.current.getSelectedData().includes(x.id)
            )
          );
          tableRef.current.getSetSelected()([]);
        }}
        handleRowClick={(e, rowId) => {
          setDialogStatus({
            open: true,
            mode: FORM_MODE.EDIT,
            formData: data.find((d) => d.id === rowId),
            formOptions: options,
            onSubmit: (formData) => {
              setData(
                data.map((d) => {
                  if (d.id === formData.id) return formData;
                  return d;
                })
              );
              setDialogStatus((prevState) => ({ ...prevState, open: false }));
            },
          });
        }}
        tableFooterSlot={
          <FarmFooterForm ref={footerRef} {...props} data={data} />
        }
        addItemBtnSlot={
          <Stack direction="row" justifyContent="flex-end" sx={{ padding: 2 }}>
            <Button onClick={() => footerRef.current.getAddHandler()()}>
              {t("Add Item")}
            </Button>
          </Stack>
        }
        useLocalFilterSort
        dense
        searchDebouncePeriod={200}
      />
      <FormDialog
        config={editConfig}
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
      />
    </Box>
  );
}
