import { CustomDateInput } from "./custom-date-input";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CustomAutocompleteField } from "./custom-autocomplete-field";
import { TagInputField } from "./tag-input-field";
import { CustomTagsField } from "./custom-tags-field";
import { resolvePath } from "../../utils/utils";
import { FarmAutocompleteField } from "./farm-autocomplete-field";

export const getComponent = (props) => {
  const {
    field,
    formData,
    setFormData,
    options,
    setOptions,
    error,
    handleChange,
    t,
    commonProps,
    sx,
  } = props;
  if (!formData) return null;
  let ops;
  switch (field.type) {
    case "date":
      return (
        <CustomDateInput
          date={formData[field.id]}
          setDate={(v) => handleChange({ target: { id: field.id, value: v } })}
          label={t(field.name)}
          sx={sx}
          textFieldProps={commonProps}
        />
      );
    case "autocomplete":
      ops = getOptions(field, options, setOptions);
      if (ops === null) return null;
      console.log("component ops: ", ops);
      return (
        <CustomAutocompleteField
          label={t(field.name)}
          options={ops}
          excludeOptions={field.excludeOptions}
          id={field.id}
          value={formData[field.id]}
          setValue={(v) =>
            setFormData({
              ...formData,
              [field.id]: v,
            })
          }
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
          sx={sx}
          textFieldProps={commonProps}
        />
      );
    case "autocompleteInventory":
      console.log("here special");
      ops = getInventoryOptions(field, options, setOptions, formData);
      if (ops === null) return null;
      console.log("component ops: ", ops);
      return (
        <CustomAutocompleteField
          label={t(field.name)}
          options={ops}
          excludeOptions={field.excludeOptions}
          id={field.id}
          value={formData[field.id]}
          setValue={(v) =>
            setFormData({
              ...formData,
              [field.id]: v,
            })
          }
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
          sx={sx}
          textFieldProps={commonProps}
        />
      );
    case "autocompleteFarm":
      ops = getOptions(field, options, setOptions);
      if (ops === null) return null;
      return (
        <FarmAutocompleteField
          label={t(field.name)}
          options={ops}
          excludeOptions={field.excludeOptions}
          id={field.id}
          value={formData[field.id]}
          setValue={(v) => setFormData({ ...formData, [field.id]: v })}
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
          sx={sx}
          textFieldProps={commonProps}
          data={field.data}
        />
      );
    case "nested":
      const split = field.id.split(".");
      const parent = split[0];
      const child = split[1];
      return (
        <TextField
          id={field.id}
          label={t(field.name)}
          onChange={(e) => {
            handleChange({
              target: {
                id: parent,
                value: {
                  ...formData[parent],
                  [child]: e.target.value,
                },
              },
            });
          }}
          value={resolvePath(formData, field.id)}
          required
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
        />
      );
    case "unit":
      // Workaround to support activities config
      if (
        formData.action === "Pesticide" ||
        formData.action === "Foliar" ||
        formData.action === "Fertilizer" ||
        formData.action === "Fungicide"
      ) {
        field.options = field.units(formData, options);
      } else {
        field.options = () => field.units;
      }
    /* falls through */
    case "select":
      if (
        formData.action === "Pesticide" ||
        formData.action === "Foliar" ||
        formData.action === "Fertilizer" ||
        formData.action === "Fungicide"
      ) {
        ops = field.options;
      } else {
        ops = getOptions(field, options, setOptions);
      }
      console.log("ops: ", ops);
      if (ops === null) return null;
      return (
        <FormControl
          sx={{
            width: "100%",
          }}
          error={!!t(error[field.id])}
          {...commonProps}
        >
          <InputLabel>{t(field.name)}</InputLabel>
          <Select
            id={field.id}
            label={t(field.name)}
            value={formData[field.id]}
            onChange={(v) => {
              v.target.name = field.id;
              handleChange(v);
            }}
            sx={sx}
          >
            {ops?.map((choice) => {
              if (typeof choice !== "object")
                choice = {
                  id: choice,
                  name: choice,
                };
              return (
                <MenuItem key={choice.id} value={choice.id}>
                  {t(choice.name)}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{t(error[field.id])}</FormHelperText>
        </FormControl>
      );
    case "tags":
      ops = getOptions(field, options, setOptions);
      if (ops === null) return null;
      return (
        <TagInputField
          id={field.id}
          label={t(field.name)}
          values={formData[field.id]}
          setValues={(v) => setFormData({ ...formData, [field.id]: v })}
          options={ops}
        />
      );
    case "tagsCustom":
      ops = getOptions(field, options, setOptions);
      if (ops === null) return null;
      return (
        <CustomTagsField
          label={t(field.name)}
          values={formData[field.id]}
          setValues={(v) => setFormData({ ...formData, [field.id]: v })}
          options={ops}
        />
      );
    case "field": //set field number as not required
      return (
        <TextField
          id={field.id}
          label={t(field.name)}
          onChange={handleChange}
          value={formData[field.id]}
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
          InputProps={{ sx }}
          {...field.props}
          {...commonProps}
        />
      );
    case "row": //set row range as not required
      return (
        <TextField
          id={field.id}
          label={t(field.name)}
          onChange={handleChange}
          value={formData[field.id]}
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
          InputProps={{ sx }}
          {...field.props}
          {...commonProps}
        />
      );
    case "price": //set row range as not required
      return (
        <TextField
          id={field.id}
          label={t(field.name)}
          onChange={handleChange}
          value={formData[field.id]}
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
          InputProps={{ sx }}
          {...field.props}
          {...commonProps}
        />
      );
    case "global":
      return null;
    case "text":
    default:
      return (
        <TextField
          id={field.id}
          label={t(field.name)}
          onChange={handleChange}
          value={formData[field.id]}
          required
          error={!!t(error[field.id])}
          helperText={t(error[field.id])}
          InputProps={{ sx }}
          {...field.props}
          {...commonProps}
        />
      );
  }
};

const getOptions = (field, options, setOptions) => {
  const key = field.id;
  console.log("Field id: ", field.id);
  console.log("Field: ", field);
  console.log("Options: ", options);
  console.log("Key: ", options[key]);
  console.log("type: ", typeof field.options);
  console.log("invoke ops: ", field.options);
  // Return values directly if the options array exists
  if (Array.isArray(options[key])) return options[key];

  // If the options is an object, it should be a promise which haven't resolved,
  // thus return null to prevent the component from being rendered
  if (typeof options[key] === "object") return null;

  if (options[key] === undefined && field.options) {
    // Retrive the options data using path provided
    if (typeof field.options === "string") {
      return resolvePath(options, field.options);
    }
    // Create a promise which will set the value when it gets the options data
    if (typeof field.options === "function") {
      console.log("here components");
      setOptions((prevData) => ({
        ...prevData,
        [key]: Promise.resolve(field.options()).then((data) => {
          setOptions((prevData) => ({ ...prevData, [key]: data ?? [] }));
        }),
      }));
      console.log("final ops: ", options[key]);
      return null;
    }
  }

  // It is impossible to retrieve any options, thus return an empty array
  return [];
};

const getInventoryOptions = (field, options, setOptions, formData) => {
  console.log("formData", formData);
  return field.options(formData)
    ? resolvePath(options, field.options(formData))
    : [];
};

const getInventoryUnitOptions = (field, options, setOptions, formData) => {
  return field.options(formData)
    ? resolvePath(options, field.options(formData))
    : [];
};
