import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  visible: false,
  title: "",
  content: "",
  confirm: null,
  cancel: null,
  onConfirm: null,
  onCancel: null,
  data: "",
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, action) => {
      const {
        type,
        title,
        content,
        confirm,
        cancel,
        onConfirm,
        onCancel,
        data,
      } = action.payload;
      state.type = type;
      state.title = title;
      state.content = content;
      state.confirm = confirm;
      state.cancel = cancel;
      state.onConfirm = onConfirm;
      state.onCancel = onCancel;
      state.data = data;
      state.visible = true;
    },
    hideModal: () => {
      return initialState;
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
