import { Grid, Box, Typography, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { queryList } from "../../api/common";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { setTitle } from "../../redux/slices/title-slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { set } from "date-fns";
import { da, de, el, tr } from "date-fns/locale";
import { use } from "echarts";
import { TIME_FRAME_CHOICES } from "../../constants/choices";
import {
  summarysList,
  activities,
  expensesList,
  financialActivities,
  chartsDashboard,
  inventoryItems,
} from "../../constants/visualisation";
import { createUrlQuery } from "../visualisation-function/compute-data";
import {
  getDateRange,
  getDateRangeType,
} from "../visualisation-function/compute-date-range";
import ExportChartModal from "./export-chart-modal";
import SalesChart from "../visualization-chart/sales-chart";
import ExpensesChart from "../visualization-chart/expenses-chart";
import ProfitOrLossChart from "../visualization-chart/profitorloss-chart";
import UsageSummaryChart from "../visualization-chart/usage-summary-chart";
import MonthlySalesChart from "../visualization-chart/monthly-sales-chart";
import AlertTable from "./alert-table";
import { CurrentWeatherConditions, WeatherForecast } from "../weather/weather";
import { Weather } from "../weather/weather";
import { getOne } from "../../api/common";
import SelectTimeFrame from "./select-time-frame";

const useStyles = makeStyles((theme) => ({
  summaryCards: {
    display: "flex",
    flexWrap: "wrap",
    margin: 16,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  summaryCard: {
    margin: theme.spacing(1),
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  titleStyle: {
    fontSize: "24px", // Adjust the font size as needed
    fontWeight: "bold",
    marginLeft: 5,
    marginBottom: 15,
    // Add more styles if necessary
  },
}));

function createData(startTime, type, severity, status) {
  return {
    startTime,
    type,
    severity,
    status,
  };
}
const rowsData = [
  createData("2023-06-01", "Low Temperature", "Minor", "Active"),
  createData("2023-06-06", "High Humidity", "Minor", "Active"),
  createData("2023-06-10", "Near Flowering Season", "Major", "Acknowledged"),
  createData("2023-04-13", "No Rainfall for 7 Days", "Critical", "Active"),
  createData("2023-06-15", "High Temperature", "Minor", "Active"),
  createData("2023-03-20", "No Rainfall for Next 7 Days", "Critical", "Active"),
];

const initialColumns = [
  {
    Header: "Start Time",
    accessorKey: "startTime",
    numeric: false,
    disablePadding: true,
    isVisible: true,
  },
  {
    Header: "Type",
    accessorKey: "type",
    numeric: false,
    disablePadding: true,
    isVisible: true,
  },
  {
    Header: "Severity",
    accessorKey: "severity",
    numeric: false,
    disablePadding: true,
    isVisible: true,
  },
  {
    Header: "Status",
    accessorKey: "status",
    numeric: false,
    disablePadding: true,
    isVisible: true,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function SummaryCard({ title, value, valueDescription, component }) {
  const classes = useStyles();
  return (
    <Paper elevation={2} className={classes.summaryCard}>
      <Typography color={"textSecondary"} variant="h6" gutterBottom>
        {title}
      </Typography>
      {component || (
        <Typography color={"primary"} variant={value.length > 12 ? "h4" : "h3"}>
          {value}
          {valueDescription && (
            <Typography color={"primary"} component="span">
              {valueDescription}
            </Typography>
          )}
        </Typography>
      )}
    </Paper>
  );
}

export const Dashboard = ({ title }) => {
  const classes = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  const chartRefs = {
    TotalSales: useRef(null),
    TotalExpenses: useRef(null),
    ProfitOrLoss: useRef(null),
    Summary: useRef(null),
    MonthlySales: useRef(null),
    Aerating: useRef(null),
    Sowing: useRef(null),
    Fertilizer: useRef(null),
    Pesticide: useRef(null),
    Foliar: useRef(null),
    Fungicide: useRef(null),
    Harvest: useRef(null),
    Sales: useRef(null),
    Transplant: useRef(null),
    Others: useRef(null),
    Inventory: useRef(null),
  };

  const initialValueInventory = {
    fertilizers: [],
    pesticides: [],
    foliars: [],
    fungicides: [],
  };

  const [loading, setLoading] = useState(true);
  const [allActivities, setAllActivities] = useState([]);
  const [allSummarys, setAllSummarys] = useState([]);
  const [summarySales, setSummarySales] = useState([]);
  const [bigNumberData, setBigNumberData] = useState([]);
  const [timeFrame, setTimeFrame] = useState("");
  const [financialMetrics, setFinancialMetrics] = useState([]);
  const [totalSales, setTotalSales] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState([]);
  const [profitOrLoss, setProfitOrLoss] = useState([]);
  const [currencyFormat, setCurrencyFormat] = useState("RM");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState(rowsData);
  const [columns, setColumns] = useState(initialColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const openColumnMenu = Boolean(anchorEl);
  const filteredInventoryItems = inventoryItems.filter(
    (item) => item.label !== "Plant"
  );
  const [inventory, setInventory] = useState(initialValueInventory);

  const toggleColumnVisibility = (columnAccessorKey) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        if (column.accessorKey === columnAccessorKey) {
          return { ...column, isVisible: !column.isVisible };
        }
        return column;
      })
    );
  };

  const handleClickColumnIcon = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseColumnMenu = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.startTime);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, startTime) => {
    const selectedIndex = selected.indexOf(startTime);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, startTime);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );

  const handleChangeTimeFrame = (e) => {
    setTimeFrame(e.target.value);
  };
  const role = useSelector((state) => state.auth.role);
  const selectedFarmId =
    useSelector((state) => state.selectedFarm.farmId) ?? null;

  const isAllFarmData = role === "admin" ? true : false; //display all data or specify farm data

  //Set graph month
  // const summaryMonths = 11; //1 years

  const getLengthOfMonths = (timeFrame) => {
    switch (timeFrame) {
      case "thisMonth":
        return 0;
      case "lastMonth":
        return -1;
      case "past3Months":
        return 2;
      case "past6Months":
        return 5;
      case "past12Months":
        return 11;
      case "thisYear":
        const currentMonth = new Date().getMonth();
        return currentMonth;
      case "lastYear":
        return 13;
      case "past3Years":
        return 15;
      case "past5Years":
        return 17;
      case "past10Years":
        return 22;
      default:
        return 11;
    }
  };

  const summaryMonths = getLengthOfMonths(timeFrame);
  const financialMonths = getLengthOfMonths(timeFrame);

  useEffect(() => {
    console.log("Enter UseEffect");
    console.log("TimeFrame in useEffect: ", timeFrame);
    dispatch(setTitle("Dashboard"));

    /********************Big Number********************/
    let tempBigData = [];
    const PlannedTaskDateRange = getDateRange(0, true);

    let PlannedTaskQueryString = createUrlQuery(
      `filter[date][$gte]=${PlannedTaskDateRange[0]}&filter[date][$lte]=${PlannedTaskDateRange[1]}`,
      `&filter[farmId]=${selectedFarmId}`,
      selectedFarmId
    );
    queryList(`tasks`, PlannedTaskQueryString).then((res) => {
      var today = new Date();
      const todayDate =
        today.getFullYear() +
        "-" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(today.getDate()).padStart(2, "0");
      const plannedTaskToday = res.data.reduce((acc, curr) => {
        const date = curr.date.split(/[T]+/);
        if (date[0] === todayDate) {
          acc += 1;
        }
        return acc;
      }, 0);
      tempBigData.push({
        id: 1,
        name: "Planned Tasks Today",
        value: plannedTaskToday ?? 0,
        valueDescription: "",
        unit: "",
      });
      tempBigData.push({
        id: 2,
        name: "Planned Tasks This Month",
        value: res?.total ?? 0,
        valueDescription: "",
        unit: "",
      });
    });

    const dateRange = getDateRange(0);
    let totalHarvestQueryString = createUrlQuery(
      `startDate=${dateRange[0]}&endDate=${dateRange[1]}&&totalOf=quantity&groupBy=month,item&action=Harvest&allFarms=${isAllFarmData}`,
      `&farmId=${selectedFarmId}`,
      selectedFarmId
    );
    queryList(`visualisation`, totalHarvestQueryString).then((res) => {
      const Harvest = res.allData.data.reduce(
        (previousValue, currentValue) => previousValue + currentValue.total,
        0
      );
      tempBigData.push({
        id: 3,
        name: "Total Harvest This Month",
        value: Harvest,
        valueDescription: "",
        unit: "kg",
      });
    });
    let TopSalesQueryString = createUrlQuery(
      `startDate=${dateRange[0]}&endDate=${dateRange[1]}&&totalOf=quantity&groupBy=month,item&action=Sales&allFarms=${isAllFarmData}`,
      `&farmId=${selectedFarmId}`,
      selectedFarmId
    );
    queryList(`visualisation`, TopSalesQueryString).then((res) => {
      const topSales = res.data.reduce(
        (prev, current) => (prev.total > current.total ? prev : current),
        0
      );

      tempBigData.push({
        id: 4,
        name: "Top Sales in this Month",
        value: topSales.item ?? "Null",
        valueDescription: topSales.total ?? "",
        unit: topSales.total ? "kg" : "",
      });
    });
    setBigNumberData(tempBigData);

    /********************Inventory********************/
    var filteredInventory = initialValueInventory;

    async function fetchInventoryData() {
      await getOne("farms", selectedFarmId).then((res) => {
        filteredInventoryItems.map((item) => {
          filteredInventory = {
            ...filteredInventory,
            [item.options]: res.data[item.options],
          };
        });

        setInventory(filteredInventory);
      });
    }

    fetchInventoryData();

    /********************Summary********************/
    let summaryDateRage = getDateRangeType(timeFrame);
    summarysList.forEach((summary) => {
      let summaryQueryString = createUrlQuery(
        `startDate=${summaryDateRage[0]}&endDate=${summaryDateRage[1]}&&totalOf=quantity&groupBy=month&action=${summary.name}&allFarms=${isAllFarmData}`,
        `&farmId=${selectedFarmId}`,
        selectedFarmId
      );

      queryList(`visualisation`, summaryQueryString).then((res) => {
        setAllSummarys((prevArray) => [
          ...prevArray,
          {
            name: summary.name,
            unit: summary.unit,
            data: res.data.sort((a, b) => a.year - b.year || a.month - b.month),
          },
        ]);
      });
    });

    /********************Monthly Sales********************/
    let summarySalesDateRange = getDateRange(0); //current month
    let monthlySalesqueryString = createUrlQuery(
      `startDate=${summarySalesDateRange[0]}&endDate=${summarySalesDateRange[1]}&&totalOf=quantity&groupBy=month,item&action=Sales&allFarms=${isAllFarmData}`,
      `&farmId=${selectedFarmId}`,
      selectedFarmId
    );
    queryList(`visualisation`, monthlySalesqueryString).then((res) => {
      setSummarySales(res.data);
    });

    /********************Activities********************/
    let activitiesDateRange = getDateRangeType(timeFrame);
    activities.forEach((activity) => {
      let queryString = createUrlQuery(
        `startDate=${activitiesDateRange[0]}&endDate=${activitiesDateRange[1]}&&totalOf=${activity.totalOf}&groupBy=month,item&action=${activity.name}&allFarms=${isAllFarmData}`,
        `&farmId=${selectedFarmId}`,
        selectedFarmId
      );
      queryList(`visualisation`, queryString).then((res) => {
        setAllActivities((prevArray) => [
          ...prevArray,
          {
            name: activity.name,
            data: res.data.sort((a, b) => a.year - b.year || a.month - b.month),
          },
        ]);
      });
    });

    /********************Financial Activities********************/
    let financialActivitiesDateRange = getDateRangeType(timeFrame);
    financialActivities.forEach((activity) => {
      let queryString = createUrlQuery(
        `startDate=${financialActivitiesDateRange[0]}&endDate=${financialActivitiesDateRange[1]}&&totalOf=quantity&priceOf=retrievePrice&groupBy=month&action=${activity.name}&allFarms=${isAllFarmData}`,
        `&farmId=${selectedFarmId}`,
        selectedFarmId
      );
      queryList(`visualisation`, queryString).then((res) => {
        setFinancialMetrics((prevArray) => [
          ...prevArray,
          {
            name: activity.name,
            unit: activity.unit,
            data: res.data.sort((a, b) => a.year - b.year || a.month - b.month),
          },
        ]);
      });
    });

    /********************Total Sales********************/
    let totalSalesDateRange = getDateRangeType(timeFrame);
    const salesObject = activities.find((obj) => obj.name === "Sales");
    let totalSalesQueryString = createUrlQuery(
      `startDate=${totalSalesDateRange[0]}&endDate=${totalSalesDateRange[1]}&&totalOf=${salesObject.totalOf}&priceOf=retrievePrice&groupBy=month,item&action=${salesObject.name}&allFarms=${isAllFarmData}`,
      `&farmId=${selectedFarmId}`,
      selectedFarmId
    );
    queryList(`visualisation`, totalSalesQueryString).then((res) => {
      setTotalSales(() => [
        {
          name: salesObject.name,
          unit: currencyFormat,
          data: res.data.sort((a, b) => a.year - b.year || a.month - b.month),
        },
      ]);
    });

    /********************Total Expenses********************/
    let totalExpensesDateRange = getDateRangeType(timeFrame);
    expensesList.forEach((expense) => {
      let totalExpensesQueryString = createUrlQuery(
        `startDate=${totalExpensesDateRange[0]}&endDate=${totalExpensesDateRange[1]}&&totalOf=${expense.totalOf}&priceOf=retrievePrice&groupBy=month,item&action=${expense.name}&allFarms=${isAllFarmData}`,
        `&farmId=${selectedFarmId}`,
        selectedFarmId
      );
      queryList(`visualisation`, totalExpensesQueryString).then((res) => {
        setTotalExpenses((prevArray) => [
          ...prevArray,
          {
            name: expense.name,
            unit: currencyFormat,
            data: res.data.sort((a, b) => a.year - b.year || a.month - b.month),
          },
        ]);
      });
    });

    setLoading(false);
    return () => {
      setAllSummarys([]);
      setSummarySales([]);
      setFinancialMetrics([]);
      setTotalExpenses([]);
    };
  }, [timeFrame]);

  return (
    <div>
      {!loading && (
        <>
          <Box
            sx={{ flexGrow: 1, paddingBottom: 5, marginTop: 5 }}
            className={classes.summaryCards}
          >
            <div htmlFor="summary" className={classes.titleStyle}>
              Summary
            </div>
            <Grid container spacing={2}>
              {bigNumberData
                .sort((a, b) => (a.id > b.id ? 1 : -1))
                .map((data, index) => {
                  return (
                    <Grid item xs={12} xl={3} md={6} key={index}>
                      <SummaryCard
                        title={t(data.name)}
                        value={
                          typeof data.value === "object"
                            ? t(data.value[language])
                            : t(data.value)
                        }
                        valueDescription={
                          t(data.valueDescription) + t(data.unit)
                        }
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <Box
            sx={{ flexGrow: 1, paddingBottom: 5 }}
            className={classes.summaryCards}
          >
            <div htmlFor="inventoryTitle" className={classes.titleStyle}>
              Inventories
            </div>
            <Grid container spacing={2}>
              {filteredInventoryItems.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    xl={3}
                    md={6}
                    key={index}
                    component={Link}
                    to={`/inventorydetails/${item.label}`}
                    style={{ textDecoration: "none" }}
                  >
                    <SummaryCard
                      title={t(item.label)}
                      value={
                        inventory[item.options].length +
                        (inventory[item.options].length <= 1
                          ? " item"
                          : " items")
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} className={classes.summaryCards}>
            <div htmlFor="charts" className={classes.titleStyle}>
              Data Analytics
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={3} md={6}>
                <SelectTimeFrame
                  timeFrame={timeFrame}
                  handleChangeTimeFrame={handleChangeTimeFrame}
                  t={t}
                />
              </Grid>
              <Grid item xs={12} xl={3} md={6}>
                <ExportChartModal
                  chartRefs={chartRefs}
                  charts={chartsDashboard}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <SalesChart
                  chartRefs={chartRefs}
                  totalSales={totalSales}
                  financialMonths={financialMonths}
                  t={t}
                  currencyFormat={currencyFormat}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ExpensesChart
                  chartRefs={chartRefs}
                  totalExpenses={totalExpenses}
                  financialMonths={financialMonths}
                  t={t}
                  currencyFormat={currencyFormat}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ProfitOrLossChart
                  chartRefs={chartRefs}
                  financialMetrics={financialMetrics}
                  financialMonths={financialMonths}
                  t={t}
                  currencyFormat={currencyFormat}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                xl={7}
                component={Link}
                to={"/activityusage"}
                style={{ textDecoration: "none" }}
              >
                <UsageSummaryChart
                  chartRefs={chartRefs}
                  allSummarys={allSummarys}
                  summaryMonths={summaryMonths}
                  t={t}
                />
              </Grid>
              <Grid item xs={12} xl={5} md={12}>
                <MonthlySalesChart
                  chartRefs={chartRefs}
                  summarySales={summarySales}
                  language={language}
                  t={t}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={6} md={12}>
                <SummaryCard title={t("Weather")} component={<Weather />} />
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* <AlertTable
                  rows={data}
                  columns={columns}
                  selected={selected}
                  dense={dense}
                  order={order}
                  orderBy={orderBy}
                  handleSelectAllClick={handleSelectAllClick}
                  handleRequestSort={handleRequestSort}
                  visibleRows={visibleRows}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  emptyRows={emptyRows}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangeDense={handleChangeDense}
                  handleClickColumnIcon={handleClickColumnIcon}
                  handleCloseColumnMenu={handleCloseColumnMenu}
                  anchorEl={anchorEl}
                  openColumnMenu={openColumnMenu}
                  toggleColumnVisibility={toggleColumnVisibility}
                /> */}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};
Dashboard.propTypes = {};
