import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// HomeController redirects users according to their roles
export const HomeController = () => {
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    switch (role) {
      case "owner":
      case "admin":
        navigate("/dashboard");
        break;
      case "farmer":
        navigate("/activities");
        break;
      default:
        navigate("/signin");
    }
  }, [navigate, role]);

  return <></>;
};
