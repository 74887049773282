import { Grid, TextField } from "@mui/material";
import React from "react";

export function CustomNameField(props) {
  const { name, label, values, setValues, ...restProps } = props;
  const { eng, chs, cht } = values;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          name={name}
          label={label["eng"]}
          variant="outlined"
          fullWidth
          value={eng}
          onChange={(e) => {
            const newValues = { ...values, eng: e.target.value };
            setValues(newValues);
          }}
          {...restProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name={name}
          label={label["chs"]}
          variant="outlined"
          fullWidth
          value={chs}
          onChange={(e) => {
            const newValues = { ...values, chs: e.target.value };
            setValues(newValues);
          }}
          {...restProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name={name}
          label={label["cht"]}
          variant="outlined"
          fullWidth
          value={cht}
          onChange={(e) => {
            const newValues = { ...values, cht: e.target.value };
            setValues(newValues);
          }}
          {...restProps}
        />
      </Grid>
    </Grid>
  );
}
