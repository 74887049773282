import React from "react";
import ProfileIcon from "@mui/icons-material/AccountCircleOutlined";
import LogOutIcon from "@mui/icons-material/LogoutOutlined";

export const mainMenuSettingsListItem = [
  {
    id: "0",
    title: "Profile",
    pathname: "/profile",
    icon: <ProfileIcon fontSize="large" />,
    role: ["farmer", "admin", "owner"],
    enabled: "true",
    color: "#646464",
  },
  {
    id: "1",
    title: "Log out",
    pathname: "/",
    icon: <LogOutIcon fontSize="large" />,
    role: ["admin", "owner", "farmer"],
    enabled: "true",
    color: "#646464",
  },
];
