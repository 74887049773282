import { DataArray } from "@mui/icons-material";
import { monthList } from "../../constants/visualisation";

export function createUrlQuery(queryString, specificQueryString = "", farmID) {
  let combinationString = queryString;
  if (farmID && specificQueryString !== "")
    combinationString += specificQueryString;

  return combinationString;
}

/*********** No Data Function ************/
export function noDataGraph(t) {
  return {
    title: {
      show: true,
      textStyle: {
        color: "#bcbcbc",
      },
      text: t("No Data"),
      left: "center",
      top: "center",
    },
  };
}

/*********** Compute Financial Metric Function ************/
function computeProfitOrLoss(
  financialData,
  timeType,
  t,
  lengend,
  yAxis,
  series,
  xAxisType
) {
  let cumulativeSalesData = [];
  let cumulativeExpensesData = [];
  let cumulativeProfitOrLoss = [];
  for (let i = 0; i < timeType.length; i++) {
    cumulativeSalesData.push(0);
    cumulativeExpensesData.push(0);
    cumulativeProfitOrLoss.push(0);
  }
  financialData.map((data, index) => {
    let timeTypeData = [];
    for (let i = 0; i < timeType.length; i++) {
      timeTypeData.push(0);
    }
    if (xAxisType === "months") {
      data.data.forEach((valueData) => {
        const findMonthIndex = timeType.indexOf(
          t(monthList[valueData?.month - 1])
        );
        timeTypeData[findMonthIndex] += valueData.total;
        if (data.name === "Sales") {
          cumulativeSalesData[findMonthIndex] += valueData.price;
        } else {
          cumulativeExpensesData[findMonthIndex] += valueData.price;
        }
      });
    } else if (xAxisType === "years") {
      data.data.forEach((valueData) => {
        const findYearIndex = timeType.indexOf(valueData.year);
        timeTypeData[findYearIndex] += valueData.total;
        if (data.name === "Sales") {
          cumulativeSalesData[findYearIndex] += valueData.price;
        } else {
          cumulativeExpensesData[findYearIndex] += valueData.price;
        }
      });
    }
  });
  for (let i = 1; i < timeType.length; i++) {
    cumulativeSalesData[i] += cumulativeSalesData[i - 1];
    cumulativeExpensesData[i] += cumulativeExpensesData[i - 1];
  }
  for (let i = 0; i < timeType.length; i++) {
    cumulativeProfitOrLoss[i] =
      cumulativeSalesData[i] - cumulativeExpensesData[i];
  }
  lengend.push(t("Profit/Loss"));
  series.push({
    name: t("Profit/Loss"),
    yAxisIndex: 0,
    type: "line",
    data: cumulativeProfitOrLoss,
  });
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    grid: {
      right: "12%",
      left: "12%",
    },
    toolbox: {
      feature: {
        dataZoom: { show: true },
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
        magicType: {
          type: ["line", "bar"],
        },
      },
      bottom: 0,
    },
    legend: {
      data: lengend,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        data: timeType,
      },
    ],
    yAxis: yAxis,
    series: series,
  };
}

function financialDataOptions(
  financialData,
  timeType,
  t,
  lengend,
  yAxis,
  series,
  xAxisType,
  type
) {
  if (type === "profitOrLoss") {
    return computeProfitOrLoss(
      financialData,
      timeType,
      t,
      lengend,
      yAxis,
      series,
      xAxisType
    );
  }
  let cumulativeData = [];
  for (let i = 0; i < timeType.length; i++) {
    cumulativeData.push(0);
  }
  financialData.map((data, index) => {
    lengend.push(t(data.name));
    let timeTypeData = [];
    for (let i = 0; i < timeType.length; i++) {
      timeTypeData.push(0);
    }
    if (xAxisType === "months") {
      data.data.forEach((data) => {
        const findMonthIndex = timeType.indexOf(t(monthList[data?.month - 1]));
        timeTypeData[findMonthIndex] += data.price;
        cumulativeData[findMonthIndex] += data.price;
      });
    } else if (xAxisType === "years") {
      data.data.forEach((data) => {
        const findYearIndex = timeType.indexOf(data.year);
        timeTypeData[findYearIndex] += data.price;
        cumulativeData[findYearIndex] += data.price;
      });
    }

    series.push({
      name: t(data.name),
      yAxisIndex: 0,
      type: "line",
      data: timeTypeData,
    });
  });
  for (let i = 1; i < timeType.length; i++) {
    cumulativeData[i] += cumulativeData[i - 1];
  }
  lengend.push(t("Cumulative"));
  series.push({
    name: t("Cumulative"),
    yAxisIndex: 0,
    type: "line",
    data: cumulativeData,
  });
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    grid: {
      right: "12%",
      left: "12%",
    },
    toolbox: {
      feature: {
        dataZoom: { show: true },
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
        magicType: {
          type: ["line", "bar"],
        },
      },
      bottom: 0,
    },
    legend: {
      data: lengend,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        data: timeType,
      },
    ],
    yAxis: yAxis,
    series: series,
  };
}

export function computeFinancialMetricsData(
  financialData,
  financialMonths,
  t,
  currencyFormat,
  type = ""
) {
  if (financialData.length !== 0) {
    // console.log("Financial Data: " + financialData);
    let months = [];
    let years = [];
    const lengend = [];
    let yAxis = [];
    yAxis.push({
      type: "value",
      name: currencyFormat,
      position: "left",
      offset: 0,
      alignTicks: true,
      axisLine: {
        show: true,
        fontSize: 4,
      },
      axisLabel: {
        formatter: `{value}`,
      },
    });

    const series = [];

    let differenceMonth = financialMonths - 12;

    if (differenceMonth > 1) {
      var currentYear = new Date().getFullYear();
      for (let i = 0; i < differenceMonth; i++) {
        years.unshift(currentYear - i);
      }
      return financialDataOptions(
        financialData,
        years,
        t,
        lengend,
        yAxis,
        series,
        "years",
        type
      );
    } else if (differenceMonth === 1) {
      const currentDate = new Date(); // Get the current date
      const lastYearDate = new Date(currentDate.getFullYear() - 1, 11, 1); // Subtract 1 year and set to December 1st

      console.log(lastYearDate.toDateString()); // Outputs the date in a human-readable format

      for (let i = 0; i <= 11; i++) {
        months.unshift(t(monthList[lastYearDate.getMonth()]));
        lastYearDate.setMonth(lastYearDate.getMonth() - 1);
      }
      return financialDataOptions(
        financialData,
        months,
        t,
        lengend,
        yAxis,
        series,
        "months",
        type
      );
    } else {
      var d = new Date();
      d.setDate(1);
      if (financialMonths == -1) {
        d.setMonth(d.getMonth() - 1); // Subtract one month from d
        months.unshift(t(monthList[d.getMonth()]));
      } else {
        for (let i = 0; i <= financialMonths; i++) {
          months.unshift(t(monthList[d.getMonth()]));
          d.setMonth(d.getMonth() - 1);
        }
      }
      return financialDataOptions(
        financialData,
        months,
        t,
        lengend,
        yAxis,
        series,
        "months",
        type
      );
    }
  } else {
    noDataGraph(t);
  }
}

/*********** Compute Activity Function ************/
function ActivityDataOptions(
  activty,
  unitType,
  isDocumentMode = false,
  timeType,
  language,
  t,
  items,
  displayTop,
  xAxisType
) {
  activty.data.map((data) => {
    let itemName = data?.item[language];
    if (itemName === null || itemName === "")
      itemName = isDocumentMode ? t("Cases") : t("Null");
    if (!items[itemName]) {
      items[itemName] = {
        total: timeType.map((time) => {
          return "";
        }),
        totalAmount: 0,
      };
    }

    if (xAxisType === "months") {
      items[itemName]["total"][timeType.indexOf(t(monthList[data.month - 1]))] =
        data.total;
      items[itemName]["totalAmount"] += data.total;
    } else if (xAxisType === "years") {
      items[itemName]["total"][timeType.indexOf(data.year)] = data.total;
      items[itemName]["totalAmount"] += data.total;
    }
  });
  if (Object.values(items).length > displayTop) {
    items = Object.entries(items).sort(([, a], [, b]) => {
      return b["totalAmount"] - a["totalAmount"];
    });
    let otherItems = [
      t("Others"),
      {
        total: timeType.map((time) => {
          return "";
        }),
        totalAmount: 0,
      },
    ];
    for (let i = displayTop - 1; i < items.length; i++) {
      items[i][1]["total"].map((x, index) => {
        otherItems[1]["total"][index] === ""
          ? (otherItems[1]["total"][index] = x)
          : (otherItems[1]["total"][index] =
              parseInt(otherItems[1]["total"][index]) + x);
      });
      otherItems[1]["totalAmount"] += items[i][1]["totalAmount"];
    }
    items = items.slice(0, displayTop);
    items.push(otherItems);
    items = items.reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
  }

  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    title: {
      show: false,
    },

    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "12%",
      containLabel: true,
    },

    toolbox: {
      feature: {
        dataZoom: { show: true },
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
        magicType: {
          type: ["line", "bar"],
        },
      },
      bottom: 0,
    },

    yAxis: {
      type: "value",
      axisLabel: {
        formatter: `{value} ${t(unitType)}`,
      },
    },
    xAxis: {
      type: "category",
      data: timeType,
    },
    series: Object.entries(items).map((key, values, index) => {
      return {
        name: key[0],
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: key[1].total,
      };
    }),
  };
}

export function computeActivityData(
  activty,
  unitType,
  isDocumentMode = false,
  activitiesMonths,
  language,
  t
) {
  if (activty) {
    let months = [];
    let years = [];
    let items = {};

    //Display Top number of plant/fertilizer on Activity
    const displayTop = 5;

    let differenceMonth = activitiesMonths - 12;

    if (differenceMonth > 1) {
      var currentYear = new Date().getFullYear();
      for (let i = 0; i < differenceMonth; i++) {
        years.unshift(currentYear - i);
      }
      return ActivityDataOptions(
        activty,
        unitType,
        isDocumentMode,
        years,
        language,
        t,
        items,
        displayTop,
        "years"
      );
    } else if (differenceMonth === 1) {
      const currentDate = new Date(); // Get the current date
      const lastYearDate = new Date(currentDate.getFullYear() - 1, 11, 1); // Subtract 1 year and set to December 1st

      console.log(lastYearDate.toDateString()); // Outputs the date in a human-readable format

      for (let i = 0; i <= 11; i++) {
        months.unshift(t(monthList[lastYearDate.getMonth()]));
        lastYearDate.setMonth(lastYearDate.getMonth() - 1);
      }
      return ActivityDataOptions(
        activty,
        unitType,
        isDocumentMode,
        months,
        language,
        t,
        items,
        displayTop,
        "months"
      );
    } else {
      var d = new Date();
      d.setDate(1);
      if (activitiesMonths == -1) {
        d.setMonth(d.getMonth() - 1); // Subtract one month from d
        months.unshift(t(monthList[d.getMonth()]));
      } else {
        for (let i = 0; i <= activitiesMonths; i++) {
          months.unshift(t(monthList[d.getMonth()]));
          d.setMonth(d.getMonth() - 1);
        }
      }
      return ActivityDataOptions(
        activty,
        unitType,
        isDocumentMode,
        months,
        language,
        t,
        items,
        displayTop,
        "months"
      );
    }
  }
}

/*********** Compute Monthly Sales Function ************/
export function computeMonthlySalesData(datas, language) {
  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      // orient: "vertical",
      // left: "left",
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        data: datas.map((data) => {
          return { value: data.total, name: data.item[language] };
        }),

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
}

/*********** Compute Summary Function ************/
function summaryDataOptions(
  summaryData,
  timeType,
  t,
  lengend,
  yAxis,
  series,
  xAxisType
) {
  let offsetValue = 0;
  summaryData.map((data, index) => {
    lengend.push(t(data.name));
    if (index < 4) {
      offsetValue = index % 2 ? 0 : 50;
    } else {
      offsetValue = 100;
    }
    yAxis.push({
      type: "value",
      name: `${t(data.name)} \n(${t(data.unit)})`,
      position: index < 2 ? "left" : "right",
      offset: offsetValue,
      alignTicks: true,
      axisLine: {
        show: true,
        fontSize: 4,
      },
      axisLabel: {
        formatter: `{value}`,
      },
    });
    let timeTypeData = [];
    for (let i = 0; i < timeType.length; i++) {
      timeTypeData.push(0);
    }
    if (xAxisType === "months") {
      data.data.forEach((data) => {
        const findMonthIndex = timeType.indexOf(t(monthList[data?.month - 1]));
        timeTypeData[findMonthIndex] = data.total;
      });
    } else if (xAxisType === "years") {
      data.data.forEach((data) => {
        const findYearIndex = timeType.indexOf(data.year);
        timeTypeData[findYearIndex] += data.total;
      });
    }
    series.push({
      name: t(data.name),
      yAxisIndex: index,
      type: index > 1 ? "bar" : "line",
      data: timeTypeData,
    });
  });
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    grid: {
      right: "12%",
      left: "12%",
    },
    toolbox: {
      feature: {
        dataZoom: { show: true },
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
        magicType: {
          type: ["line", "bar"],
        },
      },
      bottom: 0,
    },
    legend: {
      data: lengend,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        data: timeType,
      },
    ],
    yAxis: yAxis,
    series: series,
  };
}

export function computeSummaryData(summaryData, summaryMonths, t) {
  if (summaryData.length !== 0) {
    let months = [];
    let years = [];
    const lengend = [];
    const yAxis = [];

    const series = [];
    // var d = new Date();
    // let remainMonths = d.getMonth();
    // for (let i = 0; i <= summaryMonths; i++) {
    // 	months.unshift(t(monthList.slice(remainMonths - i)[0]));
    // }

    let differenceMonth = summaryMonths - 12;

    if (differenceMonth > 1) {
      var currentYear = new Date().getFullYear();
      for (let i = 0; i < differenceMonth; i++) {
        years.unshift(currentYear - i);
      }
      return summaryDataOptions(
        summaryData,
        years,
        t,
        lengend,
        yAxis,
        series,
        "years"
      );
    } else if (differenceMonth === 1) {
      const currentDate = new Date(); // Get the current date
      const lastYearDate = new Date(currentDate.getFullYear() - 1, 11, 1); // Subtract 1 year and set to December 1st

      console.log(lastYearDate.toDateString()); // Outputs the date in a human-readable format

      for (let i = 0; i <= 11; i++) {
        months.unshift(t(monthList[lastYearDate.getMonth()]));
        lastYearDate.setMonth(lastYearDate.getMonth() - 1);
      }
      return summaryDataOptions(
        summaryData,
        months,
        t,
        lengend,
        yAxis,
        series,
        "months"
      );
    } else {
      var d = new Date();
      d.setDate(1);
      if (summaryMonths == -1) {
        d.setMonth(d.getMonth() - 1); // Subtract one month from d
        months.unshift(t(monthList[d.getMonth()]));
      } else {
        for (let i = 0; i <= summaryMonths; i++) {
          months.unshift(t(monthList[d.getMonth()]));
          d.setMonth(d.getMonth() - 1);
        }
      }
      return summaryDataOptions(
        summaryData,
        months,
        t,
        lengend,
        yAxis,
        series,
        "months"
      );
    }
  } else {
    noDataGraph(t);
  }
}

export function computeInventoryData(inventoryData, field, t, type) {
  let filteredData = inventoryData[field.options];
  if (type == "solid") {
    filteredData = inventoryData[field.options].filter(
      (data) => data.unit == "kg" || data.unit == "g"
    );
  } else if (type == "liquid") {
    filteredData = inventoryData[field.options].filter(
      (data) => data.unit == "ℓ" || data.unit == "mℓ"
    );
  }

  console.log("filterData length: " + filteredData.length);

  if (inventoryData !== undefined && filteredData.length !== 0) {
    console.log("Enter Inventory Data Chart");
    let lengend = [];
    let series = [];
    let invnetoryDataArray = [];

    filteredData.forEach((data) => {
      if (data.quantity !== undefined) {
        invnetoryDataArray.push({
          name: t(data.name.eng),
          value: data.quantity,
          unit: data.unit,
        });

        lengend.push(t(data.name.eng));
        series.push({
          name: field.label,
          type: "pie",
          radius: "80%",
          center: ["70%", "50%"],
          data: invnetoryDataArray,
          label: {
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        });
      }
    });

    return {
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `${params.seriesName} <br/>${params.name}: ${params.value} ${params.data.unit} (${params.percent}%)`;
        },
      },
      toolbox: {
        feature: {
          saveAsImage: { show: true },
        },
        bottom: 0,
      },
      legend: {
        orient: "vertical",
        left: 0,
        data: lengend,
      },
      series: series,
    };
  } else {
    console.log("No Data");
    return noDataGraph(t);
  }
}
