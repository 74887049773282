import { Card, CardContent, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import GenericForm from "./generic-form";

export default function FormDialog(props) {
  const {
    config,
    dialogStatus: { open, mode, formId, formData, formOptions, onSubmit },
    setDialogStatus,
    setRefreshData,
  } = props;

  const handleClose = () => {
    setDialogStatus((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      scroll="body"
      onClose={handleClose}
    >
      <Card
        elevation={0}
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <IconButton
          sx={{ alignSelf: "flex-start" }}
          aria-label="cancel-icon"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <CardContent sx={{ margin: 3, marginTop: 0 }}>
          <GenericForm
            mode={mode}
            config={config}
            formId={formId}
            formData={formData}
            formOptions={formOptions}
            onSubmit={onSubmit}
            setRefreshData={setRefreshData}
            handleClose={handleClose}
          />
        </CardContent>
      </Card>
    </Dialog>
  );
}

FormDialog.propTypes = {
  config: PropTypes.object.isRequired,
  dialogStatus: PropTypes.shape({
    open: PropTypes.bool,
    mode: PropTypes.string,
    formId: PropTypes.string,
    formData: PropTypes.object,
    formOptions: PropTypes.object,
    onSubmit: PropTypes.func,
  }).isRequired,
  setDialogStatus: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func,
};
