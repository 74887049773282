import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export default function Locales({ children }) {
  const {
    i18n: { language },
  } = useTranslation();

  const theme = useTheme();

  const themeWithLocale = useMemo(() => {
    let locale;
    switch (language) {
      case "eng":
        locale = "enUS";
        break;
      case "cht":
        locale = "zhTW";
        break;
      case "chs":
        locale = "zhCN";
        break;
      default:
        locale = "enUS";
    }
    return createTheme(theme, locales[locale]);
  }, [language, theme]);

  return <ThemeProvider theme={themeWithLocale}>{children}</ThemeProvider>;
}
