import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import construction from "../../images/construction.gif";
import { setTitle } from "../../redux/slices/title-slice";

export const Construction = ({ title }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!dispatch) return;
    dispatch(setTitle(title));
  }, [title, dispatch]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="90vh"
    >
      <img
        alt="The page is under construction"
        src={construction}
        style={{
          maxWidth: "90vw",
          maxHeight: "90vh",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};
