import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const OfflineMsg = () => {
  const { t } = useTranslation();
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOffline(true);
    });

    window.addEventListener("online", () => {
      setOffline(false);
    });
  }, []);

  return (
    <div
      style={{
        display: offline ? "block" : "none",
        backgroundColor: "black",
        textAlign: "center",
        position: "sticky",
        top: 0,
        zIndex: 1200,
      }}
    >
      <span style={{ color: "white", size: "0.5rem" }}>
        {t("Offline mode")}
      </span>
    </div>
  );
};
