import React, { useState } from "react";
import {
  Paper,
  FormControl,
  Chip,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  arrayInput: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    minHeight: 40,
  },
  none: {
    marginTop: 10,
    marginLeft: 10,
  },
}));

/**
 * Prop list
 * @param label - select field title
 * @param values - form values
 * @param setValues - function to set values of the array
 * @param options - options to select tags
 */
export const TagInputField = ({
  label,
  values,
  setValues,
  options,
  variant = "filled",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const addValue = () => {
    if (!value || !!values.find((v) => v === value)) {
      return;
    }
    const newValues = [...values, value];
    setValue("");
    setValues(newValues);
  };

  const removeValue = (value) => {
    const newValues = values.filter((v) => v !== value);
    setValues(newValues);
  };

  const getOptions = () => {
    return options.filter((x) => !values.includes(x.id));
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Box display="flex" mb={1} alignItems="center">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={getOptions()}
          fullWidth
          isOptionEqualToValue={() => true}
          selectOnFocus
          handleHomeEndKeys
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              variant={variant}
              label={t(label)}
              InputProps={{
                ...params?.InputProps,
                sx: {
                  backgroundColor: "white",
                  borderRadius: 5,
                  boxShadow:
                    variant === "filled"
                      ? "inset 0px 2px 4px rgba(0, 0, 0, 0.25)"
                      : "",
                  "&.Mui-focused": {
                    backgroundColor: "white",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.7)",
                  },
                },
              }}
            />
          )}
          value={options.find((o) => o.id === value)?.label ?? ""}
          onChange={(_, newValue) => {
            setValue(newValue.id);
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            addValue();
          }}
        >{`${t("Add")} ${t(label)}`}</Button>
      </Box>

      <Paper component="ul" className={classes.arrayInput}>
        {!!values && values.length === 0 ? (
          <li key="none" className={classes.none}>
            {t("None")}
          </li>
        ) : null}
        {!!values &&
          values.map((value) => {
            return (
              <li key={value}>
                <Chip
                  label={options.find((o) => o.id === value)?.label}
                  sx={{ margin: 1 }}
                  onDelete={() => removeValue(value)}
                />
              </li>
            );
          })}
      </Paper>
    </FormControl>
  );
};
