import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useTranslation } from "react-i18next";

const filter = createFilterOptions();

export const CustomAutocompleteField = ({
  label,
  options,
  excludeOptions,
  id,
  value,
  setValue,
  error,
  helperText,
  sx,
  textFieldProps,
}) => {
  const [localValue, setLocalValue] = useState(
    !!value
      ? {
          id: JSON.stringify(value),
          label: JSON.stringify(value),
        }
      : null
  );
  const {
    i18n: { language },
  } = useTranslation();

  // when the local value changes, populate the actual form value
  useEffect(() => {
    if (!localValue) {
      setValue({ eng: "", chs: "", cht: "" });
      return;
    }
    if (!!localValue && typeof localValue !== "string" && !!localValue.id)
      setValue(JSON.parse(localValue.id));
  }, [localValue]);

  // when value changes (like due to manually triggered clear), set local value
  useEffect(() => {
    if (!!localValue && JSON.stringify(value) === localValue.id) return;
    setLocalValue({
      id: JSON.stringify(value),
      label: JSON.stringify(value),
    });
  }, [value]);

  return (
    <Autocomplete
      value={localValue}
      onChange={(_, newValue) => {
        setLocalValue(newValue);
      }}
      id={id}
      filterOptions={(options, params) => {
        if (!excludeOptions) return filter(options, params);
        const filtered = filter(
          options.filter((x) => !excludeOptions.includes(x.id)),
          params
        );
        return filtered;
      }}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (!!option.id) {
          const tmp = JSON.parse(option.id);
          return tmp[language];
        }
        return option.label;
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={helperText}
          required
          {...params}
          label={label}
          InputProps={{ ...params?.InputProps, sx }}
          {...textFieldProps}
        />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      // freeSolo
      isOptionEqualToValue={(option, value) => {
        if (option.id != value.id) {
          return false;
        }
        return true;
      }}
    />
  );
};
