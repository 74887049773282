import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { mainMenuSettingsListItem } from "./main-manu-settings-listitem";
import { mainMenuListItem } from "./main-menu-listitems";
import { NotificationSettings } from "../notification/notification-settings";
import { logoutUser } from "../../api/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Footer } from "../footer/footer";
import { LangSettings } from "../lang-settings/lang-settings";
import { useTranslation } from "react-i18next";
import { store } from "../../redux/store";
import { setSelectedFarmId } from "../../redux/slices/farm-slice";
import { useState } from "react";
import { AppBar, Box, Button, ListItemButton, Toolbar } from "@mui/material";
import { OfflineMsg } from "../pwa/offline-msg";
import { getFallbackDisplayValue } from "../../utils/utils";

const drawerWidth = 350;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 30,
  background:
    "linear-gradient(68.15deg, rgba(207, 64, 31, 0.9) 30.86%, rgba(207, 64, 31, 0) 131.65%)",
  position: "relative",
}));

export const MainMenu = ({ showMenu, component }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, farms } = useSelector((state) => state.auth);
  const { title } = useSelector((state) => state.title);
  const userRole = useSelector((state) => state.auth.role);
  const farmSelectedId = useSelector((state) => state.selectedFarm.farmId);
  const [open, setOpen] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (location.pathname.includes("resetpassword") || !showMenu) {
    return component;
  }

  const SidebarListItem = ({ listItem }) => {
    const { id, title, pathname, icon, role, enabled, color } = listItem;
    if (role.includes(userRole) && enabled === "true") {
      return (
        <ListItem
          disablePadding
          key={id}
          sx={{
            ...(location.pathname.includes(pathname) &&
              pathname !== "/" && {
                backgroundColor: "rgba(207, 64, 31, 0.2)",
                borderRadius: 3,
              }),
          }}
        >
          <ListItemButton
            component={Link}
            to={pathname}
            onClick={(e) => {
              handleDrawerClose();
              if (title === "Log out") {
                e.preventDefault();
                logoutUser();
              }
            }}
          >
            <ListItemIcon style={{ color: color }}>{icon}</ListItemIcon>
            <ListItemText
              style={{ color: color }}
              primary={<Typography> {t(title)}</Typography>}
            />
          </ListItemButton>
        </ListItem>
      );
    }
    return null;
  };

  return (
    <>
      <AppBar position="sticky">
        <OfflineMsg />
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            size="large"
            edge="start"
            sx={{ mr: 2, color: "white" }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {t(title)}
          </Typography>
          {userRole !== "admin" && <NotificationSettings />}
          <LangSettings />
        </Toolbar>
      </AppBar>
      <Drawer onClose={handleDrawerClose} anchor="left" open={open}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "hidden",
            width: drawerWidth,
          }}
        >
          <DrawerHeader>
            <IconButton
              onClick={handleDrawerClose}
              sx={{ position: "absolute", top: 5, right: 5 }}
            >
              <ChevronLeft sx={{ height: 40, width: 40 }} />
            </IconButton>
            <Avatar
              alt="Logo"
              src="/logo192.png"
              sx={{
                width: 90,
                height: 90,
                mb: 1,
                bgcolor: "white",
              }}
              imgProps={{
                style: {
                  transform: "translate(-3px,3px)",
                },
              }}
            />
            <Typography
              variant="h6"
              noWrap
              color="white"
              component="div"
              sx={{ textAlign: "left", fontWeight: "bold", fontSize: 30 }}
              mx={{ fontSize: 20 }}
            >
              {getFallbackDisplayValue(name)}
            </Typography>
            <Grid container alignItems={"center"} justifyContent={"start"}>
              <Grid>
                {farms.length > 1 ? (
                  <TextField
                    select
                    value={farmSelectedId}
                    onChange={(evt) => {
                      store.dispatch(setSelectedFarmId(evt.target.value));
                    }}
                    variant="standard"
                    InputProps={{
                      style: { color: "white" },
                    }}
                  >
                    {farms.map((farm) => (
                      <MenuItem key={farm.id} value={farm.id}>
                        {getFallbackDisplayValue(farm.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Typography
                    variant="subtitle"
                    noWrap
                    component="div"
                    sx={{ color: "white", textAlign: "center", fontSize: 18 }}
                  >
                    {getFallbackDisplayValue(farms[0]?.name)}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {userRole !== "farmer" && farms.length > 0 && (
              <Grid>
                <Button
                  size="small"
                  color="secondary"
                  sx={{ mt: 1 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDrawerClose();
                    navigate(`/farms/${farmSelectedId}`);
                  }}
                >
                  {t("Go To Farm")}
                </Button>
              </Grid>
            )}
          </DrawerHeader>
          <List sx={{ py: 3, px: 2, overflowY: "auto" }}>
            {mainMenuListItem.map((listItem, index) => (
              <SidebarListItem key={index} listItem={listItem} />
            ))}
            <Typography
              color="#CF401F"
              sx={{ mt: 3.5, mb: 1.5, ml: 2, fontWeight: "bold" }}
            >
              {t("Settings")}
            </Typography>
            {mainMenuSettingsListItem.map((listItem, index) => (
              <SidebarListItem key={index} listItem={listItem} />
            ))}
          </List>
        </Box>
      </Drawer>
      <main>
        {component}
        <Footer showMenu={showMenu} />
      </main>
    </>
  );
};
