import React from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomNameField } from "../common/custom-name-field";
import { CustomTagsField } from "../common/custom-tags-field";

export const FarmGeneralForm = ({
  formState,
  setFormState,
  handleSubmit,
  shouldAutoSubmit,
}) => {
  const { t } = useTranslation();

  const updateField = (name, value) => {
    // Stop the auto submit function because we are using the submit button
    // in this form
    shouldAutoSubmit.current = false;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Grid container spacing={3} style={{ padding: 10 }}>
      <Grid item xs={12}>
        <CustomNameField
          name={"name"}
          label={{
            eng: "Name",
            chs: "名称",
            cht: "名稱",
          }}
          values={formState.name}
          setValues={(v) => updateField("name", v)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomNameField
          name={"address"}
          label={{
            eng: "Address",
            chs: "地址",
            cht: "地址",
          }}
          values={formState.address}
          setValues={(v) => updateField("address", v)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTagsField
          label={t("Tags")}
          values={formState.tags}
          setValues={(v) => updateField("tags", v)}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTagsField
          label={t("Active Tags")}
          values={formState.visibleTags}
          setValues={(v) => updateField("visibleTags", v)}
          options={formState.tags.map((t) => ({ id: t, label: t }))}
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleSubmit}>{t("Save")}</Button>
      </Grid>
    </Grid>
  );
};
