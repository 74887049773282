import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { create, queryList, query } from "../../api/common";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomSelectField } from "../common/custom-select-field";
import { CustomDateInput } from "../common/custom-date-input";
import { showErrorSnackbar } from "../../redux/slices/snackbar-slice";

const TaskFooter = ({ setReload }) => {
  const [title, setTitle] = useState("");
  const [farmers, setFarmers] = useState([]);
  const [assigneeId, setAssigneeId] = useState("");
  const [date, setDate] = useState(new Date());
  const creatorId = useSelector((state) => state.auth.id);
  const creatorName = useSelector((state) => state.auth.name);
  const farmSelectedId = useSelector((state) => state.selectedFarm.farmId);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  // onload and on language change, fetch farmers
  useEffect(() => {
    if (!farmSelectedId) return;
    queryList("users", `filter[farms]=${farmSelectedId}&sort=name`).then(
      ({ data }) => {
        setFarmers(data.map((d) => ({ id: d.id, name: d.name?.[language] })));
      }
    );
  }, [farmSelectedId, language]);

  const storeData = async () => {
    if (!title || !assigneeId || !date) {
      dispatch(showErrorSnackbar(t("Invalid task")));
      return;
    }
    // create a new task
    create("tasks", {
      title,
      farmId: farmSelectedId,
      date,
      completed: false,
      creatorId,
      assigneeId,
    }).then(() => {
      // reload task list
      setReload((prev) => prev + 1);
      setTitle("");
    });
    const { data } = await query(`farms/name/${farmSelectedId}`);
    create("notifications", {
      userId: creatorId,
      userName: creatorName,
      farmId: farmSelectedId,
      farmName: data.name,
      assigneeId,
      assigneeName: farmers.find((f) => f.id === assigneeId).name,
      content: title,
      date,
      createdAt: new Date(new Date().toISOString()),
      readUsers: [],
      category: "task",
    }).then(() => {
      // reload task list
      setReload((prev) => prev + 1);
      setTitle("");
    });
  };

  return (
    <Box
      sx={{
        background: "#B0D776",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        boxShadow: 3,
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
        }}
      >
        <AddIcon
          onClick={() => storeData()}
          sx={{ cursor: "pointer", mr: 2 }}
        />
        <TextField
          id="title"
          label={t("New Task")}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          fullWidth
          value={title}
          variant="filled"
          InputProps={{
            sx: {
              backgroundColor: "white",
              borderRadius: 5,
              boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.25)",
              "&.Mui-focused": {
                backgroundColor: "white",
              },
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.7)",
              },
            },
            disableUnderline: true,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          gap: 2,
        }}
      >
        <CustomSelectField
          value={assigneeId}
          setValue={(v) => {
            setAssigneeId(v.target.value);
          }}
          choices={farmers}
          label={t("Assignee")}
          isForm
          id="assigneeId"
          sx={{
            backgroundColor: "white",
            borderRadius: 5,
            boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.25)",
            "&.Mui-focused": {
              backgroundColor: "white",
            },
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.7)",
            },
          }}
          variant="filled"
          disableUnderline
        />
        <CustomDateInput
          date={date}
          setDate={(v) => setDate(v)}
          label={t("Date")}
          sx={{
            backgroundColor: "white",
            borderRadius: 5,
            boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.25)",
            "&.Mui-focused": {
              backgroundColor: "white",
            },
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.7)",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TaskFooter;
