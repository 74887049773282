import React, { useEffect, useState } from "react";
import {
  Paper,
  FormControl,
  Chip,
  Autocomplete,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  arrayInput: {
    display: "flex",
    justifyContent: "start",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    minHeight: 40,
  },
  none: {
    marginTop: 10,
    marginLeft: 10,
  },
}));

const filter = createFilterOptions();

export const CustomTagsField = ({
  label,
  values,
  setValues,
  options = [],
  variant = "filled",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  const addValue = (val) => {
    if (!val || !!values.find((v) => v === val.id)) {
      return;
    }
    const newValues = [...values, val.id];
    setValue(null);
    setValues(newValues);
  };

  useEffect(() => {
    addValue(value);
    // eslint-disable-next-line
  }, [value]);

  const removeValue = (value) => {
    const newValues = values.filter((v) => v !== value);
    setValues(newValues);
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Autocomplete
        sx={{ mb: 1 }}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              id: newValue,
              label: newValue,
            });
          } else if (newValue && newValue.id) {
            setValue({
              id: newValue.id,
              label: newValue.id,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(
            options.filter((x) => !values.includes(x.id)),
            params
          );
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = filtered.some(
            (option) => inputValue === option.id
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              id: inputValue,
              label: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (!!option.id) {
            return option.id;
          }
          return option.label;
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            label={t(label)}
            InputProps={{
              ...params?.InputProps,
              sx: {
                backgroundColor: "white",
                borderRadius: 5,
                boxShadow:
                  variant === "filled"
                    ? "inset 0px 2px 4px rgba(0, 0, 0, 0.25)"
                    : "",
                "&.Mui-focused": {
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.7)",
                },
              },
            }}
          />
        )}
      />

      <Paper component="ul" className={classes.arrayInput}>
        {!!values && values.length === 0 ? (
          <li key="none" className={classes.none}>
            {t("None")}
          </li>
        ) : null}
        {!!values &&
          values.map((value) => {
            return (
              <li key={value}>
                <CustomChip data={value} onDelete={() => removeValue(value)} />
              </li>
            );
          })}
      </Paper>
    </FormControl>
  );
};

const CustomChip = ({ data, ...props }) => {
  return <Chip label={data} sx={{ margin: 1 }} {...props} />;
};
