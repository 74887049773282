import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Box, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { queryList, update } from "../../api/common";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationMenuItem from "./notification-menuitem";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { activityTypes } from "../../constants/activity-types";

export const NotificationSettings = ({ openNoti }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [newNotiCount, setNewNotiCount] = useState(0);
  const farmSelectedId = useSelector((state) => state.selectedFarm.farmId);
  const userId = useSelector((state) => state.auth.id);
  const isFarmer = useSelector((state) => state.auth.role) === "farmer";

  const icons = [
    {
      category: "task",
      icon: "clipboard-list-outline",
      color: "purple",
    },
    {
      category: "completed",
      icon: "sticker-check-outline",
      color: "green",
    },
  ];

  useEffect(() => {
    if (!farmSelectedId) return;
    queryList(
      "notifications",
      isFarmer
        ? `filter[farmId]=${farmSelectedId}&filter[assigneeId]=${userId}&filter[markedId][$ne]=${userId}&sort=-createdAt`
        : `filter[farmId]=${farmSelectedId}&filter[markedId][$ne]=${userId}&filter[$or][0][userId][$ne]=${userId}&filter[$or][1][category][$ne]=task&sort=-createdAt`
    ).then(({ data }) => {
      setNotifications(data);
    });
  }, [farmSelectedId, userId]);

  useEffect(() => {
    const notiLength = notifications.filter((n) => {
      return !n.readUsers.includes(userId);
    }).length;
    console.log("noti count: ", notiLength);
    setNewNotiCount(notiLength);
  }, [notifications, anchorEl]);

  console.log("Noti: ", notifications);

  return (
    <>
      <Badge
        badgeContent={newNotiCount}
        color="error"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            borderRadius: 30,
            cursor: "pointer",
          },
          marginX: 1,
          fontWeight: "bold",
          width: "fit-content",
        }}
      >
        <NotificationsIcon sx={{ fontSize: 30 }} />
      </Badge>

      {/* Notification header */}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          notifications.map((noti) => {
            const existed = noti.readUsers.includes(userId);
            noti.readUsers.push(userId);
            if (!existed) {
              update("notifications", noti);
            }
          });
        }}
        autoFocus={false}
      >
        <MenuItem
          disableRipple
          sx={{
            fontWeight: "bold",
            mb: "10px",
            "&:hover": {
              backgroundColor: "inherit",
              cursor: "default",
            },
          }}
        >
          {"Notifications"}
        </MenuItem>
        {notifications.map((noti) => (
          <NotificationMenuItem
            notification={noti}
            userId={userId}
            isFarmer={isFarmer}
          />
        ))}
      </Menu>
    </>
  );
};
