import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "eng",
  lng: localStorage.getItem("lang") || "eng",
  resources: {
    eng: {
      translations: require("./locales/eng/translations.json"),
      errorTranslations: require("./locales/eng/error-translations.json"),
    },
    chs: {
      translations: require("./locales/chs/translations.json"),
      errorTranslations: require("./locales/chs/error-translations.json"),
    },
    cht: {
      translations: require("./locales/cht/translations.json"),
      errorTranslations: require("./locales/cht/error-translations.json"),
    },
  },
  ns: ["translations", "errorTranslations"],
  defaultNS: "translations",
});

i18n.languages = ["eng", "chs", "cht"];

export default i18n;
