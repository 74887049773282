import axios from "axios";
import { logoutUser, refreshAuthToken } from "./auth";
import { hideLoading, showLoading } from "../redux/slices/loading-slice";
import { store } from "../redux/store";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const axiosInstanceNoAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

let refreshTokenPromise = null;

axiosInstance.interceptors.request.use(
  async (config) => {
    store.dispatch(showLoading());
    return config;
  },
  (err) => {
    store.dispatch(hideLoading());
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    store.dispatch(hideLoading());
    return res;
  },
  async (err) => {
    if (err.response && err.response.status === 401) {
      //try to refresh authentication token and resend the request
      try {
        // check for an existing in-progress refresh request
        // if nothing is in-progress, start a new refresh token request
        refreshTokenPromise = refreshTokenPromise || refreshAuthToken();
        await refreshTokenPromise;
        const req = await axiosInstance.request(err.config);
        return req;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          await logoutUser();
          return Promise.reject(
            new Error("Your session has expired. Please login again.")
          );
        }
        return Promise.reject(e);
      } finally {
        // clear state
        refreshTokenPromise = null;
        store.dispatch(hideLoading());
      }
    } else {
      store.dispatch(hideLoading());
      return Promise.reject(err);
    }
  }
);
