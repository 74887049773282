import React, { useEffect, useRef, useState } from "react";
import { setTitle } from "../../redux/slices/title-slice";
import { useDispatch } from "react-redux";
import { queryList } from "../../api/common";
import {
  buildQuery,
  defaultDeleteFunc,
  defaultExportFunc,
  getTableFieldsArr,
} from "../../utils/utils";
import { showErrorSnackbar } from "../../redux/slices/snackbar-slice";
import { FORM_MODE } from "../../constants/choices";
import { FungicidesConfig } from "../../constants/config";
import FormDialog from "../common/form-dialog";
import EnhancedTable from "../common/enhanced-table";
import { Box } from "@mui/system";

export const FungicideList = () => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [data, setData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const [config] = useState(FungicidesConfig);
  const [dialogStatus, setDialogStatus] = useState({
    open: false,
    mode: FORM_MODE.CREATE,
    formId: "",
  });

  useEffect(() => {
    dispatch(setTitle("Fungicides"));
    queryList(config.source, buildQuery(tableRef.current.getParams()))
      .then((resp) => {
        setData({ data: resp?.data ?? [], total: parseInt(resp?.total ?? 0) });
      })
      .catch((e) => {
        dispatch(showErrorSnackbar(e));
      });
  }, [dispatch, refreshData, config]);

  return (
    <Box sx={{ padding: 6 }}>
      <EnhancedTable
        ref={tableRef}
        data={data}
        fields={getTableFieldsArr(config)}
        setRefreshData={setRefreshData}
        createFunc={() =>
          setDialogStatus({
            open: true,
            mode: FORM_MODE.CREATE,
            formId: "",
          })
        }
        exportFunc={() =>
          defaultExportFunc(config.source, tableRef.current.getParams())
        }
        deleteFunc={() =>
          defaultDeleteFunc(
            config.source,
            tableRef.current.getSelectedData(),
            tableRef.current.getSetSelected(),
            setRefreshData
          )
        }
        handleRowClick={(e, rowId) =>
          setDialogStatus({
            open: true,
            mode: FORM_MODE.EDIT,
            formId: rowId,
          })
        }
      />
      <FormDialog
        config={config}
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
        setRefreshData={setRefreshData}
      />
    </Box>
  );
};
