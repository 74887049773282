import React from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import CustomTextField from "./custom-text-field";
import { FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";

export const CustomSelectField = ({
  label,
  choices,
  value,
  setValue,
  formStyle,
  isForm,
  sx,
  variant = "outlined",
  ...restProps
}) => {
  const { t } = useTranslation();
  if (!choices || choices.length === 0) {
    return (
      <CustomTextField
        label={label}
        value=""
        setValue={(v) => {
          setValue(v);
        }}
      />
    );
  }

  if (isForm) {
    return (
      <FormControl
        sx={{
          width: "100%",
          ...formStyle,
        }}
        variant={variant}
      >
        <InputLabel>{t(label)}</InputLabel>
        <Select
          label={t(label)}
          value={t(value)}
          onChange={(e) => setValue(e)}
          sx={sx}
          {...restProps}
        >
          {choices.map((choice) => (
            <MenuItem key={choice.id} value={t(choice.id)}>
              {t(choice.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl sx={formStyle} variant={variant}>
      <InputLabel>{t(label)}</InputLabel>
      <Select
        label={t(label)}
        value={t(value)}
        onChange={(e) => setValue(e)}
        sx={{
          backgroundColor: "white",
          borderRadius: 5,
          boxShadow: 5,
          color: "black",
          width: "100%",
          maxWidth: 400,
          "&:hover": {
            backgroundColor: "white",
          },
          "&:active": {
            backgroundColor: "white",
          },
          ...sx,
        }}
        {...restProps}
      >
        {choices.map((choice) => (
          <MenuItem key={choice.id} value={t(choice.id)}>
            {t(choice.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
