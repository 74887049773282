import {
  GiPlantSeed,
  GiFertilizerBag,
  GiDigHole,
  GiFruitBowl,
  GiMoneyStack,
  GiSelect,
  GiSeedling,
  GiSolidLeaf,
  GiMushroomGills,
} from "react-icons/gi";
import { MdPestControl, MdInventory } from "react-icons/md";
import { validateRange } from "../utils/field-utils";
import { query } from "../api/common";
import { convertToStandardUnit } from "./unit-conversion";
import { getInventoryUnits } from "../utils/utils";

export const activityTypes = [
  {
    id: "0",
    bgcolor: "orange",
    label: "Aerating",
    title: "AERATING",
    type: {
      eng: "Aerating",
      chs: "曝气",
      cht: "曝氣",
    },
    icon: GiDigHole,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => !!v,
        width: "34%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "33%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "33%",
      },
    ],
    standardUnit: "",
    units: [],
  },
  {
    id: "1",
    bgcolor: "darkseagreen",
    label: "Sowing",
    title: "SOWING",
    type: {
      eng: "Sowing",
      chs: "播种",
      cht: "播種",
    },
    icon: GiPlantSeed,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => !!v,
        width: "15%",
      },
      {
        id: "item",
        name: "Plant Name",
        type: "autocomplete",
        options: "plants",
        validate: (v) => !!v.eng || !!v.chs || !!v.cht,
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: (v) => v > 0,
        width: "10%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => !!v,
        units: [
          "g",
          "kg",
          "mg",
          "pack (1kg)",
          "pack (500g)",
          "pieces",
          "tsp (5mℓ)",
          "tsp (15mℓ)",
        ],
        width: "10%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "14%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "10%",
      },
      {
        id: "convertQuantity",
        name: "Average pieces per 1 kg",
        type: "row",
        validate: (v) => {
          if (parseInt(v) === 0) return true; //average pieces per 1 kg 0 is valid
          return parseInt(v) > 0;
        },
        width: "16%",
      },
    ],
    standardUnit: "pieces",
    units: [
      "g",
      "kg",
      "mg",
      "pack (1kg)",
      "pack (500g)",
      "pieces",
      "tsp (5mℓ)",
      "tsp (15mℓ)",
    ],
  },
  {
    id: "2",
    bgcolor: "gold",
    label: "Fertilizer",
    title: "FERTILIZER",
    type: {
      eng: "Fertilizer",
      chs: "施肥",
      cht: "施肥",
    },
    icon: GiFertilizerBag,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "item",
        name: "Fertilizer Name",
        type: "autocomplete",
        options: "fertilizers",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: async (v, d) => {
          const res = await query(`farms/name/${d.farmId}`);
          console.log("ResData: ", res.data.fertilizers);
          const selectedItem = res.data.fertilizers.find(
            (item) =>
              item.name.eng === d.item.eng &&
              item.name.chs === d.item.chs &&
              item.name.cht === d.item.cht
          );
          const { quantity: actQuantity, unit: actUnit } =
            convertToStandardUnit(Number(d.quantity), d.unit);
          return v > 0 && actQuantity <= selectedItem?.quantity;
        },
        width: "12%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: (form, allData) =>
          getInventoryUnits(form, allData, "fertilizers"),
        width: "12%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "12%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "12%",
      },
      {
        id: "price",
        name: "Price(RM)",
        type: "price",
        validate: (v) => {
          if (parseFloat(v) === 0) return true; //price 0 is valid
          return parseFloat(v) > 0;
        },
        width: "12%",
      },
    ],
    standardUnit: "kg",
    units: ["g", "kg", "mg", "pack (1kg)", "pack (500g)", "mℓ", "ℓ"],
  },
  {
    id: "3",
    bgcolor: "dimgrey",
    label: "Pesticide",
    title: "PESTICIDE",
    type: {
      eng: "Pesticide",
      chs: "农药",
      cht: "農藥",
    },
    icon: MdPestControl,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "item",
        name: "Pesticide Name",
        type: "autocomplete",
        options: "pesticides",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: async (v, d) => {
          const res = await query(`farms/name/${d.farmId}`);
          console.log("ResData: ", res.data.pesticides);
          const selectedItem = res.data.pesticides.find(
            (item) =>
              item.name.eng === d.item.eng &&
              item.name.chs === d.item.chs &&
              item.name.cht === d.item.cht
          );
          const { quantity: actQuantity, unit: actUnit } =
            convertToStandardUnit(Number(d.quantity), d.unit);
          return v > 0 && actQuantity <= selectedItem?.quantity;
        },
        width: "12%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: (form, allData) =>
          getInventoryUnits(form, allData, "pesticides"),
        width: "12%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "12%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "12%",
      },
      {
        id: "price",
        name: "Price(RM)",
        type: "price",
        validate: (v) => {
          if (parseFloat(v) === 0) return true; //price 0 is valid
          return parseFloat(v) > 0;
        },
        width: "12%",
      },
    ],
    standardUnit: "ℓ",
    units: ["g", "kg", "mg", "pack (1kg)", "pack (500g)", "mℓ", "ℓ"],
  },
  {
    id: "4",
    bgcolor: "violet",
    label: "Foliar",
    title: "FOLIAR",
    type: {
      eng: "Foliar",
      chs: "叶面肥",
      cht: "葉面肥",
    },
    icon: GiSolidLeaf,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "item",
        name: "Foliar Name",
        type: "autocomplete",
        options: "foliars",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: async (v, d) => {
          const res = await query(`farms/name/${d.farmId}`);
          console.log("ResData: ", res.data.foliars);
          const selectedItem = res.data.foliars.find(
            (item) =>
              item.name.eng === d.item.eng &&
              item.name.chs === d.item.chs &&
              item.name.cht === d.item.cht
          );
          const { quantity: actQuantity, unit: actUnit } =
            convertToStandardUnit(Number(d.quantity), d.unit);
          console.log("Total Quantity: ", selectedItem?.quantity);
          console.log("Total Unit: ", selectedItem?.unit);
          console.log("Used Quantity: ", actQuantity);
          console.log("Used Unit: ", actUnit);
          console.log(actQuantity <= selectedItem?.quantity);
          console.log(v > 0);
          return v > 0 && actQuantity <= selectedItem?.quantity;
        },
        width: "12%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: (form, allData) => getInventoryUnits(form, allData, "foliars"),
        width: "12%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "12%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "12%",
      },
      {
        id: "price",
        name: "Price(RM)",
        type: "price",
        validate: (v) => {
          if (parseFloat(v) === 0) return true; //price 0 is valid
          return parseFloat(v) > 0;
        },
        width: "12%",
      },
    ],
    standardUnit: "ℓ",
    units: ["g", "kg", "mg", "pack (1kg)", "pack (500g)", "mℓ", "ℓ"],
  },
  {
    id: "5",
    bgcolor: "purple",
    label: "Fungicide",
    title: "FUNGICIDE",
    type: {
      eng: "Fungicide",
      chs: "杀菌剂",
      cht: "殺菌劑",
    },
    icon: GiMushroomGills,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "item",
        name: "Fungicide Name",
        type: "autocomplete",
        options: "fungicides",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: async (v, d) => {
          const res = await query(`farms/name/${d.farmId}`);
          console.log("ResData: ", res.data.fungicides);
          const selectedItem = res.data.fungicides.find(
            (item) =>
              item.name.eng === d.item.eng &&
              item.name.chs === d.item.chs &&
              item.name.cht === d.item.cht
          );
          const { quantity: actQuantity, unit: actUnit } =
            convertToStandardUnit(Number(d.quantity), d.unit);
          return v > 0 && actQuantity <= selectedItem?.quantity;
        },
        width: "12%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: (form, allData) =>
          getInventoryUnits(form, allData, "fungicides"),
        width: "12%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "12%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "12%",
      },
      {
        id: "price",
        name: "Price(RM)",
        type: "price",
        validate: (v) => {
          if (parseFloat(v) === 0) return true; //price 0 is valid
          return parseFloat(v) > 0;
        },
        width: "12%",
      },
    ],
    standardUnit: "ℓ",
    units: ["g", "kg", "mg", "pack (1kg)", "pack (500g)", "mℓ", "ℓ"],
  },
  {
    id: "6",
    bgcolor: "wheat",
    label: "Add Inventory",
    title: "ADD INVENTORY",
    type: {
      eng: "Add Inventory",
      chs: "添加存货",
      cht: "添加存貨",
    },
    icon: MdInventory,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "category",
        name: "Item Category",
        type: "autocomplete",
        options: "categories",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "item",
        name: "Item Name",
        type: "autocompleteInventory",
        options: (o) => {
          let items;
          switch (o.category.eng) {
            case "Foliar":
              items = "foliars";
              break;
            case "Pesticide":
              items = "pesticides";
              break;
            case "Fungicide":
              items = "fungicides";
              break;
            case "Fertilizer":
              items = "fertilizers";
              break;
            default:
              items = "";
              break;
          }
          return items;
        },
        validate: async (v, d) => {
          let items;
          switch (d.category.eng) {
            case "Foliar":
              items = "foliars";
              break;
            case "Pesticide":
              items = "pesticides";
              break;
            case "Fungicide":
              items = "fungicides";
              break;
            case "Fertilizer":
              items = "fertilizers";
              break;
            default:
              items = "";
              break;
          }
          const res = await query(`farms/name/${d.farmId}`);
          console.log("ResData: ", res.data[items]);
          let isMatch = true;
          if (res.data[items]) {
            isMatch = res.data[items]?.some((item) => {
              return (
                item.name.eng === v.eng &&
                item.name.chs === v.chs &&
                item.name.cht === v.cht
              );
            });
          }
          return (!!v.eng || !!v.chs || !!v.cht) && isMatch;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: (v) => {
          return v > 0;
        },
        width: "12%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: ["g", "kg", "mg", "pack (1kg)", "pack (500g)", "mℓ", "ℓ"],
        width: "12%",
      },
      {
        id: "price",
        name: "Price(RM)",
        type: "price",
        validate: (v) => {
          if (parseFloat(v) === 0) return true; //price 0 is valid
          return parseFloat(v) > 0;
        },
        width: "12%",
      },
    ],
    standardUnit: "ℓ",
    units: ["g", "kg", "mg", "pack (1kg)", "pack (500g)", "mℓ", "ℓ"],
  },
  {
    id: "7",
    bgcolor: "forestGreen",
    label: "Harvest",
    title: "HARVEST",
    type: {
      eng: "Harvest",
      chs: "收成",
      cht: "收成",
    },
    icon: GiFruitBowl,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "item",
        name: "Plant Name",
        type: "autocomplete",
        options: "plants",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: (v) => {
          return v > 0;
        },
        width: "10%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: ["g", "kg", "pieces"],
        width: "10%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "13%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "12%",
      },
      {
        id: "convertQuantity",
        name: "Average kg per 1 piece",
        type: "row",
        validate: (v) => {
          return v > 0;
        },
        width: "15%",
      },
    ],
    standardUnit: "kg",
    units: ["g", "kg", "pieces"],
  },
  {
    id: "8",
    bgcolor: "red",
    label: "Sales",
    title: "SALES",
    type: {
      eng: "Sales",
      chs: "销售",
      cht: "銷售",
    },
    icon: GiMoneyStack,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "item",
        name: "Plant Name",
        type: "autocomplete",
        options: "plants",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: (v) => {
          return v > 0;
        },
        width: "12%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: ["kg", "pack (1kg)", "pack (500g)"],
        width: "12%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "12%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "12%",
      },
      {
        id: "price",
        name: "Price(RM)",
        type: "price",
        validate: (v) => {
          if (parseFloat(v) === 0) return true; //price 0 is valid
          return parseFloat(v) > 0;
        },
        width: "12%",
      },
    ],
    standardUnit: "kg",
    units: ["kg", "pack (1kg)", "pack (500g)"],
  },
  {
    id: "9",
    bgcolor: "greenyellow",
    label: "Transplant",
    title: "TRANSPLANT",
    type: {
      eng: "Transplant",
      chs: "个/株/棵",
      cht: "個/株/薈",
    },
    icon: GiSeedling,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "15%",
      },
      {
        id: "item",
        name: "Plant Name",
        type: "autocomplete",
        options: "plants",
        validate: (v) => {
          return !!v.eng || !!v.chs || !!v.cht;
        },
        width: "25%",
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "number",
        props: { type: "number" },
        validate: (v) => {
          return v > 0;
        },
        width: "15%",
      },
      {
        id: "unit",
        name: "Unit",
        type: "unit",
        validate: (v) => {
          return !!v;
        },
        units: ["pieces"],
        width: "15%",
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "15%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "15%",
      },
    ],
    standardUnit: "pieces",
    units: ["pieces"],
  },
  {
    id: "10",
    bgcolor: "dodgerblue",
    label: "Others",
    title: "OTHERS",
    type: {
      eng: "Others",
      chs: "其他",
      cht: "其他",
    },
    icon: GiSelect,
    fields: [
      {
        id: "date",
        name: "Date",
        type: "date",
        validate: (v) => {
          return !!v;
        },
        width: "20%",
      },
      {
        id: "remarks",
        name: "Remarks",
        type: "text",
        validate: (v) => {
          return !!v;
        },
        width: "40%",
        props: { fullWidth: true },
      },
      {
        id: "field",
        name: "Field Number",
        type: "field",
        props: { type: "number" },
        validate: (v) => {
          if (parseInt(v) === 0) return true; //field number 0 is valid
          return parseInt(v) > 0;
        },
        width: "20%",
      },
      {
        id: "row",
        name: "Row Range",
        type: "row",
        validate: (v) => {
          return !!validateRange(v);
        },
        width: "20%",
      },
    ],
    standardUnit: "",
    units: [],
  },
];
