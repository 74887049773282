import React from "react";
import ReactEcharts from "echarts-for-react";
import { SummaryCard } from "../dashboard/dashboard";
import { computeFinancialMetricsData } from "../visualisation-function/compute-data";
import { noDataGraph } from "../visualisation-function/compute-data";

export default function ProfitOrLossChart({
	chartRefs,
	financialMetrics,
	financialMonths,
	t,
	currencyFormat,
}) {
	return (
		<div>
			{financialMetrics.length > 0 && (
				<SummaryCard
					title={t("Profit or Loss")}
					component={
						<div ref={chartRefs.ProfitOrLoss}>
							<ReactEcharts
								option={computeFinancialMetricsData(
									financialMetrics,
									financialMonths,
									t,
									currencyFormat,
									"profitOrLoss"
								)}
							/>
						</div>
					}
				/>
			)}
			{(financialMetrics === undefined || financialMetrics.length === 0) && (
				<SummaryCard
					title={t("Financial Metrics")}
					component={
						<div ref={chartRefs.ProfitOrLoss}>
							<ReactEcharts option={noDataGraph(t)} />
						</div>
					}
				/>
			)}
		</div>
	);
}
