import React from "react";
import ReactEcharts from "echarts-for-react";
import { SummaryCard } from "../dashboard/dashboard";
import { computeFinancialMetricsData } from "../visualisation-function/compute-data";
import { noDataGraph } from "../visualisation-function/compute-data";

export default function ExpensesChart({
	chartRefs,
	totalExpenses,
	financialMonths,
	t,
	currencyFormat,
}) {
	return (
		<div>
			{totalExpenses.length > 0 && (
				<SummaryCard
					title={t("Total Expenses")}
					component={
						<div ref={chartRefs.TotalExpenses}>
							<ReactEcharts
								option={computeFinancialMetricsData(
									totalExpenses,
									financialMonths,
									t,
									currencyFormat
								)}
							/>
						</div>
					}
				/>
			)}
			{(totalExpenses === undefined || totalExpenses.length === 0) && (
				<SummaryCard
					title={t("Total Expenses")}
					component={
						<div ref={chartRefs.TotalExpenses}>
							<ReactEcharts option={noDataGraph(t)} />
						</div>
					}
				/>
			)}
		</div>
	);
}
