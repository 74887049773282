import React from "react";
import ReactEcharts from "echarts-for-react";
import { SummaryCard } from "../dashboard/dashboard";
import { computeFinancialMetricsData } from "../visualisation-function/compute-data";
import { noDataGraph } from "../visualisation-function/compute-data";

export default function SalesChart({
	chartRefs,
	totalSales,
	financialMonths,
	t,
	currencyFormat,
}) {
	return (
		<div>
			{totalSales.length > 0 && financialMonths !== undefined && (
				<SummaryCard
					title={t("Total Sales")}
					component={
						<div ref={chartRefs.TotalSales}>
							<ReactEcharts
								option={computeFinancialMetricsData(
									totalSales,
									financialMonths,
									t,
									currencyFormat
								)}
							/>
						</div>
					}
				/>
			)}
			{(totalSales === undefined || totalSales.length === 0) && (
				<SummaryCard
					title={t("Total Sales")}
					component={
						<div ref={chartRefs.TotalSales}>
							<ReactEcharts option={noDataGraph(t)} />
						</div>
					}
				/>
			)}
		</div>
	);
}
