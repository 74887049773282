import { setAuthDetails, clearAuthDetails } from "../redux/slices/auth-slice";
import { showErrorSnackbar } from "../redux/slices/snackbar-slice";
import { showLoading, hideLoading } from "../redux/slices/loading-slice";
import { store } from "../redux/store";
import { axiosInstance, axiosInstanceNoAuth } from "./axios-instance";
import { query } from "./common";
import { setSelectedFarmId } from "../redux/slices/farm-slice";

export const loginUser = async (em, password) => {
  try {
    const user = await login(em, password);
    store.dispatch(setAuthDetails(user));
    store.dispatch(setSelectedFarmId(user.farms[0]?.id));
    store.dispatch(hideLoading());
    return user;
  } catch (e) {
    store.dispatch(hideLoading());
    store.dispatch(
      showErrorSnackbar("Invalid email or password, please try again.")
    );
    return e;
  }
};

export const refreshAuthToken = async () => {
  const user = await refresh();
  const { _id, id, role, name, email } = user;
  store.dispatch(setAuthDetails(_id, id, role, name, email));
};

export const logoutUser = async () => {
  store.dispatch(showLoading());
  try {
    await logout();
  } catch (e) {
    store.dispatch(
      showErrorSnackbar("Your session has expired. Please login again.")
    );
  } finally {
    // clear auth data in redux store
    store.dispatch(clearAuthDetails());
    store.dispatch(hideLoading());
  }
};

export const getResetPasswordLink = async (email) => {
  return query(`/auth/requestresetpassword?email=${email}`);
};

export const resetPassword = (token, password) => {
  const url = `/auth/resetpassword?token=${token}`;
  const data = { password };
  return new Promise((resolve, reject) => {
    axiosInstanceNoAuth
      .put(url, data, { withCredentials: true })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve({ status, headers, data });
      })
      .catch((err) => {
        if (!!err.response && !!err.response.data && err.response.data.message)
          return reject(err.response.data.message);
        return reject(err.message);
      });
  });
};

const login = async (email, password) => {
  const data = { email, password };
  const url = `/auth/login`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, data, { withCredentials: true })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        store.dispatch(hideLoading());
        return resolve(data);
      })
      .catch((err) => {
        store.dispatch(clearAuthDetails());
        store.dispatch(hideLoading());
        if (
          !!err.response &&
          !!err.response.data &&
          err.response.data.message
        ) {
          return reject(err.response.data.message);
        }
        return reject(err.message);
      });
  });
};

const refresh = () => {
  const url = `/auth/refresh`;
  return new Promise((resolve, reject) => {
    axiosInstanceNoAuth
      .get(url, { withCredentials: true })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          store.dispatch(clearAuthDetails());
          return reject("error occurred");
        }
        store.dispatch(hideLoading());
        return resolve(data);
      })
      .catch((err) => {
        store.dispatch(clearAuthDetails());
        store.dispatch(hideLoading());
        return reject(
          new Error("Your session has expired. Please login again.")
        );
      });
  });
};

const logout = () => {
  const url = `/auth/logout`;
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(url, {}, { withCredentials: true })
      .then((response) => ({
        status: response.status,
        headers: response.headers,
        data: response.data.data,
      }))
      .then(({ status, headers, data }) => {
        if (status < 200 || status >= 300) {
          return reject("error occurred");
        }
        return resolve(data);
      })
      .catch((err) => {
        if (
          !!err.response &&
          !!err.response.data &&
          err.response.data.message
        ) {
          return reject(err.response.data.message);
        }
        return reject(err.message);
      });
  });
};
