import React, { useState, useEffect, Fragment } from "react";
import { CustomCalendar } from "./calendar/custom-calendar";
import { activityTypes } from "../../constants/activity-types";
import { useTranslation } from "react-i18next";
import { queryList } from "../../api/common";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Typography,
  Box,
  Divider,
  Grid,
  Stack,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import { exportToExcel } from "../../utils/csvUtils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";
import { setTitle } from "../../redux/slices/title-slice";
import { enUS, zhCN } from "date-fns/locale";

export function Statistics() {
  const dispatch = useDispatch();
  const selectedFarmId = useSelector((state) => state.selectedFarm.farmId);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterOptions, setFilterOptions] = useState({
    plants: [],
    fertilizers: [],
    pesticides: [],
    foliars: [],
    fungicides: [],
  });
  const [filters, setFilters] = useState({});
  const [dialog, setDialog] = useState({ open: false, title: "" });
  const [farmData, setFarmData] = useState({});

  // on load
  useEffect(() => {
    dispatch(setTitle("Statistics"));
    if (!selectedFarmId) return;
    // fetch options
    queryList(`farms/name/${selectedFarmId}`).then((res) => {
      setFarmData(res.data);
    });
    // fetch activities
    queryList(`activities`, `sort=-date&filter[farmId]=${selectedFarmId}`).then(
      ({ data }) => {
        setActivities(data);
      }
    );
    // eslint-disable-next-line
  }, [selectedFarmId]);

  useEffect(() => {
    if (!farmData || Object.keys(farmData).length === 0) return;
    const { plants, fertilizers, pesticides, foliars, fungicides } = farmData;
    const locale = language === "cht" || language === "chs" ? "zh-CN" : "en";
    setFilterOptions({
      plants: plants
        .sort((a, b) =>
          a.name[language].localeCompare(b.name[language], locale)
        )
        .map((p) => ({
          id: p.name.eng,
          name: p.name[language],
        })),
      fertilizers: fertilizers
        .sort((a, b) =>
          a.name[language].localeCompare(b.name[language], locale)
        )
        .map((p) => ({
          id: p.name.eng,
          name: p.name[language],
        })),
      pesticides: pesticides
        .sort((a, b) =>
          a.name[language].localeCompare(b.name[language], locale)
        )
        .map((p) => ({
          id: p.name.eng,
          name: p.name[language],
        })),
      foliars: foliars
        .sort((a, b) =>
          a.name[language].localeCompare(b.name[language], locale)
        )
        .map((p) => ({
          id: p.name.eng,
          name: p.name[language],
        })),
      fungicides: fungicides
        .sort((a, b) =>
          a.name[language].localeCompare(b.name[language], locale)
        )
        .map((p) => ({
          id: p.name.eng,
          name: p.name[language],
        })),
    });
  }, [farmData, language]);

  // apply filter automatically whenever any values change
  useEffect(() => {
    // invert start and end date if needed
    if (startDate > endDate) {
      setEndDate(startDate);
      setStartDate(endDate);
      return;
    }

    // convert date without time
    const startDateOnly = new Date(startDate);
    startDateOnly.setHours(0, 0, 0, 0);
    const endDateOnly = new Date(endDate);
    endDateOnly.setHours(0, 0, 0, 0);

    const tmp = activities.filter((a) => {
      const tmpDate = new Date(a.date);
      tmpDate.setHours(0, 0, 0, 0);
      if (tmpDate < startDateOnly || tmpDate > endDateOnly) return false;
      if (filters["plants"] && a.item.eng !== filters["plants"]) return false;
      if (filters["fertilizers"] && a.item.eng !== filters["fertilizers"])
        return false;
      if (filters["pesticides"] && a.item.eng !== filters["pesticides"])
        return false;
      if (filters["foliars"] && a.item.eng !== filters["foliars"]) return false;
      if (filters["fungicides"] && a.item.eng !== filters["fungicides"])
        return false;
      if (filters["field"] && a.field.toString() !== filters["field"])
        return false;
      if (filters["row"] && a.row.toString() !== filters["row"]) return false;
      return true;
    });
    setFilteredActivities(tmp);
  }, [startDate, endDate, filters, activities]);

  const SelectComponent = ({ label, value, choices, setValue }) => {
    if (!value) value = "";
    return (
      <FormControl fullWidth>
        <InputLabel>{t(label)}</InputLabel>
        <Select value={value} label={t(label)} onChange={setValue}>
          {choices.map((choice) => (
            <MenuItem key={choice.id} value={choice.id}>
              {choice.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const getActivityType = (act) => {
    return activityTypes.find((at) => at.label === act.action);
  };

  const countPlaces = (num) => {
    var text = num.toString();
    var index = text.indexOf(".");
    return index === -1 ? 0 : text.length - index - 1;
  };

  const getAggregatedResult = () => {
    const result = {};
    filteredActivities.forEach((a) => {
      // populate the key
      const key = `${a.action}-${a.item[language]}`;
      if (!!result[key]) {
        // if exists in result, add into the count
        result[key].quantity += a.quantity;
        if (result[key].price) result[key].price += a.price;
      } else {
        // else, create a new result
        if (
          (a.action == "Aerating") |
          (a.action == "Sowing") |
          (a.action == "Harvest") |
          (a.action == "Transplant") |
          (a.action == "Others")
        ) {
          result[key] = {
            key: key,
            unit: a.unit,
            quantity: a.quantity,
            action: a.action,
            item: a.item,
            bgcolor: getActivityType(a)?.bgcolor,
            icon: getActivityType(a)?.icon,
            standardUnit: getActivityType(a)?.standardUnit,
          };
        } else {
          result[key] = {
            key: key,
            unit: a.unit,
            quantity: a.quantity,
            price: a.price,
            action: a.action,
            item: a.item,
            bgcolor: getActivityType(a)?.bgcolor,
            icon: getActivityType(a)?.icon,
            standardUnit: getActivityType(a)?.standardUnit,
          };
        }
      }
    });
    // convert result into list and sort it
    const resultList = Object.keys(result).map((r) => result[r]);
    resultList.sort((a, b) => {
      if (a.action < b.action) return -1;
      return 1;
    });
    return resultList;
  };

  const handleExport = () => {
    // preprocess data before export
    console.log(filteredActivities);
    const data = filteredActivities.map((a) => [
      new Date(a?.createdAt)?.toLocaleDateString(),
      new Date(a?.date)?.toLocaleDateString(),
      a?.farmName?.eng,
      a?.farmName?.chs,
      a?.farmName?.cht,
      a?.action,
      a?.item?.eng,
      a?.item?.chs,
      a?.item?.cht,
      a?.field,
      a?.row,
      a?.quantity,
      a?.unit,
      a?.price,
      a?.convertQuantity,
      a?.originalQuantity,
      a?.originalUnit,
    ]);
    const headers = [
      "Created At",
      "Date",
      "Farm Name (ENG)",
      "Farm Name (CHS)",
      "Farm Name (CHT)",
      "Action",
      "Plant / Fertilizer / Pesticide / Foliar / Fungicide(ENG)",
      "Plant / Fertilizer / Pesticide / Foliar / Fungicide(CHS)",
      "Plant / Fertilizer / Pesticide / Foliar / Fungicide(CHT)",
      "Field",
      "Row",
      "Quantity",
      "Unit",
      "Price",
      "Convert Quantity",
      "Original Quantity",
      "Original Unit",
    ];
    exportToExcel(headers, data);
  };

  const DateComponent = ({ date, setDate }) => {
    const locale = language === "cht" || language === "chs" ? zhCN : enUS;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          value={date}
          onChange={setDate}
          renderInput={(params) => <TextField {...params} sx={{ flex: "2" }} />}
        />
      </LocalizationProvider>
    );
  };

  const ActivityDetails = ({ height }) => {
    return filteredActivities?.length === 0 ? (
      <Box
        sx={{
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{t("No data to be shown")}</Typography>
      </Box>
    ) : (
      <List
        sx={{
          height: height ?? "auto",
          overflow: "auto",
        }}
      >
        {filteredActivities?.map((activity, index) => (
          <Fragment key={index}>
            <ListItem>
              <ListItemText>
                <Typography sx={{ mb: 1 }}>
                  {new Date(activity.date).toLocaleDateString()}
                </Typography>
                <span
                  style={{
                    color: getActivityType(activity)?.bgcolor,
                    marginRight: 3,
                    fontSize: 15,
                  }}
                >
                  &#11044;
                </span>
                <b>
                  {activity.item?.[language] +
                    (activity.action == "Add Inventory"
                      ? ` (${activity.action})`
                      : "")}
                </b>
                <b>{activity.remarks}</b>
                {activity.item?.[language] || activity.remarks ? <br /> : ""}
                <i>
                  {activity.originalQuantity &&
                  activity.originalUnit &&
                  activity.originalQuantity !== 0
                    ? `${activity.originalQuantity} ${activity.originalUnit} `
                    : ""}
                  {activity.quantity && activity.unit
                    ? (countPlaces(activity.quantity) <= 6
                        ? "(Standard Unit: " + activity.quantity
                        : "(Standard Unit: " + activity.quantity.toFixed(6)) +
                      ` ${t(getActivityType(activity)?.standardUnit)}) `
                    : ""}
                  {activity.field ? t("F") + activity.field + " " : ""}
                  {activity.row ? t("R") + activity.row : ""}
                </i>
                <i>
                  <br />
                  {(activity.price !== undefined) &
                  (getActivityType(activity).label !== "Aerating") &
                  (getActivityType(activity).label !== "Sowing") &
                  (getActivityType(activity).label !== "Harvest") &
                  (getActivityType(activity).label !== "Transplant") &
                  (getActivityType(activity).label !== "Others")
                    ? "RM" + activity.price
                    : ""}
                </i>
              </ListItemText>
            </ListItem>
            <Divider sx={{ margin: 1 }} />
          </Fragment>
        ))}
      </List>
    );
  };

  const ActivitySummary = ({ height }) => {
    return filteredActivities?.length === 0 ? (
      <Box
        sx={{
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{t("No data to be shown")}</Typography>
      </Box>
    ) : (
      <List
        sx={{
          height: height ?? "auto",
          overflow: "auto",
        }}
      >
        {getAggregatedResult()?.map((result) => (
          <Fragment key={result.key}>
            <ListItem>
              <ListItemAvatar sx={{ marginRight: "10px" }}>
                <Avatar
                  sx={{
                    backgroundColor: result.bgcolor,
                    width: "3rem",
                    height: "3rem",
                  }}
                >
                  <result.icon
                    style={{
                      width: "2rem",
                      height: "2rem",
                      color: "#fff",
                    }}
                  ></result.icon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <b>{t(result.action)}</b>
                <Typography>{result.item?.[language]}</Typography>
                <Typography>
                  {result.quantity && result.standardUnit
                    ? (countPlaces(result.quantity) <= 6
                        ? result.quantity
                        : result.quantity.toFixed(6)) +
                      ` ${t(result.standardUnit)} `
                    : ""}
                </Typography>
                <Typography>
                  {result.price !== undefined ? "RM" + result.price : ""}
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider sx={{ margin: 1 }} />
          </Fragment>
        ))}
      </List>
    );
  };

  const handleDialog = (open, title) => {
    setDialog({ open: open ?? dialog.open, title: title ?? dialog.title });
  };

  return (
    <Grid container padding={2}>
      <Grid item container>
        <Grid item xs={12} md={4} padding={4}>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#92CF34",
                  textAlign: "center",
                  flex: "1",
                  maxWidth: "200px",
                }}
              >
                {t("From")}
              </Typography>
              <DateComponent date={startDate} setDate={setStartDate} />
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#92CF34",
                  textAlign: "center",
                  flex: "1",
                  maxWidth: "200px",
                }}
              >
                {t("To")}
              </Typography>
              <DateComponent date={endDate} setDate={setEndDate} />
            </Stack>
            <SelectComponent
              label="Plants"
              value={filters["plants"]}
              setValue={(v) => {
                setFilters((prev) => ({
                  ...prev,
                  plants: v.target.value,
                }));
              }}
              choices={filterOptions["plants"]}
            />
            <SelectComponent
              label="Fertilizers"
              value={filters["fertilizers"]}
              setValue={(v) => {
                setFilters((prev) => ({
                  ...prev,
                  fertilizers: v.target.value,
                }));
              }}
              choices={filterOptions["fertilizers"]}
            />
            <SelectComponent
              label="Pesticides"
              value={filters["pesticides"]}
              setValue={(v) => {
                setFilters((prev) => ({
                  ...prev,
                  pesticides: v.target.value,
                }));
              }}
              choices={filterOptions["pesticides"]}
            />
            <SelectComponent
              label="Foliars"
              value={filters["foliars"]}
              setValue={(v) => {
                setFilters((prev) => ({
                  ...prev,
                  foliars: v.target.value,
                }));
              }}
              choices={filterOptions["foliars"]}
            />
            <SelectComponent
              label="Fungicides"
              value={filters["fungicides"]}
              setValue={(v) => {
                setFilters((prev) => ({
                  ...prev,
                  fungicides: v.target.value,
                }));
              }}
              choices={filterOptions["fungicides"]}
            />
            <TextField
              label={t("Field Number")}
              onChange={(v) => {
                setFilters((prev) => ({
                  ...prev,
                  field: v.target.value,
                }));
              }}
              type="number"
              name="field"
              value={filters["field"]}
            />
            <TextField
              label={t("Row Number")}
              onChange={(v) => {
                setFilters((prev) => ({
                  ...prev,
                  row: v.target.value,
                }));
              }}
              type="number"
              name="row"
              value={filters["row"]}
            />
            <Box
              sx={{
                display: "flex",
                gap: 3,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Button
                color="secondary"
                onClick={() => {
                  setFilters({});
                }}
              >
                {t("Reset Filters")}
              </Button>
              <Button
                onClick={() => {
                  handleExport();
                }}
              >
                {t("Export")}
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} padding={4}>
          <CustomCalendar
            activities={activities}
            startDate={startDate}
            endDate={endDate}
            setDate={(d) => {
              setStartDate(d);
              setEndDate(d);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        borderTop={{ xs: 0, md: "1px solid rgba(0, 0, 0, 0.12)" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          padding={4}
          borderRight={{ xs: 0, md: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Typography
              variant="h5"
              sx={{
                mb: 3,
              }}
            >
              {t("Activity Details")}
            </Typography>
            {filteredActivities?.length !== 0 && (
              <IconButton
                size="small"
                onClick={() => handleDialog(true, "Activity Details")}
              >
                <OpenInFullIcon />
              </IconButton>
            )}
          </Stack>
          <ActivityDetails height={500} />
        </Grid>
        <Grid item xs={12} md={6} padding={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="start"
          >
            <Typography
              variant="h5"
              sx={{
                mb: 3,
              }}
            >
              {t("Activity Summary")}
            </Typography>
            {filteredActivities?.length !== 0 && (
              <IconButton
                size="small"
                onClick={() => handleDialog(true, "Activity Summary")}
              >
                <OpenInFullIcon />
              </IconButton>
            )}
          </Stack>
          <ActivitySummary height={500} />
        </Grid>
      </Grid>
      <Dialog fullScreen open={dialog.open} onClose={() => handleDialog(false)}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {dialog.title}
          <IconButton
            onClick={() => handleDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {dialog.title === "Activity Details" ? (
            <ActivityDetails />
          ) : dialog.title === "Activity Summary" ? (
            <ActivitySummary />
          ) : null}
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
