import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { create, getOne, queryList, update } from "../../api/common";
import { setTitle } from "../../redux/slices/title-slice";
import { CustomTabs } from "../common/custom-tabs";
import { ChipList } from "../common/chip-list";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../redux/slices/snackbar-slice";
import FarmSettingTable from "./farm-setting-table";
import { useParams } from "react-router-dom";
import { FarmGeneralForm } from "./farm-general-form";
import { useRef } from "react";
import { Button } from "@mui/material";
import { getFallbackDisplayValue } from "../../utils/utils";

const defaultStates = {
  id: "",
  name: {
    eng: "",
    chs: "",
    cht: "",
  },
  address: {
    eng: "",
    chs: "",
    cht: "",
  },
  plants: [],
  fertilizers: [],
  pesticides: [],
  foliars: [],
  fungicides: [],
  tags: ["all"],
  visibleTags: ["all"],
};

const itemDefaultStates = {
  id: "",
  name: {
    eng: "",
    chs: "",
    cht: "",
  },
  tags: ["all"],
  quantity: 0,
  unit: "",
};

export const FarmForm = ({ isCreate }) => {
  const role = useSelector((state) => state.auth.role);
  const [selected, setSelected] = useState(0);
  const [formState, setFormState] = useState(defaultStates);
  const [options, setOptions] = useState({
    tags: [],
    plants: [],
    fertilizers: [],
    pesticides: [],
    foliars: [],
    fungicides: [],
  });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const shouldAutoSubmit = useRef(false);

  useEffect(() => {
    async function fetchData() {
      const plants = await queryList("plants", `sort=name.${language}`);
      const fertilizers = await queryList(
        "fertilizers",
        `sort=name.${language}`
      );
      const pesticides = await queryList("pesticides", `sort=name.${language}`);
      const foliars = await queryList("foliars", `sort=name.${language}`);
      const fungicides = await queryList("fungicides", `sort=name.${language}`);

      // Stop the auto submit function when initializing the form
      shouldAutoSubmit.current = false;
      // get farms
      if (id && !isCreate) {
        const resp = await getOne("farms", id);
        setFormState(resp.data);
        dispatch(setTitle(getFallbackDisplayValue(resp?.data?.name)));
      } else {
        setFormState(defaultStates);
      }

      setOptions((prevState) => ({
        ...prevState,
        plants: plants.data.map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
        })),
        fertilizers: fertilizers.data.map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
        })),
        pesticides: pesticides.data.map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
        })),
        foliars: foliars.data.map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
        })),
        fungicides: fungicides.data.map((p) => ({
          id: JSON.stringify(p.name),
          label: p.name[language],
        })),
      }));
    }
    fetchData();
  }, [id, dispatch, language, isCreate]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      tags: formState.tags.sort().map((x) => ({ id: x, label: x })),
    }));

    // Every change made by user to the formState will be submitted
    // automatically if shouldAutoSubmit value is true
    if (shouldAutoSubmit.current) handleSubmit();

    // Reset the value every time the formState changes
    shouldAutoSubmit.current = true;
  }, [formState]);

  const handleSubmit = () => {
    console.log("Main: ", formState);
    if (isCreate) {
      create("farms", formState)
        .then(() => {
          dispatch(showSuccessSnackbar(t("Created")));
        })
        .catch((err) => {
          dispatch(showErrorSnackbar(err));
        });
    } else {
      update("farms", formState)
        .then(() => {
          dispatch(showSuccessSnackbar(t("Updated")));
        })
        .catch((err) => {
          dispatch(showErrorSnackbar(err));
        });
    }
  };

  const getConfig = (source) => {
    return {
      source,
      initialState: itemDefaultStates,
      fields: [
        {
          id: "name",
          name: "Name",
          type: "autocompleteFarm",
          data: formState[source],
          options: source,
          excludeOptions: formState[source].map((p) => JSON.stringify(p.name)),
          validate: (v) => {
            if (!v.eng && !v.chs && !v.cht) return false;
            return true;
          },
          table: {
            value: `name.${language}`,
          },
          footer: true,
        },
        {
          id: "tags",
          name: "Tags",
          type: "tagsCustom",
          options: "tags",
          validate: (v) => !!v,
          table: {
            value: (value) => <ChipList data={value} />,
          },
          footer: true,
        },
        ...(role === "admin"
          ? [
              {
                id: "global",
                name: "Add to Global List",
                type: "global",
                source,
                table: {
                  value: (value, row) => (
                    <AddToGlobalListButton
                      dispatch={dispatch}
                      t={t}
                      language={language}
                      source={source}
                      row={row}
                    />
                  ),
                  hidden: false,
                },
                footer: true,
              },
            ]
          : []),
        ...(role !== "admin" && source !== "plants"
          ? [
              {
                id: "quantity",
                name: "Quantity",
                type: "number",
                props: { type: "number" },
                width: "10%",
                footer: false,
              },
              {
                id: "unit",
                name: "Unit",
                type: "unit",
                validate: (v) => !!v,
                units: ["kg", "ℓ"],
                width: "10%",
                footer: true,
              },
            ]
          : []),
      ],
    };
  };

  //filter out similar tags
  const filteredTags = (fullTags) =>
    fullTags[fullTags.length - 1].tags.filter(
      (fullTag) => !formState.tags.includes(fullTag)
    );

  const panels = [
    <FarmGeneralForm
      formState={formState}
      setFormState={setFormState}
      handleSubmit={handleSubmit}
      shouldAutoSubmit={shouldAutoSubmit}
    />,
    <FarmSettingTable
      data={formState.plants}
      config={getConfig("plants")}
      options={options}
      setData={(v) => {
        v.length === 0 //when deleting data(current data count will be 0) - if no this logic "v" will be empty array and unable to iterate in "filteredTags"
          ? setFormState({
              ...formState,
              plants: v,
            })
          : setFormState({
              ...formState,
              plants: v,
              tags: [...formState.tags, ...filteredTags(v)], //auto adding tags to farm
              visibleTags: [
                ...formState.visibleTags,
                ...filteredTags(v), //auto adding active tags to farm
              ],
            });
      }}
    />,
    <FarmSettingTable
      data={formState.fertilizers}
      config={getConfig("fertilizers")}
      options={options}
      setData={(v) => {
        v.length === 0 //when deleting data(current data count will be 0) - if no this logic "v" will be empty array and unable to iterate in "filteredTags"
          ? setFormState({
              ...formState,
              fertilizers: v,
            })
          : setFormState({
              ...formState,
              fertilizers: v,
              tags: [...formState.tags, ...filteredTags(v)], //auto adding tags to farm
              visibleTags: [
                ...formState.visibleTags,
                ...filteredTags(v), //auto adding active tags to farm
              ],
            });
      }}
    />,
    <FarmSettingTable
      data={formState.pesticides}
      config={getConfig("pesticides")}
      options={options}
      setData={(v) => {
        v.length === 0 //when deleting data(current data count will be 0) - if no this logic "v" will be empty array and unable to iterate in "filteredTags"
          ? setFormState({
              ...formState,
              pesticides: v,
            })
          : setFormState({
              ...formState,
              pesticides: v,
              tags: [...formState.tags, ...filteredTags(v)], //auto adding tags to farm
              visibleTags: [
                ...formState.visibleTags,
                ...filteredTags(v), //auto adding active tags to farm
              ],
            });
      }}
    />,
    <FarmSettingTable
      data={formState.foliars}
      config={getConfig("foliars")}
      options={options}
      setData={(v) => {
        v.length === 0 //when deleting data(current data count will be 0) - if no this logic "v" will be empty array and unable to iterate in "filteredTags"
          ? setFormState({
              ...formState,
              foliars: v,
            })
          : setFormState({
              ...formState,
              foliars: v,
              tags: [...formState.tags, ...filteredTags(v)], //auto adding tags to farm
              visibleTags: [
                ...formState.visibleTags,
                ...filteredTags(v), //auto adding active tags to farm
              ],
            });
      }}
    />,
    <FarmSettingTable
      data={formState.fungicides}
      config={getConfig("fungicides")}
      options={options}
      setData={(v) => {
        v.length === 0 //when deleting data(current data count will be 0) - if no this logic "v" will be empty array and unable to iterate in "filteredTags"
          ? setFormState({
              ...formState,
              fungicides: v,
            })
          : setFormState({
              ...formState,
              fungicides: v,
              tags: [...formState.tags, ...filteredTags(v)], //auto adding tags to farm
              visibleTags: [
                ...formState.visibleTags,
                ...filteredTags(v), //auto adding active tags to farm
              ],
            });
      }}
    />,
  ];

  return (
    <CustomTabs
      value={selected}
      setValue={setSelected}
      tabs={[
        "General",
        "Plants",
        "Fertilizers",
        "Pesticides",
        "Foliars",
        "Fungicides",
      ]}
      panels={panels}
    />
  );
};

const AddToGlobalListButton = ({ dispatch, t, language, source, row }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    // check if names duplicate
    const promises = [];
    ["eng", "chs", "cht"].forEach((lang) => {
      promises.push(
        queryList(source, `filter[name.${lang}]=${row.name[lang]}`)
      );
    });
    Promise.all(promises)
      .then((values) => {
        if (values[0].total + values[1].total + values[2].total > 0) {
          dispatch(
            showErrorSnackbar(
              t("Similar item is found in the global list: ") +
                row.name[language]
            )
          );
        } else {
          // add into new item
          create(source, row)
            .then(() => {
              dispatch(
                showSuccessSnackbar(
                  t("The item is added into the global list!")
                )
              );
            })
            .catch((e) => {
              dispatch(showErrorSnackbar(e));
            });
        }
      })
      .catch((e) => {
        dispatch(showErrorSnackbar(e));
      });
  };

  return (
    <Button
      size="small"
      sx={{
        mt: 1,
        mb: 1,
      }}
      onClick={handleClick}
    >
      {t("Add")}
    </Button>
  );
};
