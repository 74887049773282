import React from "react";
import { useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Alert } from "@mui/material";
import { hideSnackbar } from "../../redux/slices/snackbar-slice";
import { store } from "../../redux/store";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const SnackbarModal = () => {
  const classes = useStyles();
  let open = useSelector((state) => state.snackbar.open);
  const message = useSelector((state) => state.snackbar.message);
  const alert = useSelector((state) => state.snackbar.alertType);

  const { t } = useTranslation("errorTranslations");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    store.dispatch(hideSnackbar());
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={alert}
        >
          {t(message)}
        </Alert>
      </Snackbar>
    </div>
  );
};
