export function getDateRange(monthRange, isUntilLastDay = false) {
  var endDate = new Date();
  // var endDateDay = String(endDate.getDate()).padStart(2, "0");
  // var endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  // var endDateYear = endDate.getFullYear();
  if (isUntilLastDay) {
    var today = new Date();
    endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59)
      .toISOString()
      .split("T")[0];
    endDate += "T23:59:59.000Z";
  } else {
    // endDate =
    //   endDateYear + "-" + endDateMonth + "-" + endDateDay + "T00:00:00.000Z"; //2023-01-01T00:00:00.000Z
    endDate = endDate.toISOString();
  }

  var startDate = new Date();
  var startDateMonth = null;
  var startDateYear = startDate.getFullYear();
  startDateMonth = String(
    (startDate.getMonth() + 1 - monthRange) % 12
  ).padStart(2, "0");
  if (startDateMonth < 1) {
    startDateMonth = String(12 + parseInt(startDateMonth)).padStart(2, "0");
    const yearGap = Math.ceil(Math.abs(monthRange) / 12);
    startDateYear -= yearGap;
  }

  startDate = startDateYear + "-" + startDateMonth + "-" + "01T00:00:00.000Z";
  return [startDate, endDate];
}

export function getDateRangeType(rangeType, isUntilLastDay = false) {
  console.log("RangeType: ", rangeType);
  var endDate = new Date();

  if (isUntilLastDay) {
    var today = new Date();
    endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59)
      .toISOString()
      .split("T")[0];
    endDate += "T23:59:59.000Z";
  } else {
    endDate = endDate.toISOString();
  }

  var todayDate = new Date();
  var todayDateYear = todayDate.getFullYear();
  var todayDateMonth = todayDate.getMonth();
  // var startDateMonth = null;
  // var startDateYear = startDate.getFullYear();

  switch (rangeType) {
    case "thisMonth":
      var startDate = new Date(
        todayDateYear,
        todayDateMonth,
        1,
        0,
        0,
        0
      ).toISOString();
      break;
    case "lastMonth":
      var startDate = new Date(
        todayDateYear,
        todayDateMonth - 1,
        1,
        0,
        0,
        0
      ).toISOString();
      endDate = new Date(
        todayDateYear,
        todayDateMonth,
        1,
        0,
        0,
        0
      ).toISOString();
      break;
    case "past3Months":
      var startDate = new Date(
        todayDateYear,
        todayDateMonth - 2,
        1,
        0,
        0,
        0
      ).toISOString();
      break;
    case "past6Months":
      var startDate = new Date(2023, 0, 1, 0, 0, 0).toISOString();
      break;
    case "past12Months":
      var startDate = new Date(
        todayDateYear,
        todayDateMonth - 11,
        1,
        0,
        0,
        0
      ).toISOString();
      break;
    case "thisYear":
      var startDate = new Date(todayDateYear, 0, 1, 0, 0, 0).toISOString();
      break;
    case "lastYear":
      var startDate = new Date(todayDateYear - 1, 0, 1, 0, 0, 0).toISOString();
      endDate = new Date(todayDateYear - 1, 11, 31, 23, 59, 59).toISOString();
      break;
    case "past3Years":
      var startDate = new Date(todayDateYear - 2, 0, 1, 0, 0, 0).toISOString();
      break;
    case "past5Years":
      var startDate = new Date(todayDateYear - 4, 0, 1, 0, 0, 0).toISOString();
      break;
    case "past10Years":
      var startDate = new Date(todayDateYear - 9, 0, 1, 0, 0, 0).toISOString();
      break;
    default:
      var startDate = new Date(
        todayDateYear,
        todayDateMonth - 11,
        1,
        0,
        0,
        0
      ).toISOString();
      break;
  }
  console.log("Start Date: ", startDate);
  console.log("End Date: ", endDate);
  return [startDate, endDate];
}
