import { queryList } from "../api/common";
import { ChipList } from "../components/common/chip-list";
import { store } from "../redux/store";
import { ACCOUNT_STATUS_CHOICES, ROLE_CHOICES } from "./choices";
import i18next from "i18next";
import { getFallbackDisplayValue } from "../utils/utils";

// The supported properties for field object
// id: string,
// name: string,
// type: string,
// options: function || string,
// props: object,
// validate: function,
// create: {
//   hidden: bool,
//   noValidate: bool,
// },
// edit: {
//   hidden: bool,
//   noValidate: bool,
// },
// table: {
//   hidden: bool,
//   value: function || string,
//   width: string,
// },

export const PlantsConfig = {
  source: "plants",
  initialState: {
    id: "",
    name: {
      eng: "",
      chs: "",
      cht: "",
    },
    tags: [],
  },
  fields: [
    {
      id: "name.eng",
      name: "Name (ENG)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.cht",
      name: "Name (CHT)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.chs",
      name: "Name (CHS)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "tags",
      name: "Tags",
      type: "tagsCustom",
      validate: (v) => v.length > 0,
      table: {
        value: (value) => <ChipList data={value} />,
      },
    },
  ],
};

export const UsersConfig = {
  source: "users",
  initialState: {
    accountStatus: "active",
    email: "",
    farms: [],
    id: "",
    name: {
      eng: "",
      chs: "",
      cht: "",
    },
    permissions: [],
    phoneNumber: "",
    role: "farmer",
    password: "",
  },
  fields: [
    {
      id: "name.eng",
      name: "Name (ENG)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.cht",
      name: "Name (CHT)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.chs",
      name: "Name (CHS)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "role",
      name: "Role",
      type: "select",
      options: () =>
        store.getState().auth.role === "admin"
          ? ROLE_CHOICES
          : ROLE_CHOICES.filter((x) => x.id !== "admin"),
      validate: (v) => !!v,
      table: {
        value: (value) => {
          const label =
            ROLE_CHOICES.find((choice) => choice.id === value)?.name ?? value;
          return i18next.t(label);
        },
      },
    },
    {
      id: "email",
      name: "Email",
      type: "text",
      validate: (v) => !!v,
    },
    {
      id: "phoneNumber",
      name: "Phone Number",
      type: "text",
      validate: (v) => !!v,
    },
    {
      id: "farms",
      name: "Farms",
      type: "tags",
      options: () =>
        queryList("farms/options").then(({ data }) => {
          const auth = store.getState().auth;
          const language = i18next.language;
          if (auth.role !== "admin") {
            data = data.filter(
              (d) => auth.farms.filter((x) => x.id === d.id).length > 0
            );
          }
          data = data
            .sort((a, b) => {
              if (a.name[language] < b.name[language]) return -1;
              return 1;
            })
            .map((p) => ({
              id: p.id,
              label: p.name[language],
            }));
          return data;
        }),
      validate: (v) => !!v,
      table: {
        value: (value) => (
          <ChipList
            data={value.map((farm) => getFallbackDisplayValue(farm.name))}
          />
        ),
      },
    },
    {
      id: "accountStatus",
      name: "Account Status",
      type: "select",
      options: () => ACCOUNT_STATUS_CHOICES,
      validate: (v) => !!v,
      table: {
        value: (value) => {
          const label =
            ACCOUNT_STATUS_CHOICES.find((choice) => choice.id === value)
              ?.name ?? value;
          return i18next.t(label);
        },
      },
    },
    {
      id: "password",
      name: "Password",
      type: "text",
      props: { type: "password" },
      validate: (v) => !v,
      edit: { hidden: true },
      table: { hidden: true },
    },
  ],
};

export const FertilizersConfig = {
  source: "fertilizers",
  initialState: {
    id: "",
    name: {
      eng: "",
      chs: "",
      cht: "",
    },
    tags: [],
  },
  fields: [
    {
      id: "name.eng",
      name: "Name (ENG)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.cht",
      name: "Name (CHT)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.chs",
      name: "Name (CHS)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "tags",
      name: "Tags",
      type: "tagsCustom",
      validate: (v) => !!v,
      table: {
        value: (value) => <ChipList data={value} />,
      },
    },
  ],
};

export const PesticidesConfig = {
  source: "pesticides",
  initialState: {
    id: "",
    name: {
      eng: "",
      chs: "",
      cht: "",
    },
    tags: [],
  },
  fields: [
    {
      id: "name.eng",
      name: "Name (ENG)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.cht",
      name: "Name (CHT)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.chs",
      name: "Name (CHS)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "tags",
      name: "Tags",
      type: "tagsCustom",
      validate: (v) => !!v,
      table: {
        value: (value) => <ChipList data={value} />,
      },
    },
  ],
};
export const FoliarsConfig = {
  source: "Foliars",
  initialState: {
    id: "",
    name: {
      eng: "",
      chs: "",
      cht: "",
    },
    tags: [],
  },
  fields: [
    {
      id: "name.eng",
      name: "Name (ENG)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.cht",
      name: "Name (CHT)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.chs",
      name: "Name (CHS)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "tags",
      name: "Tags",
      type: "tagsCustom",
      validate: (v) => !!v,
      table: {
        value: (value) => <ChipList data={value} />,
      },
    },
  ],
};
export const FungicidesConfig = {
  source: "Fungicides",
  initialState: {
    id: "",
    name: {
      eng: "",
      chs: "",
      cht: "",
    },
    tags: [],
  },
  fields: [
    {
      id: "name.eng",
      name: "Name (ENG)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.cht",
      name: "Name (CHT)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "name.chs",
      name: "Name (CHS)",
      type: "nested",
      validate: (v) => !!v,
    },
    {
      id: "tags",
      name: "Tags",
      type: "tagsCustom",
      validate: (v) => !!v,
      table: {
        value: (value) => <ChipList data={value} />,
      },
    },
  ],
};
