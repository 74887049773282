import React from "react";
import { TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";

export default function SearchField(props) {
  const {
    label = "Search",
    value,
    setFilters,
    debouncePeriod = 500,
    ...rest
  } = props;
  const [search, setSearch] = useState(value ?? "");
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (!changed) return;
    setChanged(false);
    const searchTimeout = setTimeout(() => {
      setFilters((prevState) => ({ ...prevState, search }));
    }, debouncePeriod);

    return () => clearTimeout(searchTimeout);
  }, [search]);

  function handleChange(e) {
    setSearch(e.target.value);
    setChanged(true);
  }

  return (
    <TextField
      label={label}
      variant="outlined"
      size="small"
      value={search}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FaSearch />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}
