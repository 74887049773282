import React from "react";
import { useState, useEffect } from "react";
import { axiosInstance } from "../../api/axios-instance";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  InputBase,
  IconButton,
  Paper,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloudIcon from "@mui/icons-material/Cloud";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import OpacityIcon from "@mui/icons-material/Opacity";
import { ForecastColumn } from "./forecast-column";
import backgroundImage from "../../images/green-background.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: "#91dd75",
    // backgroundImage: "linear-gradient(to bottom, #91dd75, #B0D776)",
    backgroundImage: `url(${backgroundImage})`,
    opacity: 0.95,
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "40px",
  },

  searchBar: {
    margin: "10px 0 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  section: {
    margin: "50px 20px 50px 20px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    spacing: "10px",
  },

  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "end",
  },

  divider: {
    marginTop: "10px",
    marginBottom: "10px",
    backgroundColor: "white",
    height: 2,
  },

  textCenter: {
    textAlign: "center",
  },

  temperature: {
    fontWeight: "bold",
    color: "#f94d00",
  },
}));

function formatDate(date) {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);

  return formattedDate;
}

function formatTime(date) {
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = new Date(date).toLocaleTimeString(undefined, options);

  return formattedTime;
}

export const Weather = () => {
  const classes = useStyles();

  const [currentWeatherData, setCurrentWeatherData] = useState(null);
  const [forecastWeatherData, setforecastWeatherData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [location, setLocation] = useState("Kuala Lumpur");

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setLocation(searchValue);
  };

  useEffect(() => {
    const fetchWeatherData = async (weatherLocation) => {
      try {
        const url = `/weather/forecastweather?location=${weatherLocation}`;
        const response = await axiosInstance.get(url);

        const allWeatherData = response.data.data;
        const forecastData = response.data.data.forecast.forecastday;
        // console.log(allWeatherData);
        // console.log(forecastData);
        // console.log(forecastData[0].hour);
        // forecastData[0].hour.map((hour) => {
        // 	console.log(hour.time);
        // });
        console.log("Calling fetchWeatherData");

        setCurrentWeatherData(allWeatherData);
        setforecastWeatherData(forecastData);

        // Call the fetchWeatherData function recursively for every 3 minutes
        setTimeout(() => fetchWeatherData(weatherLocation), 500000);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWeatherData(location);
  }, [location]);

  if (!currentWeatherData || !forecastWeatherData) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.searchBar}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 300,
          }}
          onSubmit={handleSearch}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Location"
            inputProps={{ "aria-label": "search location" }}
            onChange={handleInputChange}
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {currentWeatherData && (
        <div className={classes.section}>
          <div className={classes.flexRow} style={{ justifyContent: "center" }}>
            <Typography
              className={classes.textCenter}
              sx={{ padding: "0 10px 0 10px" }}
            >
              {formatDate(currentWeatherData.location.localtime)}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Typography
              className={classes.textCenter}
              sx={{ padding: "0 10px 0 10px" }}
            >
              Local Time&nbsp;&nbsp;
              {formatTime(currentWeatherData.location.localtime)}
            </Typography>
          </div>
          <Typography
            variant="h3"
            className={classes.textCenter}
            sx={{ fontWeight: "bold", margin: "10px 0 10px 0" }}
          >
            {currentWeatherData.location.name},&nbsp;
            {currentWeatherData.location.country}
          </Typography>
          <Typography
            variant="h5"
            className={classes.textCenter}
            sx={{ margin: "10px 0 20px 0", color: "#ffff00" }}
          >
            {currentWeatherData.current.condition.text}
          </Typography>
          <div className={classes.flexRow}>
            <img
              src={currentWeatherData.current.condition.icon}
              alt="weather icon"
              style={{
                height: "100px",
                width: "100px",
              }}
            />
            <Typography variant="h3" className={classes.temperature}>
              {currentWeatherData.current.temp_c}
              {"\u00B0C"}
            </Typography>
            <div className={classes.flexColumn}>
              <div className={classes.flexRow}>
                <DeviceThermostatIcon />
                <Typography>
                  Feel like {currentWeatherData.current.feelslike_c}
                  {"\u00B0C"}
                </Typography>
              </div>
              <div className={classes.flexRow}>
                <CloudIcon />
                <Typography>
                  Cloud Cover: {currentWeatherData.current.cloud}%
                </Typography>
              </div>
              <div className={classes.flexRow}>
                <OpacityIcon />
                <Typography>
                  Humidity: {currentWeatherData.current.humidity}%
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.section}>
        <Typography variant="h5" sx={{ marginBottom: "5px" }}>
          Hourly Forecast
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.flexRow}>
          {forecastWeatherData[0].hour &&
            forecastWeatherData[0].hour
              .filter((obj) => {
                const now = new Date();
                const currentTime = now.getTime();
                const objTime = new Date(obj.time).getTime();
                const timeDifference =
                  (objTime - currentTime) / (1000 * 60 * 60);
                return timeDifference >= 0 && timeDifference <= 5;
              })
              .map((hour, index) => {
                return (
                  <ForecastColumn
                    key={index}
                    title={formatTime(hour.time)}
                    icon={hour.condition.icon}
                    temp={hour.temp_c}
                    type={"hour"}
                  />
                );
              })}
        </div>
      </div>
      <div className={classes.section}>
        <Typography variant="h5" sx={{ marginBottom: "5px" }}>
          Daily Forecast
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.flexRow}>
          {forecastWeatherData &&
            forecastWeatherData.map((day, index) => {
              return (
                <ForecastColumn
                  key={index}
                  title={day.date}
                  icon={day.day.condition.icon}
                  temp={day.day.avgtemp_c}
                  type={"day"}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
