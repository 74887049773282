import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Menu,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import logo from "../../images/logo.png";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material/styles";
import { activityTypes } from "../../constants/activity-types";
import { update } from "../../api/common";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

export const CustomTabsActivity = ({
  tabs,
  panels,
  value,
  setValue,
  farmData,
  setFarmData,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event, newValue) => {
    console.log("NV: ", newValue);
    setValue(newValue);
  };

  const [tabActivities, setTabActivities] = useState(tabs);

  useEffect(() => {
    setTabActivities(tabs);
  }, [tabs]);

  useEffect(() => {
    anchorEl !== null ? setValue(-1) : setValue(value);
    if (farmData && farmData.id) {
      const updatedState = {
        ...farmData,
        activities: tabActivities,
      };
      console.log("Effect updatedState: ", updatedState);
      setFarmData(updatedState);
      update("farms", updatedState);
      console.log("updated");
    }
  }, [anchorEl]);

  const handleOptionChange = (option) => {
    console.log("Option selected: ", option);
    if (tabActivities.includes(option)) {
      setTabActivities(tabActivities.filter((item) => item !== option));
    } else {
      setTabActivities([...tabActivities, option]);
    }
  };
  console.log("tabs: ", tabs);
  console.log("TabAct: ", tabActivities);
  console.log("FarmData: ", farmData);
  return (
    <Box sx={{ bgcolor: "white", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.05)",
        }}
      >
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{
            color: (theme) => alpha(theme.palette.primary.main),
            margin: "10px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          <AddCircleIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            const updatedState = { ...farmData, activities: tabActivities };
            setFarmData(updatedState);
          }}
          sx={{}}
        >
          <FormGroup sx={{ px: "10px", py: "0px" }}>
            {activityTypes.map((activityType) => (
              <FormControlLabel
                key={activityType.label}
                control={
                  <Checkbox
                    checked={tabActivities.includes(activityType.label)}
                    onChange={() => handleOptionChange(activityType.label)}
                  />
                }
                label={t(activityType.label)}
              />
            ))}
          </FormGroup>
        </Menu>
        <Tabs
          variant="scrollable"
          value={value === -1 ? false : value}
          onChange={handleChange}
          aria-label="tabs"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabActivities.map((tab, index) => {
            return (
              <Tab
                key={tab}
                label={t(tab)}
                {...a11yProps(index)}
                sx={{
                  fontWeight: "bold",
                  paddingTop: 3,
                  paddingBottom: 3,
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      {value === -1 && (
        <Box
          minHeight="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={3}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "90%",
              maxWidth: 300,
            }}
          />
        </Box>
      )}
      {panels.map((panel, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {panel}
          </TabPanel>
        );
      })}
    </Box>
  );
};
