import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Dialog,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { hideModal } from "../../redux/slices/modal-slice";
import { store } from "../../redux/store";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "100%",
    padding: 15,
  },
}));

export const ConfirmationModal = () => {
  const classes = useStyles();
  const {
    type,
    visible,
    title,
    content,
    confirm,
    cancel,
    onConfirm,
    onCancel,
  } = useSelector((state) => state.modal);

  return (
    <Dialog
      maxWidth="xs"
      open={visible && type === "confirmationModal"}
      onClose={onCancel}
      classes={{ paper: classes.modal }}
    >
      <Card elevation={0}>
        <div style={{ padding: 10 }}>
          <Typography variant="h6">{title}</Typography>
        </div>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {content}
          </Typography>
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          {cancel ? (
            <Button
              color="secondary"
              onClick={
                onCancel
                  ? onCancel
                  : () => {
                      store.dispatch(hideModal());
                    }
              }
            >
              {cancel}
            </Button>
          ) : null}
          {confirm ? (
            <Button
              onClick={
                onConfirm
                  ? () => {
                      onConfirm();
                    }
                  : () => {
                      store.dispatch(hideModal());
                    }
              }
            >
              {confirm}
            </Button>
          ) : null}
        </CardActions>
      </Card>
    </Dialog>
  );
};
