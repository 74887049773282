import { deleteOne, queryList } from "../api/common";
import { showErrorSnackbar } from "../redux/slices/snackbar-slice";
import { store } from "../redux/store";
import { exportToCsv } from "./csvUtils";
import i18next from "i18next";
import { hideModal, showModal } from "../redux/slices/modal-slice";
import moment from "moment";

export const trimCompare = (a, b) => {
  // if the text isEmpty, no comparison is needed
  if (!a || !b) return false;

  const trimmedA = a
    .replace(/[`~!@#$%^&*()|+\-=?;:'",.<>{}[\]/\s]/gi, "")
    .toLowerCase();
  const trimmedB = b
    .replace(/[`~!@#$%^&*()|+\-=?;:'",.<>{}[\]/\s]/gi, "")
    .toLowerCase();
  return trimmedA === trimmedB;
};

export const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

export const buildQuery = (params) => {
  let query = "";
  const { order, orderBy, page, rowsPerPage, filters } = params;
  if (order != null && orderBy != null) {
    query += `&sort=${order === "asc" ? "" : "-"}${orderBy}`;
  }
  if (page != null && rowsPerPage != null) {
    query += `&offset=${page * rowsPerPage}`;
    query += `&limit=${rowsPerPage}`;
  }
  if (filters && Object.keys(filters).length) {
    for (const key of Object.keys(filters)) {
      if (filters[key] === null) continue;
      if (key === "search") {
        query += `&filter[q]=${filters[key]}`;
      } else {
        query += `&filter[${key}]=${filters[key]}`;
      }
    }
  }
  // Remove the first ampersand(&) character
  if (query.length > 0) query = query.substring(1);

  return query;
};

export const defaultExportFunc = (source, params) => {
  if (params) {
    const { page, rowsPerPage, ...rest } = params;
    // query required data (without pagination)
    queryList(source, buildQuery(rest))
      .then((resp) => {
        exportToCsv(`${source}.csv`, resp.data, true);
      })
      .catch((e) => {
        store.dispatch(showErrorSnackbar(e));
      });
  } else {
    store.dispatch(showErrorSnackbar("An error occurred"));
  }
};

export const defaultDeleteFunc = (
  source,
  selected,
  setSelected,
  setRefreshData
) => {
  if (selected.length === 0) return;
  const t = i18next.t;
  store.dispatch(
    showModal({
      type: "confirmationModal",
      title: t("Delete Items"),
      content: t("Are you sure you want to delete the selected item(s)?"),
      confirm: t("Delete"),
      cancel: t("Cancel"),
      onConfirm: () => {
        store.dispatch(hideModal());
        const promises = [];
        for (let i = 0; i < selected.length; i++) {
          promises.push(deleteOne(source, selected[i]));
        }
        Promise.all(promises)
          .then(() => {
            setSelected([]);
            setRefreshData((prevState) => prevState + 1);
          })
          .catch((e) => {
            store.dispatch(showErrorSnackbar(e));
          });
      },
    })
  );
};

export const getTableFieldsArr = (config) => {
  return config.fields
    .filter((field) => field.table?.hidden !== true)
    .map((field) => {
      const obj = {
        id: field.id,
        header: field.name,
        value: field.table?.value,
        width: field.table?.width || field.width,
      };

      // Workarounds to support old activities config
      if (field.type === "date" && !obj.value)
        obj.value = (value) => moment(value).format("YYYY-MM-DD");
      if (field.type === "autocomplete" && !obj.value)
        obj.value = (value) => value[i18next.language];
      if (field.type === "autocompleteInventory" && !obj.value)
        obj.value = (value) => value[i18next.language];
      if (field.type === "unit" && !obj.value)
        obj.value = (value) => i18next.t(value);
      return obj;
    });
};

// Validate the form fields
export const validateData = async ({ mode, fields, formData, setError }) => {
  const t = i18next.t;
  let isValid = true;
  await Promise.all(
    fields
      .filter((field) => {
        if (!field.validate) return false;
        if (field[mode]?.hidden || field[mode]?.noValidate) return false;
        return true;
      })
      .map(async (field) => {
        const { id, name } = field;
        const valid = await field.validate(
          resolvePath(formData, id),
          formData,
          mode,
          field
        );
        console.log("Name: ", name);
        console.log("Valid: ", valid);
        if (!valid) {
          const err = `Invalid ${name}`;
          console.log(err);
          setError((prev) => ({ ...prev, [id]: t(err) }));
          isValid = false;
        } else {
          setError((prev) => ({ ...prev, [id]: "" }));
        }
      })
  );
  return isValid;
};

export const getFallbackDisplayValue = (langObj) => {
  if (typeof langObj !== "object" || Object.keys(langObj).length < 1) return;
  let value = langObj[i18next.language];
  if (!value) {
    for (const language of i18next.languages) {
      if (langObj[language]) {
        value = langObj[language];
        break;
      }
    }
  }
  return value;
};

export const getInventoryUnits = (form, allData, action) => {
  console.log("form constant: ", form);
  console.log("allData constant: ", allData);
  const kg = ["g", "kg", "mg", "pack (1kg)", "pack (500g)"];
  const l = ["mℓ", "ℓ"];
  const all = [...kg, ...l];
  var option = all;
  allData[action].map((item) => {
    const parsedItem = JSON.parse(item.id);
    if (parsedItem.eng === form.item.eng) {
      if (item.unit === "kg") {
        option = kg;
      } else if (item.unit === "ℓ") {
        option = l;
      }
    }
  });
  console.log("option const: ", option);
  return option;
};
