const unitConversion = {
  g: {
    unit: "kg",
    convert: (v, ac, qt) => {
      if (ac === "Sowing") {
        // standard quantity is number of pieces per 1 kg
        return (v / 1000) * qt;
      }
      return v / 1000;
    },
  },
  kg: {
    unit: "kg",
    convert: (v, ac, qt) => {
      if (ac === "Sowing") {
        // standard quantity is number of pieces per 1 kg
        return v * qt;
      }
      return v;
    },
  },
  mg: {
    unit: "kg",
    convert: (v, ac, qt) => {
      if (ac === "Sowing") {
        // standard quantity is number of pieces per 1 kg
        return (v / 1000000) * qt;
      }
      return v / 1000000;
    },
  },
  "pack (1kg)": {
    unit: "kg",
    convert: (v, ac, qt) => {
      if (ac === "Sowing") {
        // standard quantity is number of pieces per 1 kg
        return v * qt;
      }
      return v;
    },
  },
  "pack (500g)": {
    unit: "kg",
    convert: (v, ac, qt) => {
      if (ac === "Sowing") {
        // standard quantity is number of pieces per 1 kg
        return (v / 2) * qt;
      }
      return v / 2;
    },
  },
  mℓ: {
    unit: "ℓ",
    convert: (v) => v / 1000,
  },
  ℓ: {
    unit: "ℓ",
    convert: (v) => v,
  },
  pieces: {
    unit: "pieces",
    convert: (v, ac, qt) => {
      if (ac === "Harvest") {
        // standard quantity is kg per number of pieces
        return v * qt;
      }
      return v;
    },
  },
  "tsp (5mℓ)": {
    unit: "tsp (5mℓ)",
    convert: (v, ac, qt) => {
      if (ac === "Sowing") {
        // standard quantity is number of pieces per 1 kg
        return (v / 5 / 1000) * qt;
      }
      return v / 5 / 1000;
    },
  },
  "tsp (15mℓ)": {
    unit: "tsp (5mℓ)",
    convert: (v, ac, qt) => {
      if (ac === "Sowing") {
        // standard quantity is number of pieces per 1 kg
        return (v / 15 / 1000) * qt;
      }
      return v / 15 / 1000;
    },
  },
  "": {
    unit: "",
    convert: (v) => v,
  },
};

export const convertToStandardUnit = (
  quantity,
  unit,
  activityType,
  convertQuantity
) => {
  return {
    unit: unitConversion[unit].unit,
    quantity: unitConversion[unit].convert(
      quantity,
      activityType,
      convertQuantity
    ),
  };
};
