import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  alertType: "info", //could not be empty string as error will happen when passing it into snackbar component props
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSuccessSnackbar: (state, action) => {
      state.open = true;
      state.message = action.payload;
      state.alertType = "success";
    },
    showErrorSnackbar: (state, action) => {
      state.open = true;
      // in some situation the payload passing in is an object
      // alert will show error if object is passed in as message
      state.message =
        typeof action.payload === "object"
          ? action.payload.message ?? "ERROR"
          : action.payload;
      state.alertType = "error";
    },
    showInfoSnackbar: (state, action) => {
      state.open = true;
      state.message = action.payload;
      state.alertType = "info";
    },
    hideSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const {
  showSuccessSnackbar,
  showErrorSnackbar,
  showInfoSnackbar,
  hideSnackbar,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
