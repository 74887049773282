import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import logo from "../../images/logo.png";
import { useTranslation } from "react-i18next";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

export const CustomTabs = ({ tabs, panels, value, setValue }) => {
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "white", width: "100%" }}>
      <Box
        sx={{
          boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Tabs
          variant="scrollable"
          value={value === -1 ? false : value}
          onChange={handleChange}
          aria-label="tabs"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={tab}
                label={t(tab)}
                {...a11yProps(index)}
                sx={{
                  fontWeight: "bold",
                  paddingTop: 3,
                  paddingBottom: 3,
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      {value === -1 && (
        <Box
          minHeight="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={3}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: "90%",
              maxWidth: 300,
            }}
          />
        </Box>
      )}
      {panels.map((panel, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {panel}
          </TabPanel>
        );
      })}
    </Box>
  );
};
