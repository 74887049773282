import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@mui/material";
import { red } from "@mui/material/colors";
import html2canvas from "html2canvas";
import { useState } from "react";

export default function ExportChartModal({ chartRefs, charts }) {
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [selectedCharts, setSelectedCharts] = useState([]);

	const openDialog = () => {
		setDialogOpen(true);
	};

	const closeDialog = () => {
		setDialogOpen(false);
		setSelectedCharts([]);
	};

	const handleChartSelection = (chartName, isSelected) => {
		if (isSelected) {
			setSelectedCharts([...selectedCharts, chartName]);
		} else {
			setSelectedCharts(selectedCharts.filter((chart) => chart !== chartName));
		}
	};

	const handleExportCharts = () => {
		const exportPromises = [];

		selectedCharts.forEach((chartName) => {
			const chartElement = chartRefs[chartName].current;
			exportPromises.push(exportChart(chartElement, `${chartName}.png`));
		});

		Promise.all(exportPromises)
			.then(() => {
				console.log("Charts exported successfully");
				closeDialog();
			})
			.catch((error) => {
				console.error("Error exporting charts:", error);
				closeDialog();
			});
	};

	const exportChart = (chartElement, fileName) => {
		return html2canvas(chartElement).then((canvas) => {
			const chartImage = canvas.toDataURL("image/png");

			// Download the image
			const link = document.createElement("a");
			link.href = chartImage;
			link.download = fileName;
			link.click();
		});
	};

	const renderExportButton = () => {
		return (
			<Button
				variant='contained'
				onClick={openDialog}
			>
				Export Charts
			</Button>
		);
	};

	const renderExportDialog = () => {
		return (
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
			>
				<DialogTitle
					sx={{
						fontSize: "2rem",
						fontWeight: "bold",
						paddingRight: "100px",
						paddingLeft: "50px",
					}}
				>
					Select Charts to Export
				</DialogTitle>
				<DialogContent>
					{charts.map((chart) => (
						<div
							key={chart.title}
							style={{
								fontSize: "1.2rem",
								marginBottom: "0.6rem",
								paddingLeft: "50px",
							}}
						>
							<label>
								<input
									type='checkbox'
									checked={selectedCharts.includes(chart.title)}
									onChange={(e) => handleChartSelection(chart.title, e.target.checked)}
									style={{
										fontSize: "1.2rem",
										margin: "3px 8px 0px 4px",
										width: "1.3rem",
										height: "1.2rem",
									}}
								/>
								{chart.title}
							</label>
						</div>
					))}
				</DialogContent>
				<DialogActions sx={{ justifyContent: "center", marginBottom: "20px" }}>
					<Button onClick={handleExportCharts}>Export</Button>
					<Button
						onClick={closeDialog}
						sx={{ backgroundColor: red[500], color: "white" }}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		);
	};
	return (
		<div>
			{renderExportButton()}
			{renderExportDialog()}
		</div>
	);
}
