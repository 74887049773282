// string with ' _ ' will be processed as Name object
// the name before the _ is the key of the object and the language after the _ is the language
// the header should match the key of the object
export const EXPORT_HEADER = {
  user: [
    "name_eng",
    "name_chs",
    "name_cht",
    "email",
    "phoneNumber",
    "role",
    "farms_eng",
    "farms_chs",
    "farms_cht",
  ],
  farm: [
    "name_eng",
    "name_chs",
    "name_cht",
    "address_eng",
    "address_chs",
    "address_cht",
    "plants_eng",
    "plants_chs",
    "plants_cht",
    "fertilizers_eng",
    "fertilizers_chs",
    "fertilizers_cht",
    "pesticides_eng",
    "pesticides_chs",
    "pesticides_cht",
    "foliars_eng",
    "foliars_chs",
    "foliars_cht",
    "fungicides_eng",
    "fungicides_chs",
    "fungicides_cht",
  ],
  // plants, fertilizers, pesticides, foliars, fungicides
  other: ["name_eng", "name_chs", "name_cht", "unitType"],
};
