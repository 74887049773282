export const validateRange = (v) => {
  // TODO: tokenize by comma
  const arr = v.split(",");
  const result = new Set();
  arr.forEach((a) => {
    const tmp = a.trim();
    let range = tmp.split("-");
    if (!range || range.length === 0 || range.length > 2) return null; // invalid
    let start = parseInt(range[0]);
    let end = parseInt(range[range.length - 1]);
    if (start === 0 && end === 0) result.add(0); // row 0 is valid
    if (!start || !end) return null;
    for (let i = start; i <= end; i++) {
      result.add(i);
    }
  });
  // result.delete(0); // row 0 is invalid
  if (result.size === 0) return null;
  return result;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
