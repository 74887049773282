import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#91dd75",
		borderRadius: "5px",
		padding: "10px",
		marginBottom: "40px",
	},

	flexRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		spacing: "10px",
	},

	flexColumn: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
	},

	textCenter: {
		textAlign: "center",
		padding: "8px 0 8px 0",
	},
}));

export const ForecastColumn = (data) => {
	const classes = useStyles();
	let time = data.title;
	if (data.type === "day") {
		const parts = time.split("-");
		const day = parts[2];
		const month = parts[1];
		time = `${day}/${month}`;
	}

	return (
		<div className={classes.flexColumn}>
			<Typography
				variant='h6'
				className={classes.textCenter}
			>
				{time}
			</Typography>
			<img
				src={data.icon}
				alt='weather icon'
				style={{
					height: "40px",
					width: "40px",
				}}
			/>
			<Typography className={classes.textCenter}>
				{data.temp}
				{"\u00B0C"}
			</Typography>
		</div>
	);
};
