import React from "react";
import { useSelector } from "react-redux";
import { Box, Checkbox, Typography } from "@mui/material";
import { update } from "../../api/common";
import { useTranslation } from "react-i18next";
import { create, query } from "../../api/common";

const TaskItem = ({ task, setReload }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const userId = useSelector((state) => state.auth.id);
  const userName = useSelector((state) => state.auth.name);

  // method to convert date string to date
  const toDate = (d) => new Date(d);

  // Method to check if the date is today
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  // Method to check if the date has passed
  const isOverdue = (date) => {
    return !isToday(date) && date < new Date();
  };

  // Method to toggle task
  const toggleTask = async () => {
    if (!task.completed) {
      const userIsCreator = userId == task.creatorId;
      const { data } = await query(`farms/name/${task.farmId}`);
      update(`tasks`, {
        ...task,
        completed: !task.completed,
      }).then(() => {
        setReload((prev) => prev + 1);
      });
      create("notifications", {
        userId: task.creatorId,
        userName: task.creatorName,
        farmId: task.farmId,
        farmName: data.name,
        assigneeId: task.assigneeId,
        assigneeName: task.assigneeName,
        markedId: userId,
        markedName: userName,
        content: task.title,
        date: task.date,
        createdAt: new Date(new Date().toISOString()),
        readUsers: [],
        category: "completed",
      });
    }
  };

  // Arrays for name of day and month
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <Box
      sx={{
        p: 2,
        mb: 1,
        boxShadow: 3,
        borderLeft: 7,
        borderColor: isToday(toDate(task.date))
          ? "#F1E560"
          : isOverdue(toDate(task.date))
          ? "#CF401F"
          : "#B0D776",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Checkbox
          checked={task.completed}
          onClick={() => {
            toggleTask();
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "inline",
              typography: {
                fontSize: 20,
              },
              fontWeight: "bold",
            }}
          >
            {task.title}
            <Box
              sx={{
                typography: {
                  fontSize: 14,
                  color: "#CF401F",
                },
                ml: 3,
                display:
                  isOverdue(toDate(task.date)) && !task.completed
                    ? "inline"
                    : "none",
              }}
            >
              {t("Overdue")}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                typography: {
                  fontWeight: "bold",
                  fontSize: 14,
                },
                color:
                  isOverdue(toDate(task.date)) && !task.completed
                    ? "#CF401F"
                    : "#444444",
              }}
            >
              {task?.assigneeName?.[language]}
            </Typography>
            <Box
              sx={{
                typography: {
                  fontWeight: "bold",
                  fontSize: 14,
                },
                color:
                  isOverdue(toDate(task.date)) && !task.completed
                    ? "#CF401F"
                    : "#444444",
              }}
            >
              {t(days[toDate(task.date).getDay()]) +
                ", " +
                t(months[toDate(task.date).getMonth()]) +
                " " +
                toDate(task.date).getDate()}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TaskItem;
