import React, { useEffect, useRef, useState } from "react";
import { setTitle } from "../../redux/slices/title-slice";
import { useDispatch } from "react-redux";
import { queryList } from "../../api/common";
import {
  buildQuery,
  defaultDeleteFunc,
  defaultExportFunc,
  getFallbackDisplayValue,
  getTableFieldsArr,
} from "../../utils/utils";
import { showErrorSnackbar } from "../../redux/slices/snackbar-slice";
import { FORM_MODE } from "../../constants/choices";
import FormDialog from "../common/form-dialog";
import EnhancedTable from "../common/enhanced-table";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export const FarmList = () => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const [data, setData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const { i18n } = useTranslation();
  const [config] = useState(() => getConfig(i18n));
  const [dialogStatus, setDialogStatus] = useState({
    open: false,
    mode: FORM_MODE.CREATE,
    formId: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setTitle("Farms"));
    queryList(config.source, buildQuery(tableRef.current.getParams()))
      .then((resp) => {
        setData({ data: resp?.data ?? [], total: parseInt(resp?.total ?? 0) });
      })
      .catch((e) => {
        dispatch(showErrorSnackbar(e));
      });
  }, [dispatch, refreshData, config]);

  return (
    <Box sx={{ padding: 6 }}>
      <EnhancedTable
        ref={tableRef}
        data={data}
        fields={getTableFieldsArr(config)}
        setRefreshData={setRefreshData}
        createFunc={() =>
          setDialogStatus({
            open: true,
            mode: FORM_MODE.CREATE,
            formId: "",
          })
        }
        exportFunc={() =>
          defaultExportFunc(config.source, tableRef.current.getParams())
        }
        deleteFunc={() =>
          defaultDeleteFunc(
            config.source,
            tableRef.current.getSelectedData(),
            tableRef.current.getSetSelected(),
            setRefreshData
          )
        }
        handleRowClick={(e, rowId) => navigate(`/${config.source}/${rowId}`)}
      />
      <FormDialog
        config={config}
        dialogStatus={dialogStatus}
        setDialogStatus={setDialogStatus}
        setRefreshData={setRefreshData}
      />
    </Box>
  );
};

const getConfig = (i18next) => {
  return {
    source: "farms",
    initialState: {
      id: "",
      name: {
        eng: "",
        chs: "",
        cht: "",
      },
      address: {
        eng: "",
        chs: "",
        cht: "",
      },
      plants: [],
      fertilizers: [],
      pesticides: [],
      foliars: [],
      fungicides: [],
      tags: ["all"],
      visibleTags: ["all"],
    },
    fields: [
      {
        id: "name.eng",
        name: "Name (ENG)",
        type: "nested",
        validate: (v) => !!v,
      },
      {
        id: "name.cht",
        name: "Name (CHT)",
        type: "nested",
        validate: (v) => !!v,
      },
      {
        id: "name.chs",
        name: "Name (CHS)",
        type: "nested",
        validate: (v) => !!v,
      },
      {
        id: `address.${i18next.language}`,
        name: "Address",
        type: "nested",
        validate: (v) => !!v,
        table: {
          value: (value, data) => getFallbackDisplayValue(data?.address),
        },
      },
    ],
  };
};
